.c-breadcrumb {
    background: #E8EAEC;
    &__list {
        margin: 0;
        @include rem-change(padding, 9px 12px 9px 20px);
        @include font-size(13);
        color: #333;
    }
    &__item {
        display: inline-block;
        position: relative;
        @include rem-change(padding-right, 14px);
        letter-spacing: normal;
        & a {
            color: inherit;
        }
        &:not(:last-child)::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: 2px;
            @include rem-change(width, 4px);
            @include rem-change(height, 4px);
            @include rem-change(margin-top, -4px);
            border-top: 1px solid #848484;
            border-right: 1px solid #848484;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            speak: none;
            transition: all 0.2s ease;
        }
    }
}