.c-label {
    display: inline-block;
    padding: .167em .34em;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 2px;
    &--primary {
        color: #fff;
        background-color: #4485F4;
    }
    &--secondary {
        color: #fff;
        background-color: #6c757d;
    }
    &--success {
        color: #fff;
        background-color: #28a745;
    }
    &--danger {
        color: #fff;
        background-color: #dc3545;
    }
    &--warning {
        color: #212529;
        background-color: #ffc107;
    }
    &--info {
        color: #fff;
        background-color: #17a2b8;
    }
    &--light {
        color: #212529;
        background-color: #f8f9fa;
    }
    &--dark {
        color: #fff;
        background-color: #343a40;
    }
    &--required {
        color: #fff;
        background-color: #D0021B;
    }
}