@-webkit-keyframes colorchange {
	0% {
		color: #d8d8d8;
	}

	30% {
		color: #4485F4;
	}

	60% {
		color: #d8d8d8;
	}

	100% {
		color: #d8d8d8;
	}
}

@keyframes colorchange {
	0% {
		color: #d8d8d8;
	}

	30% {
		color: #4485F4;
	}

	60% {
		color: #d8d8d8;
	}

	100% {
		color: #d8d8d8;
	}
}

.c-animation-arrow-pulse {
	li {
		margin-right: -3px;
		margin-left: -3px;
		color: #d8d8d8;
		-webkit-animation: colorchange 1.2s linear infinite;
		-moz-animation: colorchange 1.2s linear infinite;
		-o-animation: colorchange 1.2s linear infinite;
		-ms-animation: colorchange 1.2s linear infinite;
		animation: colorchange 1.2s linear infinite;
	}

	li:nth-child(1) {
		-moz-animation-delay: 0.3s;
		-webkit-animation-delay: 0.3s;
		-o-animation-delay: 0.3s;
		-ms-animation-delay: 0.3s;
		animation-delay: 0.3s;
	}

	li:nth-child(2) {
		-moz-animation-delay: 0.6s;
		-webkit-animation-delay: 0.6s;
		-o-animation-delay: 0.6s;
		-ms-animation-delay: 0.6s;
		animation-delay: 0.6s;
	}

	li:nth-child(3) {
		-moz-animation-delay: 0.9s;
		-webkit-animation-delay: 0.9s;
		-o-animation-delay: 0.9s;
		-ms-animation-delay: 0.9s;
		animation-delay: 0.9s;
	}
}