@charset "UTF-8";
.c-field__input {
    overflow: visible;
    @include rem-change(padding, 0 12px);
    border: 1px solid #979797;
    border-radius: 4px;
    height: 32px;
    @include font-size(15);
    color: #333;
    letter-spacing: 0;
    &--w240 {
        width: 240px;
    }
    &--w340 {
        width: 340px;
    }
    &+.c-field__icon {
        position: relative;
        display: inline-block;
        .c-icon {
            position: absolute;
            top: -14px;
            right: 11px;
            @include font-size(18);
            color: #B3B3B3;
        }
    }
    &:placeholder-shown {
        color: #B3B3B3;
    }
    &:hover {
        border: 1px solid #333333;
    }
    &:active,
    &:focus {
        border: 1px solid #1F6DF4;
        box-shadow: 0 0 3px 0 #4485F4;
        outline: none;
    }
    &.is-settled {
        background-color: transparent;
        border: solid transparent;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
        border-width: 1px 0;
    }
    &-wrap {
        &--password {
            .c-icon {
                &:visited {
                    color: #5703AE;
                }
                &:hover {
                    color: #4485F4;
                    cursor: pointer;
                }
                &:active {
                    color: #0065BD;
                }
            }
        }
    }
}