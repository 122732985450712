/*------------------------------------*\
    #VARIABLES--global
\*------------------------------------*/

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$color-1: #000000;
$color-2: #5a5858;
$color-3: #acabab;
$color-4: #d6d5d5;
$color-5: #f6f6f6;
$color-6: #ab0006;
$color-7: #005bac;
$color-gray-base: #000000 !default;
$color-gray-darker: #222222;
$color-gray-dark: #3b3b3b;
$color-gray: #5a5858;
$color-gray-normal: #dddddd;
$color-gray-light: #999999;
$color-gray-lighter: #f6f6f6;
$color-red: #ab0006;
$color-form-red: #d8261e;
$color-pink: #fae5e5;
$color-blue: #138db5;
$color-thick-blue: #0f80af;
$color-thin-blue: #deedf2;
$color-green: #07ad22;
$color-common-green: #00b2a4;
$color-thin-green: #b2e8e4;
$color-yellow: #f4e829;
$color-orange: #f19937;
$color-thin-orange: #fff6e5;
$color-thick-orange: #e5a900;
$color-gray: #737373;
$color-normal-gray: #f8f8f8;
$color-thin-gray: #dddddd;
$color-trans-white-25: rgba(255, 255, 255, 0.25);
$color-state-info: #02A4D5;
$color-state-complete: #03790F;
$color-state-inreview: #C7A118;
$color-state-deprecated: #B00B02;
$color-brand-primary: #005bac !default;
$color-brand-success: #5cb85c !default;
$color-brand-info: #5bc0de !default;
$color-brand-warning: #f0ad4e !default;
$color-brand-danger: #d9534f !default;

// onamae settings
$color-onamae-red: #cc0000;
$color-onamae-gray-1:#d8d8d8;
$color-onamae-gray-2:#dadada;
$color-onamae-gray-3:#666;
$color-onamae-gray-dark: #333;
$color-onamae-text-blue: #0065bd;
$color-onamae-icon-gray: #6B6B6B;
$color-onamae-label-function: #48a975;
$color-onamae-label-domain: #4a90e2;
$color-onamae-border-default :#d8d8d8;
$color-onamae-white: #fff;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:	"Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:			Georgia, "Times New Roman", Times, serif !default;

//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:	Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:		'ヒラギノ角ゴ ProN W3',"Hiragino Kaku Gothic ProN",メイリオ,Meiryo,sans-serif !default;
$font-family-arial:		Arial, sans-serif;

$font-size-base:		15px !default;
// $font-size-large:		ceil(($font-size-base * 1.25)) !default; // ~18px
// $font-size-small:		ceil(($font-size-base * 0.85)) !default; // ~12px

// $font-size-h1:			floor(($font-size-base * 2.6)) !default; // ~36px
// $font-size-h2:			floor(($font-size-base * 2.15)) !default; // ~30px
// $font-size-h3:			ceil(($font-size-base * 1.7)) !default; // ~24px
// $font-size-h4:			ceil(($font-size-base * 1.25)) !default; // ~18px
// $font-size-h5:			$font-size-base !default;0
// $font-size-h6:			ceil(($font-size-base * 0.85)) !default; // ~12px

// text-length
$text-length-1: 1em;
$text-length-2: 2em;
$text-length-3: 3em;
$text-length-4: 4em;
$text-length-5: 5em;
$text-length-6: 6em;
$text-length-7: 7em;
$text-length-8: 8em;
$text-length-9: 9em;
$text-length-10: 10em;
$text-length-11: 11em;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:		1.42857 !default;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:	floor(($font-size-base * $line-height-base)) !default; // ~20px

$font-size-base-number:		15;
$font-size-base:			$font-size-base-number * 1px;
// $font-size-base-sp-number:	14;
// $font-size-base-sp:			$font-size-base-sp-number * 1px;

// Spacing & Padding
$space: 1rem;
$doublespace: $space*2;
$pad: 1rem;
$pad-half: $pad/2;
$offset-top: 2rem;

// Breakpoints
// $breakpoints: (
//     'sm': 'screen and (min-width: 400px)',
//     'md': 'screen and (min-width: 768px)',
//     'lg': 'screen and (min-width: 1000px)',
//     'xl': 'screen and (min-width: 1200px)',
// ) !default;

// Animations
$animate-quick: 0.1s;

// Borders
$border-radius: 3px;
$border-radius-medium: 6px;

// Zindex
$zindex-1:          1 !default;
$zindex-2:          2 !default;
$zindex-3:          3 !default;
$zindex-l-nav:          10 !default;
$zindex-dropdown:          1000 !default;
$zindex-sticky:            1020 !default;
$zindex-fixed:             1030 !default;
$zindex-popover:           1040 !default;
$zindex-tooltip:           1050 !default;
$zindex-loading:           1060 !default;
$zindex-header:           1070 !default;
$zindex-modal-backdrop:    1080 !default;
$zindex-modal:             1090 !default;
$zindex-loading-overall:           1100 !default;