.c-container {
    @include rem-change(margin-top, 32px);
    @include clearfix();
    @include displayHide();
    // .js-displayChange {
    //     display: block;
    // }
    &.is-hidden {
        display: none !important;
    }
    .c-heading-2nd {
        line-height: 1;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 16px;
        line-height: 1;
        p {
            margin-top: 12px;
            line-height: 1.5;
        }
        &Left {
            width: 680px;
        }
        &Right {
            .c-button {
                .c-icon {
                    margin-right: 8px;
                }
                &--small .c-icon {
                    margin-right: 6px;
                }
            }
        }
    }
    &__body {
        @include rem-change(padding, 24px);
        box-sizing: border-box;
        &--white {
            background: #fff;
        }
        &--border {
            border: 1px solid #D8D8D8;
            border-top: 2px solid #D8D8D8;
        }
        &--wide {
            padding-top: 12px;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
        }
        &--narrow {
            @include rem-change(padding-top, 12px);
            @include rem-change(padding-bottom, 12px);
        }
        &--tab {
            padding: 0;
        }
    }
    &__heading {
        @include font-size(18);
        font-weight: 700;
        color: #333333;
        line-height: 1;
    }
    // &__text {
    //     font-size: 15px;
    //     color: #333333;
    // }
    &__function {
        @include font-size(15);
        text-align: right;
        .c-form-block.c-form-block {
            margin-top: 0;
            &--defInline {
                span {
                    display: inline-block;
                }
                .c-form-select {
                    position: relative;
                    select {
                        color: #333;
                        cursor: pointer;
                        position: relative;
                        z-index: $zindex-1;
                        background-color: transparent;
                        border: 1px solid #d6d6d6;
                        width: 100%;
                        padding: 0 36px 0 15px;
                        height: 31px;
                        line-height: 31px;
                        appearance: none;
                        -ms-appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        -webkit-border-radius: 3px;
                        -moz-border-radius: 3px;
                        -ms-border-radius: 3px;
                        -o-border-radius: 3px;
                        border-radius: 3px;
                        outline: none;
                    }
                    .c-icon.c-icon-arrow-down {
                        position: absolute;
                        top: 10px;
                        right: 12px;
                        @include font-size(12);
                        color: #6B6B6B;
                    }
                }
                dt,
                dd {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 8px;
                }
            }
            &--pagination {
                text-align: right;
                color: #333;
                .c-icon {
                    color: #B3B3B3;
                    @include font-size(16);
                }
            }
        }
    }
    &__navigation {
        display: block;
    }
    .completion-block {
        @include rem-change(padding, 70px 0 30px);
        text-align: center;
        color: #4485F4;
        &__icon {
            @include font-size(70);
        }
        &__text {
            @include rem-change(margin, 13px 0 0);
            @include font-size(24);
        }
    }
    .c-block-completion {
        padding-bottom: 0;
    }
    .c-progressTracker {
        @include rem-change(width, 820px);
        margin: 0 auto;
    }
    &--article {
        .c-block__body {
            @include rem-change(padding, 40px);
            background: #fff;
            border: 1px solid #D8D8D8;
            border-top: 2px solid #D8D8D8;
            box-sizing: border-box;
            .c-heading-2nd {
                position: relative;
                margin: 0;
                padding: 0;
                border-bottom: 2px solid #ccc;
                &::after {
                    background-color: #cc0000;
                    bottom: -2px;
                    content: "";
                    display: block;
                    height: 2px;
                    left: 0;
                    position: absolute;
                    width: 192px;
                }
            }
            p {
                margin: 1em 0;
                @include font-size(15);
                color: #333;
            }
        }
    }
    &--split {
        @include rem-change(width, 435px);
        @include rem-change(padding-top, 36px);
        // &:nth-child(2n+1) {
        //     float: left;
        // }
        // &:nth-child(2n+2) {
        //     float: right;
        // }
    }
}