// #VARIABLES--local
$local-c-table-border-color: #dee2e6 !default;
$local-c-table-cell-padding-default: 12px !default;

@keyframes show {
	from {
		opacity: 0;
		height: 0;
	}

	to {
		opacity: 1;
		height: auto;
	}
}

.c-table {
	table-layout: fixed;
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	word-break: break-all;
	word-wrap: break-word;
	border-collapse: collapse;

	tr {
		&.is-hoverable {

			&:hover,
			&:active {
				background-color: #EDF4FF;
			}
		}

		&.is-no-hoverable {
			td {
				padding: 0;
			}
		}
	}

	th,
	td {
		@include rem-change(padding, 12px 16px 12px 0);
		border-bottom: 1px dotted #dee2e6;
		border-collapse: separate;
		border-spacing: 0;
		vertical-align: middle;
		text-align: left;

		&:first-child {
			@include rem-change(padding-left, 16px);
		}

	}

	th {
		.c-badge {
			float: right;
			@include rem-change(margin-top, 3px);
		}

		button {
			position: relative;
			padding: 0;
			background-color: transparent;
			border: none;
			cursor: pointer;
			outline: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			&>.c-icon.c-icon-arrow-down,
			&>.c-icon.c-icon-arrow-up {
				position: absolute;
				top: 50%;
				margin-top: -8px;
				margin-left: 8px;
				@include font-size(12);
				color: #6B6B6B;
				line-height: 1;
			}
		}
	}

	td {
		@include rem-change(padding-right, 24px);

		&.c-table-action {
			padding: 0;
			text-align: right;

			a {
				padding: 12px 16px 12px 0;
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: end;
				-webkit-justify-content: flex-end;
				-ms-flex-pack: end;
				justify-content: flex-end;
			}

			.c-button-text {
				padding: 12px 8px 12px 0;
			}

			.c-icon {
				// margin-top: -.125em;
				line-height: 1;

				&:before {
					margin-right: 8px;
					@include font-size(16);
					vertical-align: -1px;
				}
			}

			&.is-unset {
				.c-button-text {
					color: #B3B3B3 !important;
					pointer-events: none;
				}
			}
		}
	}

	&.is-unset {
		color: #B3B3B3;

		p {
			color: #B3B3B3;
		}
	}

	.is-unset {
		color: #B3B3B3 !important;
	}

	&.is-hide {
		-webkit-transition: none;
		transition: none;
		display: none;
	}

	&.is-hoverable {

		>tbody>tr:not(.is-no-hoverable):hover,
		>tbody>tr:not(.is-no-hoverable):active {
			background-color: #EDF4FF;
		}
	}

	.c-badge--md {
		@include rem-change(width, 88px);
	}

	&__head {
		@include vertical();

		th {
			border-bottom: 1px solid $local-c-table-border-color;
			padding-top: 0;
			@include rem-change(padding-bottom, 8px);
		}

		&--theme-dark {
			background-color: black;

			th {
				color: #fff;
			}
		}
	}

	&__body {
		@include vertical();

		td {
			a {
				@include text-link-action();

				.c-icon {
					&:visited {
						color: #0065bd;
					}

					&:hover {
						color: #4485F4;
						cursor: pointer;
					}

					&:active {
						color: #0065BD;
					}

					&.is-disabled {
						outline: none;
						pointer-events: none;
						color: #B3B3B3;

						span,
						a {
							color: #B3B3B3;

							&:visited {
								color: #B3B3B3;
							}

							&:hover {
								color: #B3B3B3;
							}

							&:active {
								color: #B3B3B3;
							}
						}
					}
				}
			}
		}
	}

	&-note {
		display: block;
		margin-top: 8px;
		margin-top: 0.8rem;
		font-size: 13px;
		font-size: 1.3rem;
		color: #666;
		letter-spacing: 0;
	}
	&-list-horizontal {
		display: flex;
		// justify-content: center;
		align-items: center;

		&__item {
			position: relative;
			padding: 0 6px 0 12px;

			&:first-child {
				padding-left: 0;
			}
			&:not(:last-child)::after {
				position: absolute;
				top: 50%;
				right: 0;
				content: "";
				display: block;
				width: 1px;
				height: 14px;
				margin-top: -7px;
				background: #D8D8D8;
			}

			// &:last-child {
			// 	&::before {
			// 		position: absolute;
			// 		top: 50%;
			// 		left: 0;
			// 		content: "";
			// 		display: block;
			// 		width: 1px;
			// 		height: 14px;
			// 		margin-top: -7px;
			// 		background: #D8D8D8;
			// 	}
			// }
		}
	}

	&--theme-dark {
		background-color: black;

		td,
		th {
			border-color: #32383e;
			color: #fff;
		}
	}

	&--striped {
		.c-table__body tr {
			&:nth-of-type(2n+1) {
				background-color: #F8F8F8;
			}

			&:hover a {
				color: #4485F4;
				text-decoration: underline;
			}
		}

		th {
			color: #333;
		}

		td,
		th {
			@include rem-change(padding-left, 20px);
			vertical-align: middle;
		}
	}

	&--bordered {
		border-top: 2px solid #D8D8D8;
		border-right: 1px solid #D8D8D8;
		border-bottom: 1px solid #D8D8D8;
		border-left: 1px solid #D8D8D8;

		td,
		th {
			border-bottom: 1px dotted #D8D8D8;
			color: #333;
		}

		th {
			padding: 12px 12px 12px 24px;
			background: #F8F8F8;
			text-align: left;
			font-weight: 400;

			&:first-child {
				padding-left: 24px;
			}
		}

		td {
			@include rem-change(padding-left, 24px);
		}
	}

	&--filter {

		th,
		td {
			padding-left: 0;
			vertical-align: middle;
		}

		th {
			@include rem-change(padding-bottom, 8px);

			button {
				background-color: transparent;
				border: none;
				cursor: pointer;
				outline: none;
				padding: 0;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;

				>.c-icon.c-icon-arrow-down {
					@include rem-change(margin-left, 8px);
					@include font-size(12);
					color: #6B6B6B;

					&:before {
						vertical-align: -.125em;
					}
				}
			}
		}

		tr {
			td {
				padding: 0;
				border-bottom: 1px dotted #d8d8d8;
				border-collapse: separate;
				border-spacing: 0;

				>span,
				>a {
					display: block;
					@include rem-change(padding-top, 12px);
					@include rem-change(padding-right, 20px);
					@include rem-change(padding-bottom, 12px);
				}

				&:first-child {

					>span,
					>a,
					button {
						@include rem-change(padding-left, 20px);
						padding-right: 0;
					}
				}
			}

			&:last-child {
				td {
					border-bottom: 1px solid #d8d8d8;
				}
			}
		}

		.c-badge {
			@include rem-change(width, 88px);
		}
	}

	&--account {

		td,
		th {
			padding-right: 0;
			@include rem-change(padding-left, 20px);
			vertical-align: middle;
			// &.c-table-action {
			// 	&--change,
			// 	&--delete {
			// 		.c-icon:before {
			// 			@include rem-change(margin-right, 8px);
			// 			@include font-size(16);
			// 			line-height: 1.2;
			// 		}
			// 	}
			// }
		}

		.c-table__head {
			.c-icon-question {
				@include rem-change(margin-left, 8px);
				@include rem-change(width, 20px);
				@include rem-change(height, 20px);
				line-height: 20px;
				vertical-align: bottom;
			}
		}

		.c-icon {
			@include font-size(16);
		}
	}

	&--details {

		td,
		th {
			padding-right: 0;
			@include rem-change(padding-left, 20px);
			vertical-align: middle;
			text-align: left;
			// &.c-table-action {
			// 	&--change,
			// 	&--delete {
			// 		.c-icon:before {
			// 			@include rem-change(margin-right, 8px);
			// 			@include font-size(16);
			// 		}
			// 	}
			// }
		}

		th {
			vertical-align: top;
		}

		.c-icon {
			@include font-size(16);
		}
	}

	&--w400 {
		@include rem-change(width, 400px);
	}

	// &--narrow {
	// 	th,
	// 	td {
	// 		@include rem-change(padding-top, 12px);
	// 		@include rem-change(padding-bottom, 12px);
	// 	}
	// }
}

.c-table-initialization {
	border: 0 !important;

	th,
	td {
		padding: 0 !important;
		background: none;
		border: 0 !important;
		text-align: left;
	}
}



/* safari */
@media screen and (-webkit-min-device-pixel-ratio: 0) {

	_::-webkit-full-page-media,
	_:future,
	:root .c-button-text__text {
		line-height: 1.2;
	}
}

.c-table-wrap {
	padding: 24px;
	border: 1px solid #D8D8D8;
	border-top: 2px solid #D8D8D8;
	box-sizing: border-box;
}

.c-table__text {
	&:first-child {
		margin-top: 0;
	}

	margin-top: 24px;
}

.c-table-block-domain {
	&-parent {
		display: flex;
		align-items: center;

		&:hover,
		&:active {
			background-color: #EDF4FF;
		}

		&__domain {
			width: 376px;
			// padding: 12px 24px 12px 0;
			.c-button-text {
				padding: 12px 24px 12px 0;
			}
		}

		&__status {
			width: 94px;
			padding: 12px 10px 12px 0;
		}

		&__renewalDeadline {
			width: 94px;
			padding: 12px 10px 12px 0;
		}

		&__dns {
			width: 78px;
			padding: 12px 10px 12px 0;
		}

		&__subDomain {
			width: 130px;
			padding: 12px 10px 12px 0;
		}

		&__delete {
			width: 78px;
			padding: 12px 10px 12px 0;
		}

		>div {

			box-sizing: border-box;

			.c-button-text {
				span:last-child {
					margin-right: 0;
					line-height: 1.5;
				}

				.c-icon-arrow-down,
				.c-icon-arrow-up,
				.c-icon-arrow-right {
					font-size: 10px;
				}
			}

			&.c-table-action {
				.c-button-text {
					padding: 12px 0 12px;

					.c-button-text__text {
						line-height: 1;
					}

					.c-icon {
						line-height: 1;
					}

					.c-icon:before {
						// margin-left: 7px;
						font-size: 16px;
						font-size: 1.6rem;
						vertical-align: -1px;
					}
				}
			}
		}
	}

	&-child {
		&.is-show {
			display: block;
		}

		&__item {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-left: 16px;

			&:hover,
			&:active {
				background-color: #EDF4FF;
			}

			&:before {
				position: absolute;
				top: 23px;
				margin-top: -14px;
				content: "･";
				font-size: 30px;
				line-height: 1;
				color: #005AA6;
			}

			>div {
				box-sizing: border-box;

				&.c-table-action {
					.c-button-text {

						// padding: 12px 0 12px;
						.c-button-text__text {
							line-height: 1;
						}

						.c-icon {
							line-height: 1;
						}

						.c-icon:before {
							margin-right: 8px;
							font-size: 16px;
							font-size: 1.6rem;
							vertical-align: -1px;
						}
					}
				}
			}
		}

		&__domain {
			width: 344px;
			margin-left: 16px;
			padding: 12px 24px 12px 0;
		}

		&__status {
			width: 94px;
			padding: 12px 10px 12px 0;
		}

		&__renewalDeadline {
			width: 94px;
			padding: 12px 10px 12px 0;
		}

		&__dns {
			width: 78px;
			padding: 12px 10px 12px 0;
		}

		&__subDomain {
			width: 130px;
			padding: 12px 10px 12px 0;
		}

		&__delete {
			width: 78px;
			padding: 12px 10px 12px 0;
			margin-left: auto;
		}

		// li {
		// 	position: relative;
		// 	display: flex;
		// 	justify-content: space-between;
		// 	align-items: center;
		// 	&:hover,
		// 	&:active {
		// 		background-color: #EDF4FF;
		// 	}

		// 	&:before {
		// 		position: absolute;
		// 		top: 23px;
		// 		margin-top: -14px;
		// 		content: "･";
		// 		font-size: 30px;
		// 		line-height: 1;
		// 		color: #005AA6;
		// 	}

		// 	box-sizing: border-box;
		// }
	}
}