@charset 'utf-8';
// #VARIABLES--local
// color
$default-color: $color-onamae-red !default;
.c-heading {
    &-1st {
        position: relative;
        // @include last-mb0;
        @include font-size(32);
        .c-badge{
                position: absolute;
                top: 50%;
                margin-top: -13px;
                margin-left: 16px;
                font-weight: bold;
                vertical-align: middle;
            &--circle {
                
            }
            &--num {
                padding: 0.3rem 0.6266rem;
                @include font-size(16);
            }
            &--l {
                margin-top: -14px;
                padding: 6.2px 13.5px 6.5px;
            }
        }
    }
    &-2nd {
        @include font-size(24);
    }
    &-3rd {
        position: relative;
        border-bottom: 2px solid #ccc;
        @include font-size(24);
        &:before {
            position: absolute;
            bottom: 0;
            border-bottom: 2px red #ccc;
        }
    }
    &-4th {
        @include font-size(15);
    }
    &-5th {
        @include font-size(13);
    }
    &-6th {
        @include font-size(10);
    }
    &-wrap {
        > .c-icon {
            @include rem-change(margin-right, 16px);
            @include font-size(24);
            color:#6B6B6B;
        }
        .c-heading-2nd {
            display: inline-block;
            vertical-align: middle;
        }
    }
}