@charset 'utf-8';
// =====================
// article
// =====================
.p-article {
    &-container {
        @include rem-change(margin-top, 25px);
        &__inner {
            @include rem-change(padding, 32px 40px 40px 40px);
            background: #fff;
            border: 1px solid #D8D8D8;
            border-top: 2px solid #D8D8D8;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            p {
                @include rem-change(margin, 24px 0);
            }
            .c-text-moderate {
                line-height: 1;
            }
        }
    }
    &__heading {
        position: relative;
        @include rem-change(margin-top, 12px);
        border-bottom: 2px solid #ccc;
        @include font-size(24);
        &Inner {
            position: relative;
            display: inline-block;
            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: -2px;
                left: 0;
                @include rem-change(height, 2px);
                width:100%;
                background-color: $color-onamae-red;
            }
        }
    }
    &__related {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        @include rem-change(margin-top, 40px);
        @include rem-change(padding, 16px 16px 16px 0);
        border: 1px solid #D8D8D8;
        &Title {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 152px;
                -ms-flex: 0 0 152px;
                    flex: 0 0 152px;
            @include font-size(18); 
            text-align: center;
        }
        &Body {
            position: relative;
            -webkit-box-flex: 1;
            -webkit-flex: 1;
                -ms-flex: 1;
                    flex: 1;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
                -ms-flex-align: center;
                    align-items: center;
            @include rem-change(height, 94px);
            @include rem-change(padding-left, 24px);
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                display: block;
                @include rem-change(width, 1px);
                height: 100%;
                background: #D8D8D8;
            }
        }
        &List {
            @include rem-change(margin, 8px 0);
            &Item {
                a {
                    position: relative;
                    line-height: 1.7;
                }
            }
        }
    }
    &-backnumber {
        @include rem-change(margin-top, 20px);
        &__list {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            -webkit-justify-content: space-between;
                    justify-content: space-between;
            margin: 0;
        }
        &__item {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 320px;
                -ms-flex: 0 0 320px;
                    flex: 0 0 320px;
            position: relative;
            @include font-size(15);
            &--older {
                @include rem-change(padding-left, 24px);
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 5px;
                    @include rem-change(width, 8px);
                    @include rem-change(height, 8px);
                    @include rem-change(margin-top, -6px);
                    border-top: 1px solid #B3B3B3;
                    border-right: 1px solid #B3B3B3;
                    -webkit-transform: rotate(225deg);
                            transform: rotate(225deg);
                    speak: none;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                    &:visited {
                        color: #193C81;
                    }
                    &:hover {
                        color: #4485F4;
                    }
                    &:active {
                        color: #0065BD;
                    }
                }
            }
            &--backToIndex {
                -webkit-box-flex: 0;
                -webkit-flex: 0 0 140px;
                    -ms-flex: 0 0 140px;
                        flex: 0 0 140px;
                text-align: center;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: "";
                    display: block;
                    @include rem-change(width, 1px);
                    height: 100%;
                    background: #D8D8D8;
                }
                &::after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    content: "";
                    display: block;
                    @include rem-change(width, 1px);
                    height: 100%;
                    background: #D8D8D8;
                }
            }
            &--newer {
                -webkit-box-flex: 0;
                -webkit-flex: 0 0 320px;
                    -ms-flex: 0 0 320px;
                        flex: 0 0 320px;
                padding-right: 25px;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    @include rem-change(width, 8px);
                    @include rem-change(height, 8px);
                    @include rem-change(margin-top, -6px);
                    border-top: 1px solid #B3B3B3;
                    border-right: 1px solid #B3B3B3;
                    -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                    speak: none;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                    &:visited {
                        color: #193C81;
                    }
                    &:hover {
                        color: #4485F4;
                    }
                    &:active {
                        color: #0065BD;
                    }
                }
            }
        }
    }
}