// #VARIABLES--local
$local-c-table-border-color: #dee2e6 !default;
$local-c-table-cell-padding-default: 12px !default;
$local-c-table-cell-padding-default: 12px !default;
.c-tab {
	&-nav {
		position: relative;
		display: -ms-flexbox;
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		padding-left: 0;
		margin-bottom: 0;
		border-bottom: solid thin #D8D8D8;
		list-style: none;
		// &:before {
		// 	content: "";
		// 	display: block;
		// 	position: absolute;
		// 	bottom: -1px;
		// 	width: 100%;
		// }
		&__item {
			width: 24.334%;
			margin-bottom: -1px;
			text-align: center;
			background-color: #F8F8F8;
			-webkit-box-sizing: border-box;
			        box-sizing: border-box;
			&.is-active {
				.c-tab-nav__link {
					color: #495057;
					background-color: #fff;
					border-top-color: #CC0000;
					border-bottom-color: #fff;
					font-weight: 700;
				}
				.c-tab-nav__textEn {
					letter-spacing: -1px;
				}
			}
			&:hover {
				.c-tab-nav__link {
					color: #1F6DF4;
					background-color: #fff;
					border-top-color: #CC0000;
					font-weight: 700;
					text-decoration: none;
					.c-tab-nav__text {
						text-decoration: underline;
						.c-tab-nav__textEn {
							letter-spacing: -1px;
						}
					}
				}
			}
		}
		&__link {
			display: block;
			height: 42px;
			padding: 8px 10px;
			border-top: solid 3px #DADADA;
			border-right: solid 1px #D8D8D8;
			border-bottom: solid 1px #D8D8D8;
			border-left: solid 1px #D8D8D8;
			@include font-size(15);
			color: #005AA6;
    		box-sizing: border-box;
		}
		&__text {
			.c-icon {
				color: #6B6B6B;
			}
			.c-icon-wordpress {
				color: #21759B;
			}
			&En {
				letter-spacing: -0.2px;
			}
		}
		&__oval {
			&:before {
				content: "●";
				@include rem-change(margin-left, 2px);
				@include font-size(8);
				line-height: 1;
				vertical-align: top;
				color: #cc0000;
			}
		}
		&--5 {
			.c-tab-nav__item {
				width: 19.3%;
			}
		}
		&--2 {
			.c-tab-nav__item {
				width: 49.5%;
			}
		}
		&--auto {
			-webkit-box-pack: left;
			-webkit-justify-content: left;
			    -ms-flex-pack: left;
			        -webkit-box-pack: normal;
			        -webkit-justify-content: normal;
			            -ms-flex-pack: normal;
					        justify-content: normal;
					-webkit-box-pack: start;
					-webkit-justify-content: flex-start;
					    -ms-flex-pack: start;
					        justify-content: flex-start;
			.c-tab-nav__item {
				width: auto;
				min-width: 120px;
				margin-right: 8px;
				&:last-child {
					margin-right: 0;
				}
				.c-tab-nav__link {
					height: auto;
					padding: 16px 40px;
				}
			}
		}
		&--withIcon {
			.c-tab-nav__link {
				height: 55px;
				padding: 14px 40px;
			.c-icon {
				margin-right: 8px;
				font-size: 23px;
				line-height: 1;
				color: #6B6B6B;
				&.c-icon-wordpress {
					color: #21759B;
				}
			}
			}
		}
	}
	&-container {
		position: relative;
		@include rem-change(padding, 20px 24px 40px);
		background-color: #fff;
		border-right: 1px solid #D8D8D8;
		border-bottom: 1px solid #D8D8D8;
		border-left: 1px solid #D8D8D8;
		&__function {
			position: absolute;
			top: 10px;
			right: 30px;
			@include font-size(15);
		}
		&__navigation {
			display: block;
		}
	}
	&-contents {
		&__item {
			display: none;
			&.is-active {
				display: block;
			}
		}
		&__heading {
			@include font-size(18);
			font-weight: 700;
			color: #333;
		}
	}
	&-block {
		// &-defInline {
		// 	span {
        //         display: inline-block;
        //     }
        //     .c-form-select {
        //         position: relative;
        //         select {
        //             color: #333;
        //             cursor: pointer;
        //             position: relative;
        //             z-index: 10;
        //             background-color: transparent;
        //             border: 1px solid #d6d6d6;
		// 			width: 100%;
		// 			@include rem-change(height, 31px);
		// 			@include rem-change(line-height, 31px);
		// 			@include rem-change(padding, 0 36px 0 15px);
        //             appearance: none;
        //             -ms-appearance: none;
        //             -webkit-appearance: none;
        //             -moz-appearance: none;
        //             appearance: none;
        //             -webkit-border-radius: 3px;
        //             -moz-border-radius: 3px;
        //             -ms-border-radius: 3px;
        //             -o-border-radius: 3px;
        //             border-radius: 3px;
        //             outline: none;
        //         }
        //         .c-icon.c-icon-arrow-down {
        //             position: absolute;
        //             top: 10px;
        //             right: 12px;
        //             @include font-size(12);
        //             color: #6B6B6B;
        //         }
        //     }
        //     dt,
        //     dd {
		// 		display: inline-block;
		// 		@include rem-change(margin-left, 8px);
        //         vertical-align: middle;
        //     }
		// }
		&-pagination {
			text-align: right;
            color: #333;
            .c-icon {
                color: #B3B3B3;
                @include font-size(16);
            }
		}
	}
	&__button {
		position: relative;
	}
}