@charset 'utf-8';

// =====================
// wizard
// =====================
.p-wizard {
    &-container {
        width: 900px;
        margin: 20px auto 0;

        &--narrow {
            width: 728px;
        }

        .c-heading-1st {
            margin-top: 128px;
            text-align: center;
        }

        .c-text,
        .c-text-size-xl {
            color: #333;
        }

        .c-text {
            .c-icon.c-icon-externallink {
                margin: 0 4px;
                @include font-size(12);
                color: #B3B3B3;
            }
        }

        .c-text-moderate {}

        .c-form {
            &__row {
                &:first-child {
                    padding-top: 0;
                }
            }

            &__checkbox {
                position: absolute;
                top: 2px;
                left: 0;
                outline: none;
                width: 18px;
                height: 18px;

                &:hover:before {
                    border-color: #1F6DF4;
                }
            }

            &__label {
                outline: none;
            }

            &__note {
                line-height: 2;
            }

            .c-form__col.is-validation .c-form__tooltip-validation {
                position: absolute;
                top: -78px;
                left: 10px;
                padding: 8px 10px;
                border-radius: 8px;
                background: rgba(0, 0, 0, 0.8);
                @include font-size(13);
                line-height: 1.6;
                text-align: left;
                color: #fff;
            }

            .c-grid__row {
                border-bottom: dotted thin #ccc;

                &:last-child {
                    border: none;
                }
            }
        }
    }

    &__heading {
        position: relative;
        line-height: 100px;
        @include font-size(32);
        color: #333;

        &:before {
            content: "\F02D";
            position: absolute;
            display: inline-block;
            @include font-size(100);
            font-family: "iconfont";
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            speak: none;
            color: #21759B;
            opacity: 0.2;
        }

        &Text {
            padding-left: 56px;
        }
    }

    &__image {
        text-align: center;
    }

    .l-main {
        width: auto;
        height: auto;
        margin: auto;
        padding-top: 0;
    }

    .l-footer {
        width: 100%;
        min-width: 900px;
        margin: 0;
    }

    .c-progressTracker {
        width: 820px;
        margin-right: auto;
        margin-left: auto;

        &>li {
            line-height: 1;

            &::before {
                -webkit-box-shadow: 0 0 0 4px #f4f5f8;
                box-shadow: 0 0 0 4px #f4f5f8;
            }
        }

    }

    .c-modal__contents {
        margin-top: 40px;

        p {
            margin: 0;
        }
    }

    .c-form {
        &-group {
            .c-badge {
                vertical-align: middle;
            }
        }

        &-label {
            outline: none;
        }

        &-checkbox:before {
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }

    .c-block-completion {
        padding: 0;

        &__text {
            margin-top: 8px;
        }
    }

    .c-block-completion {
        &__note {
            margin-top: 32px;
            @include font-size(15);
            color: #666;
        }
    }

    .c-card.c-card--wizard {
        width: 280px;
        height: 290px;
        padding: 24px;
        background-color: #fff;

        &:hover,
        &:focus {
            border: solid 1px #d8d8d8;
            background-color: #F8F8F8;
            cursor: pointer;

            .c-block-domainSelection {

                &__text,
                &__action {
                    color: #4485F4;
                }
            }
        }

        &:active {
            background: #E9E9E9;
        }

        &.is-active {
            border: 1px solid #1F6DF4;
        }

        &.is-disabled {
            border: 1px solid #d8d8d8;

            .c-panel__text-link {
                color: #b3b3b3;

                &:after {
                    border-top: 1px solid #b3b3b3;
                    border-right: 1px solid #b3b3b3;
                }
            }

            img {
                -webkit-filter: grayscale(80%);
                -moz-filter: grayscale(80%);
                -o-filter: grayscale(80%);
                -ms-filter: grayscale(80%);
                filter: grayscale(80%);
                opacity: 0.3;
            }
        }
    }
}

.p-wizard-media {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 24px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.10);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.10);
    border-radius: 4px;

    &__header {
        border-bottom: solid thin #D6D6D6;
    }

    &__heading {
        padding: 32px 40px 16px;
        @include first-line-Initialization();
        @include font-size(24);
        font-weight: 700;
        color: #666;
    }

    &__image {
        background-color: #cc0000;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &__contents {
        // -webkit-box-flex: 1;
        // -webkit-flex: auto;
        // -ms-flex: auto;
        // flex: auto;
        // display: -webkit-box;
        // display: -webkit-flex;
        // display: -ms-flexbox;
        // display: flex;
        // -webkit-box-orient: vertical;
        // -webkit-box-direction: normal;
        // -webkit-flex-direction: column;
        // -ms-flex-direction: column;
        // flex-direction: column;
        width: 100%;
    }

    &__body {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        min-height: 282px;
        width: 560px;
        margin: 0 auto;
        padding: 32px 0 40px;
    }

    &__block {
        width: 528px;
        margin: 32px auto 0;

        >.c-text-moderate {
            margin-top: -8px;
        }
    }

    &__footer {
        padding: 16px;
        border-radius: 0 0 4px 4px;
        border-top: 1px solid #e5e5e5;
        background: #F6F6F6;
    }

    .c-form__checkbox:before {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .c-form__col:first-child {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 180px;
        -ms-flex: 0 0 180px;
        flex: 0 0 180px;
    }
}

.p-wizard-selectDomain {
    border-bottom: dotted thin #ccc;
    padding-bottom: 8px;
    line-height: 1.2;
    overflow: hidden;

    &__characterString {
        float: left;
        width: 380px;
        margin-top: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &__button {
        float: right;
    }
}

.p-wizard-block-dbInfo {
    margin: 40px auto 0;

    &__heading {
        position: relative;
        font-weight: bold;
        line-height: 1;

        span {
            position: relative;
            padding-right: 8px;
            background-color: #fff;
            z-index: 2;
        }

        &::after {
            position: absolute;
            top: 7.5px;
            content: "";
            display: block;
            border-top: solid 1px #d8d8d8;
            width: 100%;
            height: 1px;
            z-index: 1;
        }
    }

    .c-form {
        width: 528px;
        margin: 16px auto;

        &__row {
            padding: 8px 0;
        }
    }
}

.p-wizard-panel {
    position: relative;
    width: 360px;
    height: auto;
    padding: 26px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 4px 0 #DADADA;
    box-shadow: 0 2px 4px 0 #DADADA;
    border: solid 1px #d8d8d8;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    z-index: $zindex-1;

    &__header {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;

        &:before {
            content: "\F02D";
            display: inline-block;
            height: 60px;
            font-size: 60px;
            font-family: "iconfont";
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            speak: none;
            color: #21759B;
        }
    }

    &__icon {
        width: 60px;
        height: 60px;
        margin-right: 8px;

        img[src*=".svg"] {
            width: 100%;
            height: auto;
        }
    }

    &__heading {
        padding-left: 16px;
        text-align: left;
        color: #333;
    }

    &__body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 16px;

        .c-form {
            &-label {
                z-index: $zindex-2;
                cursor: pointer;
                outline: none;
                line-height: 1.2;

                &__text {
                    @include font-size(13);
                    vertical-align: baseline;
                }
            }

            &-checkbox {
                outline: none;

                &:before {
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }

                &:hover:before {
                    border-color: #1F6DF4;
                }

                &__text {
                    margin-left: 4px;
                }
            }
        }

        .c-form-group {
            margin-bottom: -4px;
        }

        .c-button {
            padding: 8px;
        }
    }

    &__close {
        position: absolute;
        top: 8px;
        right: 8px;
        @include font-size(16);
        line-height: 1;
        color: #B3B3B3;
        z-index: $zindex-2;
        cursor: pointer;
    }

    &-floating {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: $zindex-1;

        &.is-hide {
            @include displayHide();
        }
    }
}