@charset 'utf-8';

.c-card {
	position: relative;
	width: auto;
	height: auto;
	@include rem-change(padding, 24px);
	-webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(12, 12, 13, 0.1);
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(12, 12, 13, 0.1);
	-webkit-box-shadow: 0 2px 4px 0 #DADADA;
	box-shadow: 0 2px 4px 0 #DADADA;
	border: solid 1px #d8d8d8;
	border-radius: 4px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	text-align: left;
	word-break: break-all;

	&-list {
		&--vertical {
			.c-card-list__item {
				@include rem-change(margin, 12px 0);
			}

			.c-card--size-s {
				@include rem-change(padding, 16px 20px);
			}
		}

		&__item {
			@include rem-change(margin, 0 8px);
		}

		&--interval-m {
			.c-card-list__item {
				@include rem-change(margin, 0 18px);
			}
		}
	}

	&-link,
	&-button {
		display: block;
		width: 100%;

		.c-card {
			// color: #0065bd;

			&:hover,
			&:focus {
				background-color: #F8F8F8;
				cursor: pointer;

				// color: red;
				.c-block-domainSelection {

					&__text,
					&__action {
						color: #4485F4;
					}
				}
			}

			&:active {
				background: #E9E9E9;
			}

			&.is-active {
				border: 1px solid #1F6DF4;
				// box-shadow: 0 0 4px 0 #4485F4;
			}

			&.is-disabled {
				border: 1px solid #d8d8d8;

				.c-panel__text-link {
					color: #b3b3b3;

					&:after {
						border-top: 1px solid #b3b3b3;
						border-right: 1px solid #b3b3b3;
					}
				}

				img {
					-webkit-filter: grayscale(80%);
					-moz-filter: grayscale(80%);
					-o-filter: grayscale(80%);
					-ms-filter: grayscale(80%);
					filter: grayscale(80%);
					opacity: 0.3;
				}
			}
		}
	}

	&-label {
		display: block;
		width: 100%;

		.c-card {
			color: #0065bd;

			&:hover,
			&:active {
				text-decoration: none;
				cursor: pointer;
			}

			&:hover {
				background-color: #fff;
				border: 1px solid #4485F4;
				color: #4485F4;

				a {
					text-decoration: none;
				}
			}

			&:focus {
				background-color: #F8F8F8;
			}

			&:active {
				background: #E9E9E9;
				color: #0065BD;
			}

			&:link {
				text-decoration: none;
			}

			&:visited {
				color: #5703AE;
			}

			&.is-active {
				border: 1px solid #1F6DF4;
				-webkit-box-shadow: 0 0 4px 0 #4485F4;
				box-shadow: 0 0 4px 0 #4485F4;
			}

			&.is-disabled {
				border: 1px solid #d8d8d8;

				.c-panel__text-link {
					color: #b3b3b3;

					&:after {
						border-top: 1px solid #b3b3b3;
						border-right: 1px solid #b3b3b3;
					}
				}

				img {
					-webkit-filter: grayscale(80%);
					-moz-filter: grayscale(80%);
					-o-filter: grayscale(80%);
					-ms-filter: grayscale(80%);
					filter: grayscale(80%);
					opacity: 0.3;
				}
			}
		}
	}

	&-link {
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	&-button {
		@include buttonReset();
		background-color: #fff;
	}

	&-label {
		outline: none;

		.c-form-group {
			&--radio {
				// @include rem-change(margin-top, 8px);

				.c-form-label__text {
					@include font-size(18);
					color: #666;
				}
			}
		}
	}

	&__text-moderate {
		@include font-size(13);
		color: #9B9B9B;
	}

	.c-form-radio {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		// -webkit-box-align: center;
		// -webkit-align-items: center;
		// -ms-flex-align: center;
		// align-items: center;

		&__text {
			position: relative;
			margin-left: 8px;
			@include font-size(18);
			color: #333;
			vertical-align: middle;
			line-height: 19px;

			&Recommend {
				position: absolute;
				right: -2.4em;
				top: 50%;
				margin-top: -8px;
				font-size: 15px;
				color: #D8261E;
				line-height: 1;
			}
		}

		&__button {
			vertical-align: text-top;
		}
	}

	.c-tooltip-icon {
		position: absolute;

		&.is-UpperRight {
			top: 12px;
			right: 12px;
		}

		&.is-UpperLeft {
			top: 12px;
			left: 12px;
		}

		&.is-bottomRight {
			bottom: 12px;
			right: 12px;
		}
	}

	&--w200 {
		@include rem-change(width, 200px);
	}

	&--w260 {
		@include rem-change(width, 260px);
	}

	&--w270 {
		@include rem-change(width, 270px);
	}

	&--w300 {
		@include rem-change(width, 300px);
	}

	&--w400 {
		@include rem-change(width, 400px);
	}

	&--w708 {
		width: 708px;

		.c-block-domainSelection__title {}

		.c-block-domainSelection__text {
			width: 390px;
		}
	}

	&--wAuto {
		width: auto;
	}

	&--h-m {
		@include rem-change(height, 206px);
	}

	&--h200 {
		@include rem-change(height, 200px);
	}

	&--h240 {
		@include rem-change(height, 240px);
	}

	&--h300 {
		@include rem-change(height, 300px);
	}

	&--h400 {
		@include rem-change(height, 400px);
	}

	&--size-m {
		width: 218px;
		height: 208px;
		padding: 20px 20px 0 24px;
	}

	&--size-l {
		width: 218px;
		height: 260px;
		padding: 20px 20px 0 24px;
	}

	&--oblong {
		padding: 16px 24px;
	}

	// &--size-s {
	// 	@include rem-change(padding, 16px 20px);
	// }
	// &--size-l {
	// 	@include rem-change(padding, 16px 20px);
	// }
}

.c-card-login {
	position: relative;
	width: 160px;
	height: 150px;
	@include rem-change(padding, 24px);
	-webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(12, 12, 13, 0.1);
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(12, 12, 13, 0.1);
	-webkit-box-shadow: 0 2px 4px 0 #DADADA;
	box-shadow: 0 2px 4px 0 #DADADA;
	border: solid 1px #d8d8d8;
	border-radius: 4px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	text-align: left;
	word-break: break-all;
	background: #fff;
	text-align: center;

	&__icon {
		display: block;
		font-size: 3.2rem;
		line-height: 1;

		.c-icon {
			color: #6B6B6B;

			&.c-icon-exchangeonline {
				color: #0078D7;
			}
		}
	}

	&__text {
		display: block;
		margin-top: 12px;
		font-size: 15px;
		line-height: 1;
		color: #333;
	}

	&:hover,
	&:focus {
		background-color: #f8f8f8;
		cursor: pointer;

		.c-button,
		.c-button--primary,
		.c-button,
		.c-button--primary {
			background-color: #0065BD;
			-webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
			transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
			-o-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
			transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
			transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
		}

		.c-card-login__text {
			color: #333;
		}
	}

	&:active {
		background-color: #e9e9e9;

		.c-card-login__text {
			color: #333;
		}
	}

	.c-button.c-button--primary {
		margin-top: 12px;
		padding: 7px 16px;

		.c-icon.c-icon-logout {
			margin-right: 12px;
			font-size: 16px;
		}
	}

	.c-tooltip-icon {
		position: absolute;
		top: 8px;
		right: 8px;
	}

	&-link {
		text-decoration: none !important;
	}

	&.is-disabled {
		border: 1px solid #d8d8d8;
		-webkit-box-shadow: none;
		box-shadow: none;
		opacity: 0.6;

		.c-card-login__icon {
			color: #DADADA;
			.c-icon.c-icon-exchangeonline {
				color: #DADADA;
			}
			img {
				-webkit-filter: grayscale(80%);
				-moz-filter: grayscale(80%);
				-o-filter: grayscale(80%);
				-ms-filter: grayscale(80%);
				filter: grayscale(80%);
				opacity: 0.3;
			}
		}

		.c-button {
			background-color: #D8D8D8;
			outline: none;
			color: #B3B3B3;
			pointer-events: none;
			cursor: auto;
		}

		.c-card-login {}

		.c-tooltip-icon {
			display: none;
		}
	}
}