html {
  font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 62.5%;
  height: 100%;
  min-height: 100%;
  position: relative;
  background-color: #f4f5f8;
}

body {
  overflow-y: scroll;
  height: 100%;
  @include font-size(15);
  color: #333;
  line-height: 1.5;
}

a {
  color: $color-onamae-text-blue;
  &:link {
    text-decoration: none;
  }
  &:visited {
    color: #5703AE;
  }
  &:hover,
  &:active {
    text-decoration: underline;
  }
  &:hover {
    color: #4485F4;
  }
  &:active {
    color: #0065BD;
  }
}
img {
  vertical-align: middle;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-type: none;
}
ul, ol, p, dl, dt, dd {
  margin: 0;
  padding: 0;
}
p,
th,
td {
  @include font-size(15);
  color: #333;
}

.is-disabled {
  outline: none;
  color: #B3B3B3;
  pointer-events: none;
  cursor: auto;
  span,
  a {
    color: #B3B3B3;
    &:visited,
    &:hover,
    &:active {
      color: #B3B3B3;
    }
  }
}
