// $local変数
$local-progressBar-primary: #e9e9e9;
$local-progressBar-meter-primary: #4485F4;
.c-progress-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	@include rem-change(margin-top, 10px);
	&__item {
		line-height: 1;
		.c-progressBar {
			margin-bottom: 0;
		}
		&:first-child {
			@include rem-change(width, 320px);
		}
	}
	&__itemNnm {
		display: inline-block;
		@include font-size(20);
		font-weight: 700;
		vertical-align: middle;
		color: #4485F4;
	}
	&__itemText {
		display: inline-block;
		@include font-size(12);
		vertical-align: sub;
	}
	&--left {
		// -webkit-box-pack: left;
		// -webkit-justify-content: left;
		//     -ms-flex-pack: left;
		//         justify-content: left;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		    -ms-flex-pack: start;
		        justify-content: flex-start;
		.c-progress-block__item {
			&:first-child {
				@include rem-change(width, 220px);
			}
			&Nnm {
				@include font-size(36);
				font-weight: 400;
			}
		}
		// .c-progress-block__itemNnm {
		// 	@include font-size(36);
		// 	font-weight: 400;
		// }
	}
}
.c-progressBar {
	overflow: hidden;
	@include rem-change(height, 4px);
	@include rem-change(margin-bottom, 20px);
	background-color: $local-progressBar-primary;
	border-radius: 4px;
	&__w160 {
		@include rem-change(width, 160px);
	}
	&__w200 {
		@include rem-change(width, 200px);
	}
	&__w400 {
		@include rem-change(width, 400px);
	}
	&__h8 {
		@include rem-change(height, 8px);
		background-color: #EEE;
	}
	&__h24 {
		@include rem-change(height, 24px);
		background-color: #EEE;
	}
	.c-progressBar__meter {
		float: left;
		width: 0%;
		height: 100%;
		@include font-size(12);
		line-height: 20px;
		color: #fff;
		text-align: center;
		background-color: $local-progressBar-meter-primary;
		-webkit-transition: width 0.6s ease;
		transition: width 0.6s ease;
		border-top-left-radius : 4px;
		border-bottom-left-radius : 4px;
		&--secondary {
			background-color: #005AA6;	
			border-radius: 0;
		}
	}
	&.is-warning {
		.c-progressBar__meter--primary {
			background-color: #FCB71B;	
		}
		.c-progressBar__meter--secondary {
			background-color: #F1990A;	
		}
	}
	&.is-danger {
		.c-progressBar__meter--primary {
			background-color: #F48080;	
		}
		.c-progressBar__meter--secondary {
			background-color: #D8261E;	
		}
	}
}
.c-progressTracker {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 0;
	padding: 0;
	width: auto;
	list-style: none;
	&::before {
		top: 9px;
		left: 11.4%;
		width: 77%;
		@include rem-change(height, 4px);
	}
	&::after {
		top: 0;
		right: 0;
		@include rem-change(width, 22px);
		@include rem-change(height, 22px);
		border-radius: 50%;
	}
	&::before {
		position: absolute;
		background-color: #D8D8D8;
		content: "";
	}
	&>li {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		    -ms-flex: 1;
		        flex: 1;
		position: relative;
		@include rem-change(padding-top, 30px);
		@include font-size(15);
		text-align: center;
		color: #B3B3B3;
		&.is-previous {
			color: #0065BD;
			&::before {
				background-color: #f4f5f8;
				border: solid 4px #4285f4;
			}
			&::after {
				top: 9px;
				left: 45%;
				width: 100%;
				@include rem-change(height, 4px);
				position: absolute;
				background-color: #4285f4;
				content: "";
			}
			&:last-child {
				&::after {
					width: 0;
				}
			}
		}
		&.is-latest {
			font-weight: 700;
			color: #0065BD;
			&::before {
				background-color: #4285f4;
				border: solid 4px #4285f4;
			}
			&::after {
				top: 9px;
				left: 45%;
				width: 100%;
				@include rem-change(height, 4px);
				position: absolute;
				background-color: #4285f4;
				content: "";
			}
			&::after {
				width: 0;
			}
		}
		&.is-passed {
			&::before {
				background: #FFF;
				border: solid 4px #D8D8D8;
				-webkit-transform: rotate(45deg);
				        transform: rotate(45deg);
				border-radius: 1px;
			}
			&.is-previous {
				color: #0065BD;
				&::before {
					background-color: #f4f5f8;
					border: solid 4px #4285f4;
				}
				&::after {
					top: 9px;
					left: 45%;
					width: 100%;
					@include rem-change(height, 4px);
					position: absolute;
					background-color: #4285f4;
					content: "";
				}
				&:last-child {
					&::after {
						width: 0;
					}
				}
			}
			&.is-latest {
				font-weight: 700;
				color: #0065BD;
				&::before {
					background-color: #FFF;
					border: solid 4px #4285f4;
				}
				&::after {
					top: 9px;
					left: 45%;
					width: 100%;
					@include rem-change(height, 4px);
					position: absolute;
					background-color: #4285f4;
					content: "";
				}
				&::after {
					width: 0;
				}
			}
		}
		&::before {
			position: absolute;
			top: 2px;
			left: 46%;
			@include rem-change(width, 8px);
			@include rem-change(height, 8px);
			margin: auto;
			background-color: #FFF;
			border: solid 4px #D8D8D8;
			border-radius: 50%;
			border-radius: 50%;
			-webkit-box-shadow: 0 0 0 4px #fff;
			        box-shadow: 0 0 0 4px #fff;
			z-index: $zindex-1;
			content: "";
		}
	}
	&--vertical {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		@include rem-change(height, 400px);
		&::after {
			top: auto;
			right: auto;
			bottom: 0;
			left: -2px;
		}
		&::before {
			top: 0;
			left: 6px;
			@include rem-change(width, 4px);
			height: 100%;
		}
		&>li {
			@include rem-change(padding-top, 4px);
			padding-bottom: 0;
			@include rem-change(padding-left, 30px);
			&.is-latest::after {
				top: 0;
				left: 6px;
				@include rem-change(width, 4px);
				height: 100%;
			}
			&::before {
				position: absolute;
				top: 2px;
				left: 46%;
				@include rem-change(width, 8px);
				@include rem-change(height, 8px);
				margin: auto;
				background-color: #fff;
				border: solid 4px #D8D8D8;
				border-radius: 50%;
				border-radius: 50%;
				-webkit-box-shadow: 0 0 0 4px #fff;
				        box-shadow: 0 0 0 4px #fff;
				z-index: $zindex-1;
				content: "";
			}
		}
	}
	&--doubleCircle {
		&>li {
			&::before {
				-webkit-box-shadow: 0 0 0 4px #fff, 0 0 0 5px #4485F4;
				        box-shadow: 0 0 0 4px #fff, 0 0 0 5px #4485F4;
			}
		}
	}
	&--doubleCircle {
		&>li {
			&::before {
				-webkit-box-shadow: 0 0 0 4px #fff, 0 0 0 5px #4485F4;
				        box-shadow: 0 0 0 4px #fff, 0 0 0 5px #4485F4;
			}
		}
	}
	&--num3 {
		&::before {
			left: 17%;
			width: 66%;
		}
	}
	&--num5 {
		&::before {
			left: 10%;
			width: 80%;
		}
	}
	&--num6 {
		&::before {
			left: 7.5%;
    		width: 85%;
		}
	}
	&--num7 {
		&::before {
			left: 7.5%;
			width: 86%;
		}
	}
	&--num7 {
		&::before {
			left: 7.5%;
			width: 86%;
		}
	}
	&--w820 {
		@include rem-change(width, 820px);
		margin: 0 auto;
	}
	&.is-show {
		display: block !important;
	}
	&.is-hidden {
		display: none !important;
	}
}

.c-progressCircle {
	display: inline-block;
	position: relative;
	width: 100px;
	height: 100px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	&:before {
		border: 4px solid #eee;
		content: "";
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 0;
		border-radius: 50%;
		-webkit-box-sizing: border-box;
		        box-sizing: border-box;
	}
	&-Lt50 {
		clip: rect(0, 100px, 100px, 50px);
		height: 100px;
		left: 0;
		position: absolute;
		top: 0;
		width: 100px;
		z-index: 1;
		border-radius: 50%;
		i {
			background: none;
			border: 4px solid #4485F4;
			clip: rect(0, 50px, 100px, 0);
			height: 100px;
			left: 0;
			top: 0;
			position: absolute;
			width: 100px;
			border-radius: 50%;
			-webkit-transition: 0.2ms;
			transition: 0.2ms;
			-webkit-box-sizing: border-box;
			        box-sizing: border-box;
		}
	}
	&-Gt50 {
		clip: rect(0, 50px, 100px, 0);
		height: 100px;
		left: 0;
		position: absolute;
		top: 0;
		width: 100px;
		z-index: 1;
		border-radius: 50%;
		i {
			background: none;
			border: 4px solid #4485F4;
			clip: rect(0, 50px, 100px, 0);
			height: 100px;
			left: 0;
			top: 0;
			position: absolute;
			width: 100px;
			border-radius: 50%;
			-webkit-transition: 0.2ms;
			transition: 0.2ms;
			-webkit-box-sizing: border-box;
			        box-sizing: border-box;
		}
	}
	&-block {
		margin-top: 30px;
		color: #4485F4;
		font-size: 14px;
		text-align: center;
		&__icon {
			line-height: 1;
			font-size: 18px;
		}
		&__text {
		}
	}
	&.is-congestion{
		// background: #D8261E;
		.c-progressCircle-Lt50,
		.c-progressCircle-Gt50 {
			i {
				border: 4px solid #F1990A;
			}
		}
		.c-progressCircle-block {
			color: #F1990A;
		}
	} 
}

// .c-progressCircle {
// 	position: relative;
//     width: 120px;
//     height: 120px;
//     background: #4485F4;
//     border-radius: 50%;
//     text-align: center;
//     overflow: hidden;
// 	z-index: 1;
// 	&::before {
//     	content: "";
// 		display: block;
// 		position: absolute;
// 		top: 0;
// 		left: -60px;
// 		width: 120px;
// 		height: 120px;
// 		background: #E9E9E9;
// 		transform-origin: right 60px;
// 		z-index: 2;
// 		// animation: rotate-circle-left 3s linear forwards;
// 	}
// 	&::after {
// 		content: "";
// 		display: block;
// 		position: absolute;
// 		top: 0px;
// 		left: 60px;
// 		width: 120px;
// 		height: 120px;
// 		background: #E9E9E9;
// 		transform-origin: left 60px;
// 		z-index: 3;
// 		animation: rotate-circle-right 3s linear forwards;
// 	}
// 	&__inner {
// 		position: absolute;
// 		top: 5px;
// 		left: 5px;
// 		width: 110px;
// 		height: 72px;
// 		padding-top: 38px;
// 		background: #fff;
// 		border-radius: 50%;
// 		z-index: 4;
// 	}
// }
// @keyframes rotate-circle-right {
// 	0% {
// 		transform: rotate(0deg);
// 	}
// 	15% {
// 		transform: rotate(90deg);
// 	}
// 	100% {
// 		transform: rotate(90deg);
// 	}
// }

// @keyframes rotate-circle-left {
//     0% {
// 		transform: rotate(0deg);
// 	}
// 	15% {
// 		transform: rotate(0deg);
// 	}
// 	18% {
// 		transform: rotate(0deg);
// 	}
// 	100% {
// 		transform: rotate(0deg);
// 	}
// }
