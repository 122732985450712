
.c-text {
	margin: 0;
	padding: 0;
	&-link {
		position: relative;
		margin-bottom: 0;
		padding: 0;
		&:after {
			display: inline-block;
			content: "";
			width: 4px;
			height: 4px;
			margin-left: 8px;
			border-top: 1px solid #0065bd;
			border-right: 1px solid #0065bd;
			-moz-transform: translate(-55%, -50%) rotate(45deg);
			-ms-transform: translate(-55%, -50%) rotate(45deg);
			-webkit-transform: translate(-55%, -50%) rotate(45deg);
			transform: translate(-55%, -50%) rotate(45deg);
			speak: none;
			transition: all 0.2s ease;
		}
		&:visited {
			color: #5703AE;
			border-color: #5703AE;
		}
		&:hover {
			color: #4485F4;
			text-decoration: underline;
		}
		&:active {
			color: #0065BD;
			text-decoration: underline;
		}
	}
	&-externallink {
		&::after {
			display: inline-block;
			font-family: "iconfont";
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			speak: none;
			vertical-align: middle;
			content: "\F027";
			font-size: 12px;
			color: #B3B3B3;
			vertical-align: -0.1em;
		    margin-left: 4px;
		}
	}
	&-size {
		&-xs {
			font-size: 10px !important;
			font-size: 1.0rem !important;
		}
		&-s {
			font-size: 13px !important;
			font-size: 1.3rem !important;
		}
		&-m {
			font-size: 15px !important;
			font-size: 1.5rem !important;
		}
		&-l {
			font-size: 18px !important;
			font-size: 1.8rem !important;
		}
		&-xl {
			font-size: 24px !important;
			font-size: 2.4rem !important;
		}
		&-xxl {
			font-size: 32px !important;
			font-size: 3.2rem !important;
		}
		&14 {
			font-size: 14px !important;
			font-size: 1.4rem !important;
		}
		&20 {
			font-size: 20px !important;
			font-size: 2rem !important;
		}
		&23 {
			font-size: 23px !important;
		}
		&35 {
			font-size: 35px !important;
			font-size: 3.5rem !important;
		}
		&55 {
			font-size: 55px !important;
		}
		&57 {
			font-size: 57px !important;
			font-size: 5.7rem !important;
		}
		&70 {
			font-size: 70px !important;
			font-size: 7rem !important;
		}
		&120 {
			font-size: 120px !important;
			font-size: 12rem !important;
		}
		&144 {
			font-size: 144px !important;
			font-size: 14.4rem !important;
		}
	}
	&-weight {
		&-bold {
			font-weight: 700 !important;
		}
		&-normal {
			font-weight: 400 !important;
		}
		&-light {
			font-weight: 300 !important;
		}
	}
	&-color {
		&-moderate {
			color: #666 !important;
		}
		&-note {
			color: #D8261E !important;
		}
		&-brandColor {
			color: $color-onamae-red !important;
		}
		&-blue {
			color: #0065bd !important;
		}
		&-blue-emphasis {
			color: #1F6DF4 !important;
		}
		&-annotation {
			color: #F1990A !important;
		}
	}
	&-align {
		&-left {
			text-align: left !important;
		}
		&-center {
			text-align: center !important;
		}
		&-right {
			text-align: right !important;
		}
	}
	&-letterSpacing {
		&-xs {
			font-size: 10px !important;
			font-size: 1.0rem !important;
		}
		&-s {
			font-size: 13px !important;
			font-size: 1.3rem !important;
		}
		&-m {
			font-size: 15px !important;
			font-size: 1.5rem !important;
		}
		&-l {
			font-size: 18px !important;
			font-size: 1.8rem !important;
		}
		&-xl {
			font-size: 24px !important;
			font-size: 2.4rem !important;
		}
		&-xxl {
			font-size: 32px !important;
			font-size: 3.2rem !important;
		}
		&14 {
			font-size: 14px !important;
			font-size: 1.4rem !important;
		}
		&20 {
			font-size: 20px !important;
			font-size: 2rem !important;
		}
	}
	&-decoration-underline {
		text-decoration: underline;
	}
	&-decoration-none {
		text-decoration: none;
	}
	&-decoration-lineThrough {
		text-decoration: line-through;
	}
	&-truncate {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&-moderate {
		@include font-size(13);
		@include first-line-Initialization();
		color: #666;
		&.is-disabled {
            outline: none;
            color: #B3B3B3;
            pointer-events: none;
            cursor: auto;
            .c-text-color-blue {
                color: #B3B3B3 !important;
            }
        }
	}
	&-note {
		@include font-size(13);
		// @include first-line-Initialization();
		color: #D8261E;
		&.is-hidden {
			display: none;
		}
	}
	&-indent {
		&-1word {
			text-indent: -1em;
			padding-left: 1em;
		}
		&-2word {
			text-indent: -2em;
			padding-left: 2em;
		}
	}
	&-button {
		@include buttonReset;
		color: #0065bd;
		&:visited {
			color: #5703AE;
			border-color: #5703AE;
		}
		&:hover {
			color: #4485F4;
			text-decoration: underline;
		}
		&:active {
			color: #0065BD;
			text-decoration: underline;
		}
	}
	&-list {
		&-disc {
			&__item {
				&:before {
					content: '';
					width: 3px;
					width: 0.3rem;
					height: 3px;
					height: 0.3rem;
					display: inline-block;
					border-radius: 100%;
					background: #333;
					position: relative;
					left: -10px;
					top: -3px;
				}
			}
			.c-text-list-disc.c-text-list-disc--nestingFirst {
				margin-left: 1em;
			}
		}
	}
	.c-icon.c-icon-externallink {
		&:before {
			vertical-align: -.125em;
		}
	}
}

.c-text-icon {
	display:flex;
	align-items: center;
	&__icon {
		margin-right: 6px;
		font-size: 20px;
		color: #4485F4;
		line-height: 0;
		&:before {
			vertical-align: -1px;
		}
		
	}
	&__text {
		line-height: 1;
	}
	&.is-disabled {
		outline: none;
		pointer-events: none;
		color: #B3B3B3;
		span,
		a {
			color: #B3B3B3;

			&:visited {
				color: #B3B3B3;
			}

			&:hover {
				color: #B3B3B3;
			}

			&:active {
				color: #B3B3B3;
			}
		}
		.c-text-icon__icon {
			visibility: hidden;
		}
	}
	&.c-text-note {
		.c-text-icon__text {
			font-size: 13px !important;
			color: #D8261E !important;
		}
		.c-text-icon__icon {
			font-size: 18px !important;
			color: #D8261E !important;
		}
	}
}