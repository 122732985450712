@charset "UTF-8";

input[type="radio"],
input[type="checkbox"] {
    display: none;
}
/* IE 10 以降 */
:-ms-input-placeholder {
    color: #ddd;
}

textarea.is-error,
textarea.is-error:focus,
input[type="text"].is-error,
input[type="text"].is-error:focus,
input[type="email"].is-error,
input[type="email"].is-error:focus,
input[type="password"].is-error,
input[type="password"].is-error:focus,
input[type="number"].is-error,
input[type="number"].is-error:focus,
    {
    background-color: #FFE7E7;
    border: 1px solid #D8261E;
    -webkit-box-shadow: 0 0 3px 0 #D8261E;
    box-shadow: 0 0 3px 0 #D8261E;
    color: #333;
    outline: none;
}

.c-form {

    textarea.is-error,
    textarea.is-error:focus,
    input[type="text"].is-error,
    input[type="text"].is-error:focus,
    input[type="email"].is-error,
    input[type="email"].is-error:focus,
    input[type="password"].is-error,
    input[type="password"].is-error:focus,
    input[type="number"].is-error,
    input[type="number"].is-error:focus,
        {
        background-color: #FFE7E7;
        border: 1px solid #D8261E;
        -webkit-box-shadow: 0 0 3px 0 #D8261E;
        box-shadow: 0 0 3px 0 #D8261E;
        color: #333;
        outline: none;
    }

    margin-bottom: 0;

    &__row {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        @include rem-change(padding, 12px 0);
        border-bottom: 1px dotted #D8D8D8;
    }

    &__col {
        position: relative;
        padding-right: 8px;
        word-break: break-all;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 1;
        -ms-flex: 0 0 1;
        flex: 0 0 1;
        width: 100%;

        &:first-child {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 170px;
            -ms-flex: 0 0 170px;
            flex: 0 0 170px;
        }

        &--w220 {
            &:first-child {
                -webkit-box-flex: 0;
                -webkit-flex: 0 0 220px;
                -ms-flex: 0 0 220px;
                flex: 0 0 220px;
            }
        }
        &.is-unset {
        color: #B3B3B3;
        }
    }

    &-note {
        display: block;
        @include rem-change(margin-top, 8px);
        @include first-line-Initialization();
        @include font-size(13);

        &.is-error {
            color: #D0021B;
        }

        &.is-glay {
            color: #666666;
        }

        &.is-lightGray {
            color: #B3B3B3;
        }

        &.is-lightGray02 {
            color: #999999;
        }

        &--inilne {
            display: inline;
        }

        .c-icon.c-icon-externallink {
            @include rem-change(margin, 0 4px);
            @include font-size(12);
            color: #B3B3B3;
            line-height: 0;
        }
    }

    &-evaluation {
        &.is-low {
            color: #D0021B;
        }

        &.is-high {
            color: #0065bd;
        }
    }

    &-label {
        position: relative;

        +.c-badge {
            @include rem-change(margin-left, 4px);
        }
    }

    &-group {
        &-list {
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;

            &__item {
                @include rem-change(margin-right, 12px);
            }

            &--vertical {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-flow: column nowrap;
                -ms-flex-flow: column nowrap;
                flex-flow: column nowrap;

                .c-form-group-list__item {
                    &:first-child {
                        margin-top: 0;
                    }

                    margin-top: 12px;
                }
            }
        }

        &.is-disabled {
            outline: none;
            color: #B3B3B3;
            pointer-events: none;
            cursor: auto;

            .c-badge {
                background: #D8D8D8;
                color: #fff;
            }
        }
    }

    &-radio,
    &-checkbox {
        position: relative;
        color: #333;
        outline: none;

        +.c-badge {
            @include rem-change(margin-left, 4px);
        }

        &:hover {
            cursor: pointer;
        }
    }

    &-radio {
        position: relative;

        input:checked+.c-form-radio__button {
            border-color: #4485f4 !important;
            border-width: 2px;
            -webkit-box-shadow: none;
            box-shadow: none;

            &:after {
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
                @include rem-change(width, 12px);
                @include rem-change(height, 12px);
                @include rem-change(margin, -6px 0 0 -6px);
                opacity: 1;
            }
        }

        &__button {
            position: relative;
            display: inline-block;
            @include rem-change(width, 16px);
            @include rem-change(height, 16px);
            border-width: 2px;
            border-style: solid;
            border-color: #D8D8D8;
            border-radius: 50%;
            vertical-align: middle;
            cursor: pointer;
            background-color: #fff;
            outline: none;

            &--shadow {
                -webkit-box-shadow: rgba(0, 0, 0, 0.6) 0px 4px 12px -6px;
                box-shadow: rgba(0, 0, 0, 0.6) 0px 4px 12px -6px;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                @include rem-change(width, 1px);
                @include rem-change(height, 1px);
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
                opacity: 0;
                background-color: #4485f4;
                border-radius: 50%;
            }
        }

        &__text {
            vertical-align: -1px;
        }

        &--inline {
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            @include rem-change(margin-right, 8px);
            padding-left: 0;
        }
    }

    &-checkbox {
        input:checked+.c-form-checkbox__button:before {
            border-color: #4485f4 !important;
            background-color: #4485f4;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        input:checked+.c-form-checkbox__button:after {
            visibility: visible;
        }

        &__button {
            position: relative;
            outline: none;
            display: inline-block;
            @include rem-change(width, 18px);
            @include rem-change(height, 18px);
            @include rem-change(margin-top, -1px);
            vertical-align: middle;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                @include rem-change(width, 18px);
                @include rem-change(height, 18px);
                border-width: 1px;
                border-style: solid;
                border-color: #b3b3b3;
                border-radius: 3px;
                background-color: #fff;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 2px;
                left: 6px;
                @include rem-change(width, 4px);
                @include rem-change(height, 9px);
                border-style: solid;
                border-color: #fff;
                border-width: 0 2px 2px 0;
                visibility: hidden;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        &__text {
            margin-left: 8px;
            vertical-align: middle;
        }
    }

    &-switch {
        position: relative;
        width:80px;
        height:28px;

        input[type="radio"]:checked+.c-form-switch__label:before,
        input[type="checkbox"]:checked+.c-form-switch__label:before {
            filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
            opacity: 0;
        }

        input[type="radio"]:checked+.c-form-switch__label:after,
        input[type="checkbox"]:checked+.c-form-switch__label:after {
            filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
            opacity: 1;
        }

        input[type="radio"]:checked+.c-form-switch__label span,
        input[type="checkbox"]:checked+.c-form-switch__label span {
            left: 54px;
        }

        &__label {
            display: inline-block;
            cursor: pointer;
            width:80px;
            height:28px;
            vertical-align: middle;
            background-color: #ccc;
            border: 1px solid #D8D8D8;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-radius: 4px;
            overflow: hidden;
            outline: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            span {
                display: block;
                position: absolute;
                top: 0;
                left: -1px;
                width:30px;
                height:28px;
                margin-left:-1px;
                font: 0/0 a;
                color: transparent;
                text-shadow: none;
                background-color: transparent;
                background-color: #fff;
                border: 0;
                border-radius: 4px;
                -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
                box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
                -webkit-transition: left false false, 0.1s false false, ease-in false false;
                -webkit-transition: left 0.1s ease-in;
                transition: left 0.1s ease-in;
                z-index: $zindex-1;
            }

            &:before {
                content: "\004F\0046\0046";
                padding-right: 14px;
                text-align: right !important;
            }

            &:after {
                content: "\004F\004E";
                padding-left: 16px;
                background-color: #4485f4;
                text-align: left !important;
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
                opacity: 0;
            }

            &:before,
            &:after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                -webkit-transition: opacity false false, 0.1s false false, ease-in false false;
                -webkit-transition: opacity 0.1s ease-in;
                transition: opacity 0.1s ease-in;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                @include font-size(13);
                font-weight: 700;
                line-height: 28px;
                color: #fff;
                border-radius: 4px;
            }
        }

        &--enabledOrDisabled {
            .c-form-switch__label {
                &:before {
                    content: "\7121\52B9";
                    padding-right: 14px;
                    text-align: right !important;
                }

                &:after {
                    content: "\6709\52B9";
                    padding-left: 14px;
                    background-color: #4485f4;
                    text-align: left !important;
                    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
                    opacity: 0;
                }
            }
        }
    }

    &-field {
        position: relative;

        &__input {
            overflow: visible;
            height: 32px;
            line-height: 32px;
            padding: 0 12px;
            letter-spacing: 0;
            border: 1px solid #979797;
            border-radius: 4px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;

            &--w240 {
                width: 240px;
            }

            &--w320 {
                width: 320px;
            }

            &--w340 {
                width: 340px;
            }

            &+.c-form-field__icon {
                position: relative;
                margin-left: -30px;

                .c-icon {
                    @include font-size(24);
                    color: #B3B3B3;
                    line-height: 1;
                }

                .c-icon.c-icon-search {
                    @include font-size(18);
                    color: #B3B3B3;
                }
            }

            &:placeholder-shown {
                color: #B3B3B3;
            }

            &:hover {
                border: 1px solid #333;
            }

            &:active,
            &:focus {
                border: 1px solid #1F6DF4;
                -webkit-box-shadow: 0 0 3px 0 #4485F4;
                box-shadow: 0 0 3px 0 #4485F4;
                outline: none;
            }

            &.is-settled {
                height: auto;
                line-height: 1;
                padding: 0;
                background-color: transparent;
                border: solid transparent;
                border-top-width: medium;
                border-right-width: medium;
                border-bottom-width: medium;
                border-left-width: medium;
                border-width: 1px 0;

                &:active,
                &:focus {
                    border-color: none;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    outline: none;
                }
            }
        }

        &--password {
            .c-form-field__icon {
                @include rem-change(margin-left, 8px);
                display: inline-block;

                .c-icon {
                    @include font-size(24);
                    color: #B3B3B3;
                    line-height: 1;

                    &:visited {
                        color: #5703AE;
                    }

                    &:hover {
                        color: #4485F4;
                        cursor: pointer;
                    }

                    &:active {
                        color: #0065BD;
                    }
                }
            }
        }

        &-search {
            width: 100%;
            position: relative;
            display: table;
            border-collapse: separate;

            &--w240 {
                @include rem-change(width, 240px);
            }

            &--w340 {
                @include rem-change(width, 340px);
            }

            input {
                // width: 100%;
                vertical-align: middle;
            }

            &-button {
                display: table-cell;
                width: 72px;
                font-size: 14px;
                font-weight: normal;
                line-height: 0;
                color: #555555;
                text-align: center;
                background-color: #eeeeee;
                border: 1px solid #979797;
                border-left-color: #cccccc;
                border-left-style: solid;
                border-left-width: 1px;
                border-radius: 4px;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                border-left: 0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                cursor: pointer;
                vertical-align: middle;

                &__text {
                    vertical-align: middle;
                }

                &:hover,
                &:focus {
                    background-color: #E9E9E9;
                    @include transition-hover-button();
                }

                &:active {
                    background-color: #D8D8D8;
                }

                .c-icon {
                    color: #b4b4b4;
                }
            }

            &-cancelButton {
                position: absolute;
                right: 88px;
                top: 9px;
                color: #ccc;
                cursor: pointer;
                display: block;
                width: 12px;
                height: 12px;
                font-size: 12px;
                line-height: 1;

                .c-icon {
                    color: #B3B3B3;
                    line-height: 0;

                    &:visited {
                        color: #5703AE;
                    }

                    &:hover {
                        color: #4485F4;
                        cursor: pointer;
                    }

                    &:active {
                        color: #0065BD;
                    }
                }
            }

            &__input {
                display: table-cell;
                overflow: visible;
                @include rem-change(padding, 0 32px 0 12px);
                @include rem-change(height, 32px);
                width: 100%;
                @include rem-change(line-height, 20px);
                letter-spacing: 0;
                border: 1px solid #979797;
                border-radius: 4px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border-right: solid thin #d6d6d6;

                &--w240 {
                    @include rem-change(width, 240px);
                }

                &--w340 {
                    @include rem-change(width, 340px);
                }

                &+.c-form-field__icon {
                    position: relative;
                    margin-left: -30px;

                    .c-icon {
                        @include font-size(24);
                        color: #B3B3B3;
                        line-height: 1;
                    }

                    .c-icon.c-icon-search {
                        @include font-size(18);
                        color: #B3B3B3;
                    }
                }

                &:placeholder-shown {
                    color: #B3B3B3;
                }

                &:-ms-input-placeholder {
                    color: #B3B3B3;
                }

                &::-webkit-input-placeholder {
                    color: #B3B3B3;
                }



                &:hover {
                    border: 1px solid #333;
                }

                &:active,
                &:focus {
                    border: 1px solid #1F6DF4;
                    -webkit-box-shadow: 0 0 3px 0 #4485F4;
                    box-shadow: 0 0 3px 0 #4485F4;
                    outline: none;
                }

                &.is-settled {
                    height: auto;
                    line-height: 1;
                    padding: 0;
                    background-color: transparent;
                    border: solid transparent;
                    border-top-width: medium;
                    border-right-width: medium;
                    border-bottom-width: medium;
                    border-left-width: medium;
                    border-width: 1px 0;

                    &:active,
                    &:focus {
                        border-color: none;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        outline: none;
                    }
                }

                &::-ms-clear {
                    display: none;
                }
            }

            &-pulldown {
                display: table-cell;
                vertical-align: middle;

                .c-form-select {
                    position: relative;
                    display: block;

                    select {
                        color: #333;
                        cursor: pointer;
                        position: relative;
                        z-index: $zindex-1;
                        background-color: transparent;
                        border: 1px solid #979797;
                        border-right-color: #cccccc;
                        border-right-style: solid;
                        border-right-width: 1px;
                        border-radius: 4px;
                        border-bottom-right-radius: 0;
                        border-top-right-radius: 0;
                        border-right: 0;
                        width: 100%;
                        @include rem-change(height, 32px);
                        @include rem-change(line-height, 32px);
                        @include rem-change(padding, 0 28px 0 12px);
                        @include font-size(13);
                        -ms-appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        outline: none;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                    }

                    select::-ms-expand {
                        display: none;
                    }

                    .c-icon.c-icon-arrow-down {
                        position: absolute;
                        // top: 10px;
                        // top: 16px;
                        top: 50%;
                        right: 8px;
                        margin-top: -6px;
                        @include font-size(12);
                        color: #6b6b6b;
                        width: 12px;
                        height: 12px;
                        line-height: 12px;

                        &:before {}
                    }
                }

                +.c-form-field-search__input {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-left: solid thin #d6d6d6;

                    &:hover {
                        border: 1px solid #333;
                    }

                    &:active,
                    &:focus {
                        border: 1px solid #1F6DF4;
                        -webkit-box-shadow: 0 0 3px 0 #4485F4;
                        box-shadow: 0 0 3px 0 #4485F4;
                        outline: none;
                    }

                    &.is-settled {
                        height: auto;
                        line-height: 1;
                        padding: 0;
                        background-color: transparent;
                        border: solid transparent;
                        border-top-width: medium;
                        border-right-width: medium;
                        border-bottom-width: medium;
                        border-left-width: medium;
                        border-width: 1px 0;

                        &:active,
                        &:focus {
                            border-color: none;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                            outline: none;
                        }
                    }

                    &::-ms-clear {
                        display: none;
                    }

                }
            }
        }
    }

    &-textarea {
        position: relative;

        &__textarea {
            width: 100%;
            @include rem-change(padding, 8px 12px);
            border: 1px solid #979797;
            border-radius: 4px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            resize: vertical;
            vertical-align: top;

            &--w240 {
                @include rem-change(width, 240px);
            }

            &--w528 {
                @include rem-change(width, 528px);
            }

            &--h160 {
                @include rem-change(height, 160px);
            }

            &--h232 {
                @include rem-change(height, 232px);
            }

            &.is-no-resize {
                resize: none;
            }

            &:placeholder-shown {
                color: #B3B3B3;
            }

            &:hover {
                border: 1px solid #333;
            }

            &:active,
            &:focus {
                border: 1px solid #1F6DF4;
                -webkit-box-shadow: 0 0 3px 0 #4485F4;
                box-shadow: 0 0 3px 0 #4485F4;
                outline: none;
            }

            &.is-settled {
                height: auto;
                line-height: 1;
                padding: 0;
                background-color: transparent;
                border: solid transparent;
                border-top-width: medium;
                border-right-width: medium;
                border-bottom-width: medium;
                border-left-width: medium;
                border-width: 1px 0;

                &:active,
                &:focus {
                    border-color: none;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    outline: none;
                }
            }
        }
    }

    &--center {
        margin: 0 auto;
    }

    &--vertical {
        .c-form__row {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .c-form__col:first-child {
            width: auto;
            -webkit-box-flex: 1;
            -webkit-flex: auto;
            -ms-flex: auto;
            flex: auto;
            @include rem-change(margin-bottom, 8px);
        }
    }

    &--w580 {
        @include rem-change(width, 580px);
    }

    &--w800 {
        @include rem-change(width, 800px);
    }
}

.c-form-select {
    position: relative;

    &__select {
        color: #333;
        cursor: pointer;
        position: relative;
        z-index: $zindex-1;
        background-color: transparent;
        border: 1px solid #d6d6d6;
        width: 100%;
        @include rem-change(height, 31px);
        @include rem-change(line-height, 31px);
        @include rem-change(padding, 0 36px 0 15px);
        -ms-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 3px;
        outline: none;
        &--w240 {
            width: 240px;
        }
        &:active, &:focus {
            border: 1px solid #1F6DF4;
            -webkit-box-shadow: 0 0 3px 0 #4485F4;
            box-shadow: 0 0 3px 0 #4485F4;
            outline: none;
        }
    }

    select::-ms-expand {
        display: none;
    }

    .c-icon.c-icon-arrow-down {
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -6px;
        @include font-size(12);
        color: #6b6b6b;
        line-height: 1;
    }
}


.c-form__tooltip-validation {
    position: absolute;
    top: -80%;
    left: 0;
    @include rem-change(padding, 8px 10px);
    @include font-size(13);
    line-height: 1.6;
    text-align: left;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;

    &:after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 14px;
        border-left: solid 6px transparent;
        border-right: solid 6px transparent;
        border-top: solid 6px rgba(0, 0, 0, 0.8);
    }
}

.c-form-validation {
    position: absolute;
    bottom: 2px;
    left: 24px;
    max-width: 350px;
    margin-right: -24px;
    @include rem-change(padding, 8px 10px);
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    @include font-size(13);
    line-height: 1.6;
    text-align: left;
    color: #fff;
    box-sizing: border-box;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 14px;
        border-left: solid 6px transparent;
        border-right: solid 6px transparent;
        border-top: solid 6px rgba(0, 0, 0, 0.8);
    }

    &-wrap {
        position: relative;
    }
}


// throw away_20180803
.c-form {
    &__input {
        overflow: visible;
        @include rem-change(height, 32px);
        @include rem-change(padding, 0 12px);
        border: 1px solid #979797;
        border-radius: 4px;
        @include font-size(15);
        color: #333333;
        letter-spacing: 0;

        &--w240 {
            @include rem-change(width, 240px);
        }

        &--w340 {
            @include rem-change(width, 340px);
        }

        &+.c-form__icon {
            position: relative;
            display: inline-block;

            .c-icon {
                position: absolute;
                top: -17px;
                right: 10px;
                @include font-size(24);
                color: #B3B3B3;
            }
        }

        &:placeholder-shown {
            color: #B3B3B3;
        }

        &:hover {
            border: 1px solid #333333;
        }

        &:active,
        &:focus {
            border: 1px solid #1F6DF4;
            -webkit-box-shadow: 0 0 3px 0 #4485F4;
            box-shadow: 0 0 3px 0 #4485F4;
            outline: none;
        }

        &.is-settled {
            height: auto;
            padding: 0;
            background-color: transparent;
            border: solid transparent;
            border-top-width: medium;
            border-right-width: medium;
            border-bottom-width: medium;
            border-left-width: medium;
            border-width: 1px 0;

            &:active,
            &:focus {
                border-color: none;
                -webkit-box-shadow: none;
                box-shadow: none;
                outline: none;
            }
        }

        &-wrap {
            &--password {
                .c-icon {
                    &:visited {
                        color: #5703AE;
                    }

                    &:hover {
                        color: #4485F4;
                        cursor: pointer;
                    }

                    &:active {
                        color: #0065BD;
                    }

                    &.is-disabled {
                        outline: none;
                        pointer-events: none;
                        color: #B3B3B3;

                        span,
                        a {
                            color: #B3B3B3;

                            &:visited {
                                color: #B3B3B3;
                            }

                            &:hover {
                                color: #B3B3B3;
                            }

                            &:active {
                                color: #B3B3B3;
                            }
                        }
                    }
                }
            }

            &--search {
                &:before {
                    content: "\F02B";
                    display: inline-block;
                    font-family: "iconfont";
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    speak: none;
                    vertical-align: middle;
                    font-size: 18px;
                    font-size: 1.8rem;
                    color: #B3B3B3;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    &__note {
        display: block;
        @include rem-change(margin-top, 8px);
        @include first-line-Initialization();
        @include font-size(13);
        letter-spacing: 0;

        &.is-error {
            color: #D0021B;
        }

        &.is-glay {
            color: #666666;
        }

        &.is-lightGray {
            color: #B3B3B3;
        }

        &.is-lightGray02 {
            color: #999999;
        }

        &--inilne {
            display: inline;
        }
    }

    &-input {
        overflow: visible;
        @include rem-change(height, 32px);
        @include rem-change(padding, 0 12px);
        border: 1px solid #979797;
        border-radius: 4px;
        @include font-size(15);
        color: #333333;
        letter-spacing: 0;

        &--w240 {
            @include rem-change(width, 240px);
        }

        &--w340 {
            @include rem-change(width, 340px);
        }

        &+.c-form__icon {
            position: relative;
            display: inline-block;

            .c-icon {
                position: absolute;
                top: -17px;
                right: 10px;
                @include font-size(24);
                color: #B3B3B3;
            }
        }

        &:placeholder-shown {
            color: #B3B3B3;
        }

        &:hover {
            border: 1px solid #333333;
        }

        &:active,
        &:focus {
            border: 1px solid #1F6DF4;
            -webkit-box-shadow: 0 0 3px 0 #4485F4;
            box-shadow: 0 0 3px 0 #4485F4;
            outline: none;
        }

        &.is-settled {
            height: auto;
            padding: 0;
            background-color: transparent;
            border: solid transparent;
            border-top-width: medium;
            border-right-width: medium;
            border-bottom-width: medium;
            border-left-width: medium;
            border-width: 1px 0;
        }

        &-wrap {
            &--password {
                .c-icon {
                    &:visited {
                        color: #5703AE;
                    }

                    &:hover {
                        color: #4485F4;
                        cursor: pointer;
                    }

                    &:active {
                        color: #0065BD;
                    }

                    &.is-disabled {
                        outline: none;
                        pointer-events: none;
                        color: #B3B3B3;

                        span,
                        a {
                            color: #B3B3B3;

                            &:visited {
                                color: #B3B3B3;
                            }

                            &:hover {
                                color: #B3B3B3;
                            }

                            &:active {
                                color: #B3B3B3;
                            }
                        }
                    }
                }
            }
        }
    }

    &__checkbox {
        position: absolute;
        top: 0;
        left: 0;

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            @include rem-change(width, 18px);
            @include rem-change(height, 18px);
            border-width: 1px;
            border-style: solid;
            border-color: #b3b3b3;
            border-radius: 3px;
            -webkit-box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 5px;
            box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 5px;
            background-color: #fff;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 2px;
            left: 6px;
            @include rem-change(width, 4px);
            @include rem-change(height, 9px);
            border-style: solid;
            border-color: #fff;
            border-width: 0 2px 2px 0;
            visibility: hidden;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.c-form-field__input-wrap.c-form-field__input-wrap--search {
    &::after {
        content: "\F02B";
        display: inline-block;
        margin-left: -24px;
        @include font-size(18);
        font-family: "iconfont";
        font-style: normal;
        font-weight: normal;
        vertical-align: middle;
        color: #B3B3B3;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        speak: none;
    }
}