.c-pagination {
	display: block;
	@include rem-change(margin-top, 24px);
	&__list {
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		margin: 0;
		padding-left: 0;
		list-style: none;
	}
	&__item {
		@include rem-change(margin, 0 2px);
		&:first-child {
			.c-icon-arrow-left {
				margin-right: 8px;
			}
		}
		&:last-child {
			.c-icon-arrow-right {
				margin-left: 8px;
			}
		}
		&.is-disabled {
			visibility: hidden;
		}
		&.is-active {
			font-weight: 700;
			color: #333;
			span {
				display: inline-block;
				@include font-size(18);
				text-align: center;
				line-height: 36px;
				vertical-align: middle;
				min-width: 36px;
				padding: 0 10px;
				background: #4485F4;
				border-radius: 4px;
				@include font-size(18);
				color: #fff;
				box-sizing: border-box;
			}
		}
		&--previous,
		&--next {
			.c-pagination__link {
				border-radius: 4px;
				@include font-size(15);
			}
		}
	}
	&__dots,
	&__link {
		display: inline-block;
		@include font-size(18);
		text-align: center;
		line-height: 36px;
		vertical-align: middle;
	}
	&__dots {
		min-width: 24px;
	}
	&__link {
		min-width: 36px;
		padding: 0 10px;
		border-radius: 4px;
		@include font-size(18);
		@include text-link-action();
		box-sizing: border-box;
		.c-icon {
			@include font-size(16);
			color: #B3B3B3;
			&:before {
				vertical-align: -0.1em;
				display: inline-block;
			}
		}
		&:visited {
			color: #5703AE;
			border-color: #5703AE;
		}
		&:hover {
			background: #F4F4F4;
			border-radius: 4px;
			color: #4485F4;
			text-decoration: underline;
		}
		&:active {
			color: #0065BD;
			text-decoration: underline;
		}
	}
	&--right {
		.c-pagination__list {
			justify-content: flex-end;
		}
	}
	&--left {
		.c-pagination__list {
			justify-content: flex-start;
		}
	}
	// & is-
}