.l-nav {
    position: fixed;
    top: 48px;
    bottom: 0;
    width: 220px;
    background-color: #fff;
    border: 1px solid #dadada;
    border-right-color: $color-onamae-gray-2;
    border-left-color: $color-onamae-gray-2;
    box-sizing: border-box;
    z-index: $zindex-l-nav;

    &__list {
        position: relative;
        min-height: calc(100vh - 136px);
        margin-top: 20px;
        margin-bottom: 0;

        &--sub {
            min-height: auto;
            margin-top: 0;
        }
    }

    &__item {
        margin-top: 10px;
    }

    &__link {
        display: block;
        padding: 11px 20px;
        line-height: 0;

        .c-icon {
            @include font-size(16);
            color: $color-onamae-icon-gray;
            vertical-align: top;
        }

        &:focus,
        &:hover,
        &:active,
        &.is-active {
            text-decoration: none;
            outline: none;
        }

        &:focus,
        &:hover {
            background-color: #f4f4f4;

            .l-nav__text,
            .c-icon {
                color: $color-onamae-red;
            }
        }

        &:active {
            background: #E9E9E9;

            .c-icon,
            .l-nav__text {
                color: $color-onamae-red;
            }
        }

        &.is-active {
            background-color: $color-normal-gray;
            outline: none;

            .l-nav__text {
                color: $color-onamae-red;
                font-weight: 700;
            }

            .c-icon {
                color: $color-onamae-red;

                &.c-icon-home {
                    &:before {
                        content: "\F002";
                    }
                }

                &.c-icon-domain {
                    &:before {
                        content: "\F00C";
                    }
                }

                &.c-icon-homepage {
                    &:before {
                        content: "\F004";
                    }
                }

                &.c-icon-homepagesecurity {
                    &:before {
                        content: "\F008";
                    }
                }

                &.c-icon-mail {
                    &:before {
                        content: "\F00E";
                    }
                }

                &.c-icon-mailsecurity {
                    &:before {
                        content: "\F010";
                    }
                }

                &.c-icon-upload {
                    &:before {
                        content: "\F012";
                    }
                }

                &.c-icon-analytics {
                    &:before {
                        content: "\F017";
                    }
                }

                &.c-icon-homepage-plus {
                    &:before {
                        content: "\F006";
                    }
                }

                &.c-icon-tool {
                    &:before {
                        content: "\F00A";
                    }
                }

                &.c-icon-guid {
                    &:before {
                        content: "\F014";
                    }
                }

                &.c-icon-database {
                    &:before {
                        content: "\F016";
                    }
                }

                &.c-icon-server {
                    &:before {
                        content: "\F02F";
                    }
                }
            }
        }

        &.is-disabled {

            .c-icon,
            .l-nav__text {
                color: #b3b3b3;
            }

            &:hover {
                background-color: #fff;

                .l-nav__text {
                    font-weight: normal;
                }
            }
        }
    }

    &__text {
        display: inline-block;
        margin: 0 0 0 1rem;
        @include font-size(14);
        color: $color-onamae-gray-dark;
        vertical-align: top;

        &:first-line {
            line-height: 1.3;
        }

        line-height: 1;
    }

    @media screen and (max-height: 550px) {
        max-height: 550px;
        overflow-y: scroll;

        .l-nav__link {
            padding-right: 0;
        }
    }
}

.l-nav-group {
    position: relative;
    padding-bottom: 8px;

    &__title {
        position: relative;
        display: block;
        padding: 6px 20px 8px;
        font-size: 12px;
        color: #666;

        &Text {
            position: relative;
            background: #fff;
            padding-right: 10px;
            z-index: 2;
        }

        &::after {
            position: absolute;
            top: 14px;
            content: "";
            display: block;
            border-top: solid 1px #d8d8d8;
            width: 174px;
            height: 1px;
            z-index: 1;
        }
    }

    // &::before {
    //     position: absolute;
    //     left: 20px;
    //     content: "";
    //     display: block;
    //     border-top: solid 1px #d8d8d8;
    //     width: 174px;
    //     height: 1px;
    //     z-index: 1;
    // }
    &::after {
        position: absolute;
        bottom: 0;
        left: 20px;
        content: "";
        display: block;
        border-top: solid 1px #d8d8d8;
        width: 174px;
        height: 1px;
        z-index: 1;
    }

    &__link {
        display: block;
        padding: 11px 0px 11px 20px;
        line-height: 0;

        .c-icon {
            @include font-size(16);
            color: $color-onamae-icon-gray;
            vertical-align: top;
        }

        &:focus,
        &:hover,
        &:active,
        &.is-active {
            text-decoration: none;
            outline: none;
        }

        &:focus,
        &:hover {
            background-color: #f4f4f4;

            .l-nav__text,
            .c-icon {
                color: $color-onamae-red;
            }
        }

        &:active {
            background: #E9E9E9;

            .c-icon,
            .l-nav__text {
                color: $color-onamae-red;
            }
        }

        &.is-active {
            background-color: $color-normal-gray;
            outline: none;

            .l-nav__text {
                color: $color-onamae-red;
                font-weight: 700;
            }

            .c-icon {
                color: $color-onamae-red;

                &.c-icon-home {
                    &:before {
                        content: "\F002";
                    }
                }

                &.c-icon-domain {
                    &:before {
                        content: "\F00C";
                    }
                }

                &.c-icon-homepage {
                    &:before {
                        content: "\F004";
                    }
                }

                &.c-icon-homepagesecurity {
                    &:before {
                        content: "\F008";
                    }
                }

                &.c-icon-mail {
                    &:before {
                        content: "\F00E";
                    }
                }

                &.c-icon-mailsecurity {
                    &:before {
                        content: "\F010";
                    }
                }

                &.c-icon-upload {
                    &:before {
                        content: "\F012";
                    }
                }

                &.c-icon-analytics {
                    &:before {
                        content: "\F017";
                    }
                }

                &.c-icon-homepage-plus {
                    &:before {
                        content: "\F006";
                    }
                }

                &.c-icon-tool {
                    &:before {
                        content: "\F00A";
                    }
                }

                &.c-icon-guid {
                    &:before {
                        content: "\F014";
                    }
                }

                &.c-icon-database {
                    &:before {
                        content: "\F016";
                    }
                }

                &.c-icon-server {
                    &:before {
                        content: "\F02F";
                    }
                }
            }
        }

        &.is-disabled {

            .c-icon,
            .l-nav__text {
                color: #b3b3b3;
            }

            &:hover {
                background-color: #fff;

                .l-nav__text {
                    font-weight: normal;
                }
            }
        }
    }

    &__text {
        display: inline-block;
        margin: 0 0 0 1rem;
        @include font-size(14);
        color: $color-onamae-gray-dark;
        vertical-align: top;

        &:first-line {
            line-height: 1.3;
        }

        line-height: 1;
    }

    @media screen and (max-height: 550px) {
        max-height: 550px;

        // overflow-y: scroll;
        .l-nav__link {
            padding-right: 0;
        }
    }
}