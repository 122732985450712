@charset 'utf-8';
// =====================
// account
// =====================
.p-account {
    .c-container {
        .c-progressTracker {
            @include rem-change(width, 820px);
            margin: 0 auto;
        }
        &__table {
            @include rem-change(margin-top, 20px);
            &.is-hide {
                display: none;
            }
        }
        .c-panel {
            &-list {
                justify-content: center;
                @include rem-change(margin, 27.5px 0 20px);
                &__item {
                    @include rem-change(margin, 0 8px);
                }
            }
            &-label {
                cursor: pointer;
                outline: none;
                &:hover,
                &:active {
                    text-decoration: none;
                }
                .c-form-group {
                    &--radio {
                        @include rem-change(margin-top, 8px);
                        .c-form-label__text {
                            @include font-size(18);
                            color: #666;
                        }
                    }
                }
            }
            position: relative;
            @include rem-change(width, 218px);
            @include rem-change(height, 206px);
            @include rem-change(padding, 24px 24px);
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1),
            0 1px 4px 0 rgba(12, 12, 13, 0.1);
            box-shadow: 0 2px 4px 0 #DADADA;
            text-align: left;
            &__title {
                position: relative;
                @include rem-change(margin-top, 12px);
                @include rem-change(padding-left, 24px);
                @include font-size(18);
                line-height: 1.1;
                color: #333;
            }
            &__subTitle {
                @include font-size(13);
                color: #9B9B9B;
            }
            &__text {
                @include rem-change(margin-top, 32px);
                @include font-size(15);
                color: #666;
            }
            .c-tooltip-icon {
                position: absolute;
                top: 12px;
                right: 12px;
                @include rem-change(width, 20px);
                @include rem-change(height, 20px);
                margin-right: 0;
                text-align: center;
                @include rem-change(line-height, 20px);
            }
            .c-form__checkbox:before {
                box-shadow: none;
            }
            &:hover {
                background-color: #fff;
                border: 1px solid #4485F4;
                cursor: pointer;
                a {
                    text-decoration: none;
                }
            }
            &:focus {
                background-color: #F8F8F8;
                cursor: pointer;
            }
            &:active {
                background: #E9E9E9;
            }
            &.is-active {
                border: 1px solid #1F6DF4;
                box-shadow: 0 0 4px 0 #4485F4;
            }
            &.is-disabled {
                border: 1px solid #d8d8d8;
                .c-panel__text-link {
                    color: #b3b3b3;
                    &:after {
                        border-top: 1px solid #b3b3b3;
                        border-right: 1px solid #b3b3b3;
                    }
                }
                img {
                    -webkit-filter: grayscale(80%);
                    -moz-filter: grayscale(80%);
                    -o-filter: grayscale(80%);
                    -ms-filter: grayscale(80%);
                    filter: grayscale(80%);
                    opacity: 0.3;
                }
            }
        }
        .c-text-link {
            @include rem-change(margin-top, 36px);
            @include font-size(15);
        }
        // .c-button-list {
        //     @include rem-change(margin-top, 36px);
        // }
    }
}