.l-header {
	position: fixed;
	width: 100vw;
	height: 48px;
	max-height: 48px;
	// @include rem-change(height, 48px);
	// @include rem-change(max-height, 48px);
	z-index: $zindex-header;
	@include clearfix;
	background-color: $color-onamae-gray-dark;
	&__inner {
		position: relative;
		height: 48px;
		// @include rem-change(height, 48px);
	}
	&__logo {
		position: absolute;
		top: 0;
		width:220px;
		// @include rem-change(width, 220px);
		margin: 0;
		padding: 0;
		background: $color-onamae-red;
		h1 {
			margin: 0;
			padding: 0;
			vertical-align: top;
		}
		a {
			display: block;
		}
		img {
			width: 220px;
			height: 48px;
			vertical-align: top;
		}
	}
	&__bar {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		    -ms-flex-align: center;
		        align-items: center;
		width: auto;
		width: calc(100% - 220px);
		min-width: 690px;
		height: 48px;
		margin: 0 0 0 220px;
		color: #fff;
	}
	&__heading {
		display: block;
		margin-left: 20px;
		@include font-size(15);
		font-weight: 400;
		line-height: 1;
	}
	&__function {
		margin-right: 37px;
		// @include rem-change(margin-right, 37px);
		@supports (-ms-ime-align:auto) {
			margin-right: 32px;
		}
		&-list {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			&Item{
				// margin-left: 32px;
				&:first-child {
					margin-left: 0;
				}
				.c-button-text__icon {
					color: #ccc;
					&.c-icon-arrow-down {
						@include font-size(10);
					}
				}
				.c-button-text__text {
					line-height: 1;
					color: #ccc;
					@include font-size(14);
				}
				.c-button-text {
					padding: 13px;
					&:hover {
						background: #666;
						text-decoration: none;
					}
				}
			}
		}
		.c-dropdown {
			// @include rem-change(margin-left, 10px);
			margin-left: 10px;
		}
	}
	&__text {
		@include font-size(14);
		color: #ccc;
		.c-icon {
			margin-left: 4px;
			// @include rem-change(margin-left, 4px);
			@include font-size(10);
			&:before {
				vertical-align: -.125em;
			}
		}
	}
}

.l-header__function-listItem .c-button-text {
    padding: 13px;
}

_::-webkit-full-page-media, _:future, :root .l-header__function-listItem .c-button-text .c-button-text__text {
	line-height: 25px;
}
_::-webkit-full-page-media, _:future, :root .l-header__function-listItem .c-button-text .c-button-text__icon.c-icon.c-icon-arrow-down {
	line-height: 25px;
}

@media (max-width: 920px){
	.l-header__heading {
		// display: none;
		margin-left: 0;
	}
}
@media (max-width: 900px){
	.l-header__heading {
		display: none;
	}
}
