// button
// $local変数
$local-button-primary: #4485F4;
$local-button-primary-border-color: #1F6DF4;
$local-button-secondary: #F6F6F6;
$local-button-secondary-border-color: #D6D6D6;
$local-button-size-medium: #D6D6D6;
$local-button-size-large: #D6D6D6;
$local-button-size-small: #D6D6D6;

// @extend
// ベース
%c-button {
    @include rem-change(padding, 10px 15px);
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    line-height: 1;
}

%c-button-primary {
    background-color: $local-button-primary;
    color: #fff;

    &:hover,
    &:focus {
        background-color: #0065BD;
        @include transition-hover-button();
    }

    &:active {
        background-color: #005AA6;
    }
}

%c-button-size-medium {
    padding: 1.3rem 2.7rem;
    @include font-size(13);
    border-radius: 4px;
}

.c-button {
    @extend %c-button;
    @extend %c-button-primary;
    @extend %c-button-size-medium;
    outline: none;

    &--primary {
        @extend %c-button-primary;
    }

    &--secondary {
        background-color: $local-button-secondary;
        border: 1px solid #D6D6D6;
        color: #333;

        &:hover,
        &:focus {
            background-color: #E9E9E9;
            @include transition-hover-button();
        }

        &:active {
            background-color: #D8D8D8;
        }
    }

    &--danger {
        background: #D0021B;
        border: 1px solid #AF0000;
        color: #fff;

        &:hover,
        &:active {
            background-color: #D0021B;
            border: 1px solid #AF0000;
        }

        &:hover {
            @include transition-hover-button();
        }
    }

    &--w100,
    &--w160,
    &--w190,
    &--w200 {
        @include rem-change(padding, 11.5px 0);
    }

    &--w100 {
        @include rem-change(width, 100px);
    }

    &--w160 {
        @include rem-change(width, 160px);
        @include font-size(15);
    }

    &--w190 {
        @include rem-change(width, 190px);
        @include font-size(15);
    }

    &--w200 {
        @include rem-change(width, 200px);
        @include font-size(18);
    }

    &--xsmall {
        padding: 7px 11px;
    }

    &--small {
        @include rem-change(padding, 8.5px 18px);
    }

    &--medium {
        @include rem-change(padding, 11.5px 16.5px);
        @include font-size(15);
    }

    &--large {
        @include rem-change(padding, 15px 27px);
        @include font-size(18);
    }

    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    &.is-disabled {
        background-color: #D8D8D8;

        span,
        a {
            color: #fff;
        }
    }

    .c-icon {
        margin-top: -1px;

        // margin-right: 4px;
        &-plus {
            margin-right: 6px;
            @include font-size(12);
        }
    }

    &--withIcon {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;

        .c-button__icon {
            margin-right: 10px;
            line-height: 0;
        }

        &.c-button--small {
            padding: 8px 12px;

            .c-button__icon {
                font-size: 12px;
            }
        }
    }

    &.is-show {
        display: block !important;
    }

    &.is-hidden {
        display: none !important;
    }
}

.c-button-text {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #0065bd;

    &:visited {
        color: #5703AE;
        border-color: #5703AE;
    }

    &:hover {
        color: #4485F4;
        text-decoration: underline;
    }

    &:active {
        color: #0065BD;
        text-decoration: underline;
    }

    &__icon {
        &.c-icon-reload {
            margin-right: 6px;
        }

        &:before {
            margin-right: 0 !important;
        }
    }

    &__text {
        line-height: 1;

        +.c-icon-arrow-down {
            margin-left: 4px;
        }
    }

    span {
        margin-left: 8px;

        &:last-child {
            margin-right: 0;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

// .c-button-add {
//     @include font-size(15);
//     .c-icon-plus::before {
//         vertical-align: baseline;
//     }
// }

.c-button-list {
    @include rem-change(margin, 0 -4px);

    &.is-right {
        text-align: right;
    }

    &.is-center {
        text-align: center;
    }

    &.is-left {
        text-align: left;
    }

    &__item {
        display: inline-block;
        @include rem-change(margin, 0 4px);
    }

    &--vertical {
        .c-button-list__item {
            display: block;
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.c-button-reset {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

a.c-button {
    &:link {
        text-decoration: none !important;
    }

    &:visited {
        color: #fff !important;
    }

    &:hover,
    &:active {
        text-decoration: none !important;
    }

    &:hover {
        color: #fff !important;
    }

    &:active {
        color: #fff !important;
    }
}