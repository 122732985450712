.c-link-icon-external {
	position: relative;
	display: -ms-inline-flexbox;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: inline-flex;
	text-decoration: none;
	margin-right: 20px;
	&:after {
		position: absolute;
		right: -20px;
		top: 50%;
		content: "\F027";
		margin: 0 4px;
		margin: 0 0.4rem;
		margin-top: -7px;
		font-size: 12px;
		font-size: 1.2rem;
		color: #B3B3B3;
		vertical-align: -.08em;
	}

	&:after {
		font-family: "iconfont";
		font-style: normal;
		font-weight: 400;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		speak: none;
	}
}