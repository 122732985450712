// list

.c-list {
    &__item {
        margin: 4px 0;
    }
}
.c-list-selected {
    
}
.c-list-horizontal {
    display: flex;
  justify-content: center;
  align-items: center;

    &__item {
        position: relative;
        padding: 0 12px;
        &:last-child {
            &::before {
                position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background: #D8D8D8;
            }   
        }
    }
}