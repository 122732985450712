.c-section {
    @include clearfix();
    &__inner {
        width: 900px;
        max-width: 900px;
        margin: 32px auto 0;
        @include clearfix();
        &--wide {

        }
        &--narrow {

        }
    }
    .c-heading-1st {
        // line-height: 1;
        // margin-bottom:16px;
        // @include first-line-Initialization();
    }
    &-secondLayer {
        margin-top: 60px;
        @include clearfix();
    }
    &-thirdLayer {
        
    }
    &-fourthLayer {
        
    }
}