@charset 'utf-8';
// =====================
// login
// =====================
.p-webtool {
    .l-main {
        width: auto;
        height: auto;
        margin: auto;
        padding-top: 0;
    }
    .l-footer {
        width: 100%;
        min-width: 680px;
        margin: 0;
    }
    &-container {
        width: 562px;
        min-height: auto;
        margin: 80px auto 0;
        background: #FFF;
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        &__header {
            height: 48px;
            background-color: #333;
            .c-logo {
                line-height: 1;
            }
        }
        &__body {
            padding: 0 46px 40px;
        }
        .c-heading-2nd {
            font-weight: 700;
            color: #666;
            line-height: 1;
        }
        .c-table {
            th,
            td {
                border: none;
            }
            th {
                padding-right: 24px;
                padding-left: 0;
                font-weight: 400;
                text-align: right;
            }
            td {
                padding-right: 0;
            }
        }
        .c-button-list.c-button-list--vertical {
            margin-top: 28px;
        }
    }
    .c-modal {
        &--vertical{
            .c-modal__contents {
                width: auto;
            }
        }
    }
    .g-recaptcha {
        width: 304px;
        margin: 20px auto 0;
    }
}

.p-webtool-icon {
    padding-top: 32px;
    font-size: 48px;
    color: #6B6B6B;
    line-height: 0;
}