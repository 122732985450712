@font-face {
	font-family: "iconfont";
	src: url('/Content/Font/iconfont.eot');
	src: url('/Content/Font/iconfont.eot?#iefix') format('eot'),
	  url('/Content/Font/iconfont.woff2') format('woff2'),
	  url('/Content/Font/iconfont.woff') format('woff'),
	  url('/Content/Font/iconfont.ttf') format('truetype'),
	  url('/Content/Font/iconfont.svg#iconfont') format('svg');
	font-weight: normal;
	font-style: normal;
  }
  
  .c-icon:before {
	display: inline-block;
	font-family: "iconfont";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	speak: none;
	vertical-align: middle;
  }
  
  .c-icon-lg {
	font-size: 1.3333333333333333em;
	line-height: 0.75em;
	vertical-align: -15%;
  }
  .c-icon-2x { font-size: 2em; }
  .c-icon-3x { font-size: 3em; }
  .c-icon-4x { font-size: 4em; }
  .c-icon-5x { font-size: 5em; }
  .c-icon-fw {
	width: 1.2857142857142858em;
	text-align: center;
  }
  
  .c-icon-home:before { content: "\F001" }
	.c-icon-home-fill:before { content: "\F002" }
	.c-icon-homepage:before { content: "\F003" }
	.c-icon-homepage-fill:before { content: "\F004" }
	.c-icon-homepage-plus:before { content: "\F005" }
	.c-icon-homepage-plus-fill:before { content: "\F006" }
	.c-icon-homepagesecurity:before { content: "\F007" }
	.c-icon-homepagesecurity-fill:before { content: "\F008" }
	.c-icon-tool:before { content: "\F009" }
	.c-icon-tool-fill:before { content: "\F00A" }
	.c-icon-domain:before { content: "\F00B" }
	.c-icon-domain-fill:before { content: "\F00C" }
	.c-icon-mail:before { content: "\F00D" }
	.c-icon-mail-fill:before { content: "\F00E" }
	.c-icon-mailsecurity:before { content: "\F00F" }
	.c-icon-mailsecurity-fill:before { content: "\F010" }
	.c-icon-upload:before { content: "\F011" }
	.c-icon-upload-fill:before { content: "\F012" }
	.c-icon-guid:before { content: "\F013" }
	.c-icon-guid-fill:before { content: "\F014" }
	.c-icon-database:before { content: "\F015" }
	.c-icon-database-fill:before { content: "\F016" }
	.c-icon-analytics:before { content: "\F017" }
	.c-icon-lock:before { content: "\F018" }
	.c-icon-arrow-left-small:before { content: "\F019" }
	.c-icon-arrow-right-small:before { content: "\F01A" }
	.c-icon-arrow-down-small:before { content: "\F01B" }
	.c-icon-arrow-up-small:before { content: "\F01C" }
	.c-icon-arrow-left:before { content: "\F01D" }
	.c-icon-arrow-right:before { content: "\F01E" }
	.c-icon-arrow-down:before { content: "\F01F" }
	.c-icon-arrow-up:before { content: "\F020" }
	.c-icon-close:before { content: "\F021" }
	.c-icon-plus:before { content: "\F022" }
	.c-icon-reload:before { content: "\F023" }
	.c-icon-check:before { content: "\F024" }
	.c-icon-edit:before { content: "\F025" }
	.c-icon-delete:before { content: "\F026" }
	.c-icon-externallink:before { content: "\F027" }
	.c-icon-logout:before { content: "\F028" }
	.c-icon-eye:before { content: "\F029" }
	.c-icon-eye-off:before { content: "\F02A" }
	.c-icon-search:before { content: "\F02B" }
	.c-icon-file:before { content: "\F02C" }
	.c-icon-wordpress:before { content: "\F02D" }
	.c-icon-server:before { content: "\F02E" }
	.c-icon-server-fill:before { content: "\F02F" }
	.c-icon-wordpresssecurity:before { content: "\F030" }
	.c-icon-autobackup:before { content: "\F031" }
	.c-icon-contentscache:before { content: "\F032" }
	.c-icon-databaseuser:before { content: "\F033" }
	.c-icon-directorysecurity:before { content: "\F034" }
	.c-icon-errorpage:before { content: "\F035" }
	.c-icon-filemanager:before { content: "\F036" }
	.c-icon-htaccess:before { content: "\F037" }
	.c-icon-ipsecurity:before { content: "\F038" }
	.c-icon-mailinglist:before { content: "\F039" }
	.c-icon-php:before { content: "\F03A" }
	.c-icon-phpadmin:before { content: "\F03B" }
	.c-icon-serverchange:before { content: "\F03C" }
	.c-icon-sitetransfer:before { content: "\F03D" }
	.c-icon-ssh:before { content: "\F03E" }
	.c-icon-ssl:before { content: "\F03F" }
	.c-icon-testoperation:before { content: "\F040" }
	.c-icon-waf:before { content: "\F041" }
	.c-icon-webfont:before { content: "\F042" }
	.c-icon-webmail:before { content: "\F043" }
	.c-icon-error:before { content: "\F044" }
	.c-icon-warning:before { content: "\F045" }
	.c-icon-accessrights:before { content: "\F046" }
	.c-icon-calender:before { content: "\F047" }
	.c-icon-change:before { content: "\F048" }
	.c-icon-exchangeonline:before { content: "\F049" }
	.c-icon-smartphone:before { content: "\F04A" }
	.c-icon-tablet:before { content: "\F04B" }
	.c-icon-weebly:before { content: "\F04C" }
	.c-icon-account:before { content: "\F04D" }
	.c-icon-help:before { content: "\F04E" }
	.c-icon-manulist:before { content: "\F04F" }
	.c-icon-mailinglist-manage:before { content: "\F050" }
	.c-icon-homepageediter:before { content: "\F051" }
	
	

	.c-icon {
		display: inline-block;
		vertical-align: middle;
		&-count-num {
			display: inline-block;
			background: #cc0000;
			min-width: 22px;
			padding: 1px 2px;
			box-sizing: border-box;
			@include font-size(12);
			text-align: center;
			color: #fff;
			border: solid 1px #cf0f0f;
			&--circle {
				-moz-border-radius: 50px;
				-webkit-border-radius: 50px;
				-o-border-radius: 50px;
				-ms-border-radius: 50px;
				border-radius: 50px;
			}
		}
		&-question.c-icon-question--circle {
			display: inline-block;
			@include font-size(13);
			color: #b3b3b3;
			background: #eeeeee;
			border-radius: 50px;
			width: 16px;
			height: 16px;
			text-align: center;
			line-height: 16px;
			margin-right: 4px;
		}
		&-square {
			display: inline-block;
			width: 12px;
			height: 12px;
			background: #d8d8d8;
			vertical-align: middle;
		}
	}