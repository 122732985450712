.l-footer {
    width: calc(100% - 220px);
    min-width: 900px;
    margin: 0 0 0 220px;
    padding: 12px 0;
    background-color: #f4f5f8;
    border-top: solid thin #D8D8D8;
    &footer__inner {
        
    }
    &__text {
        @include font-size(13);
        text-align: center;
        line-height: 1;
        color: #666;
    }
}