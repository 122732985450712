@charset 'utf-8';
.l-main {
	height: 100%;
	padding: 48px 0 120px;
	background-color: #f4f5f8;
	&__inner {
		height: auto;
		// margin-left: 2rem;
		@include rem-change(padding, 0 2rem);
		// @include rem-change(max-width, 28px 30px);
	}
	display: table;
	width: auto;
	width: calc(100% - 220px);
	height: 100%;
	margin: 0 0 0 220px;
}