// #VARIABLES--local
// color
$local-c-panel-padding: 8px !default;
$local-c-panel-item-width: 138px !default;
$local-c-panel-item-height: 140px !default;
$local-c-panel-font-size: 15 !default;
.c-panel {
	&-list {
		display: flex;
		flex-wrap: wrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin-left: auto;
		margin-right: auto;
		will-change: auto;
	}
	width: $local-c-panel-item-width;
	height: $local-c-panel-item-height;
	padding: $local-c-panel-padding;
	// @include rem-change(padding, $local-c-panel-padding);
	background-color: #fff;
	border: solid 1px $color-onamae-border-default;
	border-radius: 4px;
	box-sizing: border-box;
	text-align: center;
	&:hover,
	&:focus {
		background-color: #F8F8F8;
		cursor: pointer;
	}
	&:active {
		background: #E9E9E9;
	}
	&.is-active {
		border: 1px solid #1F6DF4;
	}
	&.is-disabled {
		border: 1px solid #d8d8d8;
		.c-panel__text-link {
			color: #b3b3b3;
			&:after {
				border-top: 1px solid #b3b3b3;
				border-right: 1px solid #b3b3b3;
			}
		}
		.c-panel-link & {
			cursor: auto;
		    pointer-events: none;
		}
		img {
			-webkit-filter: grayscale(80%);
			-moz-filter: grayscale(80%);
			-o-filter: grayscale(80%);
			-ms-filter: grayscale(80%);
			filter: grayscale(80%);
			opacity: 0.3;
		}
	}
	&__link {
		display: block;
		color: $color-onamae-text-blue;
		&:link,
		&:visited,
		&:hover,
		&:active {
			color: #0065bd;
			text-decoration: none;
		}
	}
	&__icon {
		display: block;
	}
	&__text {
		display: block;
		@include font-size($local-c-panel-font-size);
		color: #333;
		&-link {
			display: block;
			margin-top:10px;
			// @include rem-change(margin-top, 10px);
			@include font-size($local-c-panel-font-size);
			position: relative;
			margin-bottom: 0;
			padding: 0;
			&:after {
				content: "";
				display: inline-block;
				width: 4px;
				height: 4px;
				margin-left: 4px;
				border-top: 1px solid #0065bd;
				border-right: 1px solid #0065bd;
				transform: rotate(45deg);
				vertical-align: middle;
				speak: none;
				transition: all 0.2s ease;
				&:visited {
					color: #193C81;
				}
				&:hover {
					color: #4485F4;
					text-decoration: underline;
				}
				&:active {
					color: #0065BD;
					text-decoration: underline;
				}
			}
		}
	}
	&__label {
		display: block;
		padding-left: 24px;
		position: relative;
	}
	&-link {
		&:link,
		&:visited,
		&:hover,
		&:active {
			color: #0065bd;
			text-decoration: none;
		}
		&.is-disabled {
			.c-panel__text-link {
				color: #b3b3b3;
				&:after {
					border-top: 1px solid #b3b3b3;
					border-right: 1px solid #b3b3b3;
				}
			}
			img {
				-webkit-filter: grayscale(80%);
				-moz-filter: grayscale(80%);
				-o-filter: grayscale(80%);
				-ms-filter: grayscale(80%);
				filter: grayscale(80%);
				opacity: 0.3;
			}
		}
	}
	&-label {
		cursor: pointer;
		outline: none;
		&:hover,
		&:active {
			text-decoration: none;
		}
		&:active {
		}
		.c-form {
			&-group {
				// @include rem-change(margin-top, 8px);
				margin-top:8px;
			}
			&-radio,
			&-checkbox
			{
				&__text {
					@include font-size(18);
					vertical-align: middle;
					color: #666;
				}
				&__button {
					vertical-align: text-top;
				}
			}
		}
	}
}