@charset 'utf-8';

// =====================
// article
// =====================
.p-analysis {
    &-container {
        &-list {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin: 32px -12px 0;

            &__item {
                margin: 0 12px;

                &:nth-child(1) {
                    width: 550px;
                }

                &:nth-child(2) {
                    width: 326px;
                }

                &:nth-child(3) {
                    width: 550px;
                }

                &:nth-child(4) {
                    width: 326px;
                }

                &:nth-child(5) {
                    width: 438px;
                }

                &:nth-child(6) {
                    width: 438px;
                }

                &:nth-child(7) {
                    width: 900px;
                }

                &:nth-child(n + 3) {
                    margin-top: 60px;
                }
            }
        }

        &__header {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -webkit-box-align: baseline;
            -webkit-align-items: baseline;
            -ms-flex-align: baseline;
            align-items: baseline;
        }

        &__body {
            &--white {
                background: #fff;
            }

            &Inner {
                padding: 24px;
                background: #fff;
                border-right: solid thin #D8D8D8;
                border-bottom: solid thin #D8D8D8;
                border-left: solid thin #D8D8D8;
            }
        }
        .c-card-list {
            margin: 0 -0.4rem;
    
            &__item {
                margin: 0 .4rem;
    
                .c-card {
                    width: 178px;
                    padding: 22px 20px 12px;
                    background: #fff;
    
                    .c-form-radio__text {
                        font-size: 15px;
                    }
                }
            }
        }
    
        .c-card-button {
            .p-analysis-cardButton-change {
                position: relative;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                width: 100%;
    
                &__title {
                    position: relative;
                    // margin-left: 12px;
                    padding-right: 24px;
                    line-height: 1;
                    color: #333;
    
                    &::after {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        content: "";
                        display: block;
                        width: 1px;
                        height: 32px;
                        margin-top: -17px;
                        background: #D8D8D8;
                    }
                }
    
                &__text {
                    margin-left: 24px;
                    @include font-size(18);
                    line-height: 1;
                    color: #0065BD;
                }
    
                &__icon {
                    margin-right: 12px;
                    @include font-size(20);
                    line-height: 0;
                    color: #6B6B6B;
                }
    
                .c-icon {
    
                    &-domain,
                    &-mail {
                        @include font-size(20);
                        line-height: 0;
                        color: #6B6B6B;
                    }
                }
    
                &__action {
                    margin-left: auto;
                    text-align: right;
                    color: #0065BD;
    
                    .c-icon {
                        line-height: 1;
    
                        &:before {
                            margin-right: 8px;
                            margin-right: 0.8rem;
                            font-size: 16px;
                            font-size: 1.6rem;
                            vertical-align: 0;
                        }
                    }
                }
            }
        }
    }

    &-heading-2nd {
        position: relative;
        margin-top: 12px;
        border-bottom: 2px solid #ccc;
        @include font-size(24);

        &__inner {
            position: relative;
            display: inline-block;

            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: -2px;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: #CC0000;
            }
        }
    }

    &-accessStatus {
        margin-top: 16px !important;

        &__content {
            display: none;
            padding: 24px;
            background-color: #fff !important;
            border: 1px solid #D8D8D8;

            &.is-active {
                display: block;
            }
        }
    }

    &-carousel {
        margin-top: 20px;

        &-main {
            position: relative;
            display: block;
            overflow: hidden;
            float: right;
            width: 600px;
        }

        &-button-next {
            position: absolute;
            top: 50%;
            right: 15px;
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            z-index: 1;
            font-size: 32px;
            color: #B3B3B3;
            @include buttonReset;
        }

        &-button-prev {
            position: absolute;
            top: 50%;
            left: 15px;
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            z-index: 1;
            font-size: 32px;
            color: #B3B3B3;
            @include buttonReset;
        }


        &-list {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            width: 2058px;
            margin-left: auto;
            margin-right: auto;
            -webkit-transform: translate3d(-378px, 0px, 0px);
            transform: translate3d(-378px, 0px, 0px);

            &Item {
                float: left;
                margin: 0 8px;
            }
        }

        &-content {
            width: 434px;
            padding: 24px;
            background: #fff;
            border-top: 2px solid #D8D8D8;
            border-right: 1px solid #D8D8D8;
            border-bottom: 1px solid #D8D8D8;
            border-left: 1px solid #D8D8D8;
            opacity: .6;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;

            &.is-active {
                opacity: 1;
            }
        }

        &-title {
            margin-bottom: 16px;
            font-size: 13px;
            font-weight: bold;
            text-align: center;
            color: #333;
        }

        &-nav {
            float: left;
            width: 260px;

            &-list {
                &__item {
                    margin-top: 10px;

                    &:first-child {
                        margin-top: 0;
                    }

                    .c-card {
                        padding: 15px 24px;

                        .c-form-radio__text {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    &-accessDevice {
        margin-top: 8px;
        &__list {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            text-align: center;
        }

        &__item {
            -webkit-box-flex: 1;
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
        }

        &__icon {
            line-height: 0;
            font-size: 32px;
            color: #6B6B6B;
            .c-icon {
                line-height: 0;
            }
        }

        &__title {
            margin-top: 16px;
            font-size: 15px;
            line-height: 1;
            color: #333;
        }

        &__num {
            margin-top: 12px;
            font-size: 13px;
            line-height: 1;
            // color: #1F6DF4;
        }

        &__text {
            margin-top: 26px;
            font-size: 15px;
            line-height: 1;
            text-align: center;
            color: #333;
        }
    }
}