.c-badge {
    display: inline-block;
    @include rem-change(padding, 2.4px 4px 1.6px);
    @include font-size(12);
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #4485F4;
    color: white;
    border-radius: 2px;
    box-sizing: border-box;
    &--m {
        @include rem-change(padding, 4px 6.24px);
    }
    &--s {
        @include rem-change(padding, 2.4px 4px 1.6px);
        font-size: 12px;
    }
    &--l {
        @include rem-change(padding, 6.5px 13.5px);
    }
    &--primary {
        color: #fff;
        background-color: #4485F4;
    }
    &--secondary {
        color: #fff;
        background-color: #6c757d;
    }
    &--success {
        color: #fff;
        background-color: #28a745;
    }
    &--danger {
        color: #fff;
        background-color: #dc3545;
    }
    &--warning {
        color: #212529;
        background-color: #ffc107;
    }
    &--info {
        color: #fff;
        background-color: #17a2b8;
    }
    &--light {
        color: #212529;
        background-color: #f8f9fa;
    }
    &--dark {
        color: #fff;
        background-color: #343a40;
    }
    &--required {
        color: #fff;
        background-color: #D0021B;
    }
    &--important {
        color: #fff;
        background: $color-onamae-red;
    }
    &--other {
        color: #fff;
        background: $color-onamae-gray-3;
    }
    &--circle {
        border-radius: 1em;
    }
    &--num {
        padding: 0.3rem 0.529rem;
    }
    &.is-disabled {
        background: #D8D8D8;
        color: #fff;
    }
    &.is-hidden {
        display: none;
    }
}