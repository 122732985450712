@charset "UTF-8";
@-webkit-keyframes modalwindow_body_in {
	0% {
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
	}
	100% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
}
@keyframes modalwindow_body_in {
	0% {
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
	}
	100% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
}

@-webkit-keyframes modalwindow_body_out {
	0% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
	100% {
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
	}
}

@keyframes modalwindow_body_out {
	0% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
	100% {
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
	}
}

body.is-modal-enabled {
	overflow: hidden !important;
	.l-nav__link {
		&:focus:not(.is-active) {
            background-color: transparent;
            .l-nav__text,
            .c-icon {
                color: #333;
			}
			pointer-events: none;
		}
	}
}
body.is-modal-enabled .l-contents {
	padding-right: 17px;
}

@supports (-ms-ime-align:auto) {
    body.is-modal-enabled .l-contents {
		padding-right: 12px;
	}
}

.c-modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	// overflow: auto;
	overflow: scroll;
	z-index: $zindex-modal;
	width: 100%;
	height: calc(100% + 100px);
	background: rgba(0, 0, 0, 0.2);
	border-bottom: 100px solid transparent;
	color: #333;
	cursor: pointer;
	opacity: 1;
	cursor: default;
	&.is-lock .c-modal__button.c-modal__button--close {
		background: #ccc;
	}
	&.is-visible .c-modal__content {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
		-webkit-animation: 400ms modalwindow_body_in;
		        animation: 400ms modalwindow_body_in;
	}
	&__header {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		border-bottom: solid thin #D6D6D6;
	}
	&__close {
		position: absolute;
		top: 0;
		right: 0;
		// @include rem-change(padding, 16px 16px 0 6px);
		padding: 16px 16px 0 6px;
		@include font-size(18);
		color: #B3B3B3;
		cursor: pointer;
		.c-icon-close:before {
			vertical-align: top;
		}
	}
	&__heading {
		padding-top: 32px;
		padding-bottom: 16px;
		padding-left: 40px;
		@include font-size(24);
		font-weight: 700;
		color: #666;
		line-height: 1;
		&--withIcon {
			position: relative;
			padding-left: 64px;
			.c-icon {
				position: absolute;
				top: 50%;
				left: 35px;
				margin-top: -6px;
			}
		}
		.c-text-icon {
			.c-text-icon__icon {
				margin-right: 12px;
				font-size: 32px;
				&.c-icon.c-icon-exchangeonline {
					color: #0078D7;
				}
			}
			.c-text-icon__text {
				margin-top: 4px;
			}
		}
	}
	&__content {
		position: relative;
		width: 640px;
		// @include rem-change(width, 640px);
		margin-top: 95px;
		// @include rem-change(margin-top, 95px);
		margin-right: auto;
		margin-bottom: 300px;
		// @include rem-change(margin-bottom, 300px);
		margin-left: auto;
		background: #fff;
		border-radius: 4px;
		cursor: default;
		-webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
		        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
		-webkit-animation: 200ms modalwindow_body_out;
				animation: 200ms modalwindow_body_out;
		&--wide {
			width: 900px;
			.c-modal__body {
				width: 820px;
			}
			.c-modal__contents {
				width: 100%;
				margin: 0 auto;
			}
		}
	}
	&__body {
		width: 560px;
		// @include rem-change(width, 560px);
		margin: 0 auto;
		padding: 32px 20px 40px;
		// @include rem-change(padding, 32px 20px 40px);
		&--auto {
			min-height: auto;
		}
		.c-modal__description {
			margin-bottom: 12px;
			// @include rem-change(margin-bottom, 12px);
		}
		.c-block-searchResults {
			padding: 0 0 22px;
			.c-block-searchResults__icon {
				margin-top: 40px;
			}
		}
	}
	&__contents {
		width: 528px;
		// @include rem-change(width, 528px);
		margin: 0 auto;
		&--w540 {
			width: 540px;
		}
		.c-block-annotation {
			padding: 12px 12px 12px 12px;
			.c-text-list-disc {
				padding-left: 20px;
				&__item {
					&:first-child {
						margin-top: 0;
					}
					margin-top: 8px;
					@include font-size(13);
					color: #333;
					letter-spacing: 0;
				}
			}
		}
	}
	&__footer {
		padding: 14px;
		// @include rem-change(padding, 14px);
		background: #F6F6F6;
		border-radius: 0 0 4px 4px;
		border-top: 1px solid #e5e5e5;
	}
	&__note {
		margin-top: 12px;
		// @include rem-change(margin-top, 12px);
		margin-bottom: 0;
		@include font-size(15);
		color: #D0021B;
	}
	&--w_800 {
		.c-modal__content {
			width: 800px;
			// @include rem-change(width, 800px);
		}
		.c-modal__body {
			width: auto;
			padding: 32px;
			// padding: 3.2rem;
		}
		.c-modal__contents {
			width: auto;
			margin: 0 auto;
		}
	}
	&--vertical {
		.c-modal {
			&__header {
				border-bottom: none;
			}
			&__heading {
				width: 100%;
				padding-top: 40px;
				// @include rem-change(padding-top, 40px);
				padding-bottom: 0;
				padding-left: 0;
				text-align: center;
			}
			&__body {
				min-height: auto;
				padding: 40px 0;
				// @include rem-change(padding, 40px 0);
				&--narrow {
					padding-top: 24px;
					// @include rem-change(padding-top, 24px);
				}
			}
			&__description {
				margin-bottom: 28px;
				// @include rem-change(margin-bottom, 28px);
				text-align: center;
			}
			&__contents {
				margin: 0 auto;
				// @include rem-change(margin, 0 auto);
				width: auto;
				table {
					th {
						padding-right: 12px;
						// @include rem-change(padding-right, 12px);
						text-align: right;
						font-weight: normal;
					}
					td {
						padding-left: 12px;
						// @include rem-change(padding-left, 12px);
						&.is-unset {
							color: #B3B3B3;
						}
					}
					th,
					td {
						@include rem-change(max-width, 240px);
						@include rem-change(padding-top, 8px);
						@include rem-change(padding-bottom, 8px);
						vertical-align: top;
					}
				}
				.c-button-list {
					@include rem-change(margin-top, 32px);
					text-align: center;
				}
				.c-block-annotation {
					line-height: 1.5;
				}
			}
			&__footer {
				padding: 0;
				@include rem-change(padding-bottom, 40px);
				border: none;
				background: inherit;
				text-align: center;
				.c-text-link {
					@include rem-change(margin-top, 16px);
					@include font-size(15);
				}
			}
			&__text {
				@include font-size(16);
				text-align: center;
			}
		}
		&.c-modal--delete {
			.c-modal__body {
				padding-top: 32px;
			}
		}
	}
	.c-block-completion {
		padding: 0;
		padding-top: 40px;
		&__icon {
			@include font-size(60);
		}
		&__text {
			margin-top: 24px;
		}
	}
	.c-block-domainSelection {
		&__title {
			padding-right: 16px;
			&::after {
				height: 20px;
				margin-top: -10px;
			}
		}
		&__text {
			width: 430px;
			margin-left: 11px;
			color: #333;
		}
		.c-icon.c-icon-domain {
			color: #6B6B6B;
		}
		.c-icon.c-icon-mail {
			// vertical-align: 0;
			color: #6B6B6B;
		}
	}
	.c-block-borderBox {
		padding: 12px 16px;
		&-wrap {
			margin: 0 -10px;
			padding: 12px;
		}
	}
	.c-card {
		.c-text-icon {
			.c-text-icon__icon {
				&.c-icon.c-icon-plus {
					@include font-size(16);
					color: #B3B3B3;
				}
			}
			.c-text-icon__text {
				color: #333;
			}
		}
	}

}

.c-modal__content--sitemap {
	width: 960px;
	.c-modal__body {
		width: 960px;
		padding: 36px;
		padding-right: 0;
		padding-left: 0;
	}
	.c-modal__contents {
		width: 100%;
		margin: 0 auto;
	}
}
.c-modal-sitemap {
	&__inner {
		padding: 0 10px;
	}
	&-list {
		display: flex;
		justify-content: space-between;
		&__column {
			width: 25%;
			margin: 0 8.5px;
		}	
	}
	&-nav {
		position: relative;
		padding-bottom: 8px;
		&__title {
			position: relative;
			display: block;
			padding: 6px 20px 8px;
			font-size: 12px;
			color: #666;
			&Text {
				position: relative;
				background: #fff;
				padding-right: 4px;
				z-index: 2;
				cursor: auto !important;
				&.c-text-link {
					position: relative;
					color: #0065BD;			
					&:after {
						display: inline-block;
						content: "";
						width: 4px;
						height: 4px;
						margin-left: 4px;
						border-top: 1px solid #0065bd;
						border-right: 1px solid #0065bd;
						-moz-transform: translate(-55%, -50%) rotate(45deg);
						-ms-transform: translate(-55%, -50%) rotate(45deg);
						-webkit-transform: translate(-55%, -50%) rotate(45deg);
						transform: translate(-55%, -50%) rotate(45deg);
						speak: none;
						transition: all 0.2s ease;
					}
					&:visited {
						color: #5703AE;
						border-color: #5703AE;
					}
					&:hover {
						color: #4485F4;
						text-decoration: underline;
					}
					&:active {
						color: #0065BD;
						text-decoration: underline;
					}
				}
				&:hover {
					cursor: pointer;
				}
			}
			&::after {
				position: absolute;
				top: 14px;
				content: "";
				display: block;
				border-top: solid 1px #d8d8d8;
				width: 178px;
				height: 1px;
				z-index: 1;
			}
		}
		&::after {
			position: absolute;
			bottom: 0;
			left: 20px;
			content: "";
			display: block;
			width: 174px;
			height: 1px;
			z-index: 1;
		}
		&__link {
			display: block;
			padding: 11px 0px 11px 20px;
			line-height: 0;
			.c-icon {
				@include font-size(16);
				color: $color-onamae-icon-gray;
				vertical-align: middle;
				&.c-icon-wordpress {
					color: #21759B;
				}
				&.c-icon-exchangeonline {
					color: #0078D7;
				}
				&.c-icon-weebly {
					color: #1495D5;
				}
			}
			&:focus,
			&:hover,
			&:active,
			&.is-active
			 {
				text-decoration: none;
				outline: none;
			}
			&:focus,
			&:hover {
				background-color: #f4f4f4;
				.l-nav__text,
				.c-icon {
					color: $color-onamae-red;
					&.c-icon-exchangeonline {
						color: #0078D7;
					}
					&.c-icon-wordpress {
						color: #21759B;
					}
					&.c-icon-weebly {
						color: #1495D5;
					}
				}
			}
			&:active {
				background: #E9E9E9;
				.c-icon,
				.l-nav__text {
					color: $color-onamae-red;
				}
			}
			&.is-active {
				background-color: $color-normal-gray;
				outline: none;
				.l-nav__text {
					color: $color-onamae-red;
					font-weight: 700;
				}
				.c-icon {
					color: $color-onamae-red;
					&.c-icon-home {
						&:before {
							content: "\F002";
						}
					}
					&.c-icon-domain {
						&:before {
							content: "\F00C";
						}
					}
					&.c-icon-homepage {
						&:before {
							content: "\F004";
						}
					}
					&.c-icon-homepagesecurity {
						&:before {
							content: "\F008";
						}
					}
					&.c-icon-mail {
						&:before {
							content: "\F00E";
						}
					}
					&.c-icon-mailsecurity {
						&:before {
							content: "\F010";
						}
					}
					&.c-icon-upload {
						&:before {
							content: "\F012";
						}
					}
					&.c-icon-analytics {
						&:before {
							content: "\F017";
						}
					}
					&.c-icon-homepage-plus {
						&:before {
							content: "\F006";
						}
					}
					&.c-icon-tool {
						&:before {
							content: "\F00A";
						}
					}
					&.c-icon-guid {
						&:before {
							content: "\F014";
						}
					}
					&.c-icon-database {
						&:before {
							content: "\F016";
						}
					}
					&.c-icon-server {
						&:before {
							content: "\F02F";
						}
					}
				}
			}
			&.is-disabled {
				.c-icon,
				.l-nav__text {
					color: #b3b3b3;
				}
				&:hover {
					background-color: #fff;
					.l-nav__text {
						font-weight: normal;
					}
				}
			}
		}
		&__text {
			display: inline-block;
			margin: 0 0 0 1rem;
			@include font-size(14);
			color: $color-onamae-gray-dark;
			vertical-align: middle;
			&:first-line {
				line-height: 1.3;
			}
			line-height: 1;
		}
		@media screen and (max-height: 550px) {
			max-height: 550px;
			.l-nav__link {
				padding-right: 0;
			}
		 }
		 &.is-disabled {
			span {
				color: #666;
			}
			.c-modal-sitemap-nav__titleText.c-text-link {
				color: #96c0e4;
			}
			.c-modal-sitemap-nav__titleText.c-text-link:after {
				opacity: 0.5;
			}
			opacity: 0.5;
			// .c-icon {
			// 	opacity: 0.5;
			// }
			// .c-text-link:after {
			// 	opacity: 0.5;
			// }
			// .l-nav__text {
			// 	color: #a0a0a0;
			// }
			// .c-modal-sitemap-nav__titleText.c-text-link {
			// 	color: #96c0e4;
			// }
			// &:hover {
			// 	cursor: pointer;
			// }
		}
	}
}