@charset 'utf-8';
.p-error {
    .l-contents {
        min-height: auto;
    }
    .l-main {
        position: relative;
        background-color: #FFE7E7;
        width: 100%;
        height: calc(100vh - 86px);
        min-height: auto;
        margin: 0;
        padding-bottom: 0;
    }
    .c-section {
        @include rem-change(height, 740px);
        padding-bottom: 0;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(75%, #f4f5f8), color-stop(75%, #FFE7E7));
        background: linear-gradient(#f4f5f8 75%, #FFE7E7 75%);
    }
    .l-footer {
        width: 100%;
        // min-width: 900px;
        margin: 0;
        background-color: #FFE7E7;
    }
    .p-error-heading-1st,
    .c-text-size-xxl {
        letter-spacing: 4px;
    }
}