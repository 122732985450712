$color-onamae-gray-3:#666;
$color-onamae-gray-dark: #333;

.c-tooltip {
	position: absolute;
	display: block;
	left: auto;
	right: auto;
	top: auto;
	bottom: auto;
	text-align: center;
	z-index: $zindex-tooltip;
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
	opacity: 0;
	max-width: 200px;
	width: 200px;
	&--wide {
		max-width: 320px;
		width: 320px;
	}
	&--narrow {
		max-width: 100px;
		width: 100px;
	}
	&.is-show {
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
		opacity: 1;
	}
	&.is-top {
		margin-top: -15px;
		padding: 5px 0;
		.c-tooltip-arrow {
			bottom: 0;
			left: 50%;
			margin-left: -5px;
			border-width: 5px 5px 0;
			border-top-color: rgba(0, 0, 0, 0.80);
		}
	}
	&.is-right {
		margin-left: 5px;
		padding: 0 5px;
		.c-tooltip-arrow {
			top: 50%;
			left: 0;
			margin-top: -5px;
			border-width: 5px 5px 5px 0;
			border-right-color: rgba(0, 0, 0, 0.80);
		}
	}
	&.is-bottom {
		margin-top: 6px;
		padding: 5px 0;
		.c-tooltip-arrow {
			top: 0;
			left: 50%;
			margin-left: -5px;
			border-width: 0 5px 5px;
			border-bottom-color: rgba(0, 0, 0, 0.80);
		}
	}
	&.is-left {
		margin-left: -15px;
		padding: 0 5px;
		.c-tooltip-arrow {
			top: 50%;
			right: 0;
			margin-top: -5px;
			border-width: 5px 0 5px 5px;
			border-left-color: rgba(0, 0, 0, 0.80);
		}
	}
	&__inner {
		padding: 12px;
		background: rgba(0, 0, 0, 0.80);
		@include font-size(13);
		line-height: 1.6;
		text-align: left;
		color: #fff;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;
		border-radius: 8px;
	}
	&-arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
	&-icon-wrap {
		position: relative;
		display: inline-block;
		line-height: 1;
		.c-tooltip-icon {
			position: absolute;
			top: -2px;
			right: -24px;
		}
		&--hasButton {
			.c-tooltip-icon {
				right: -44px;
			}
		}
	}
	&-icon {
		cursor: pointer;
	}
}