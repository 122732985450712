// Clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

//   media-query
@mixin media-query($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin font-size($size, $base: 15) {
  font-size: $size + px;
  font-size: ($size / 10) + rem;
}

@mixin rem-change($property, $values, $rootSize:10px) {
  $rem: ();
  @each $value in $values {
    @if $value==0 or $value==auto {
      $rem: append($rem, $value);
    }
    @else {
      $unit: unit($value);
      $val: parseInt($value);
      @if $unit=="px" {
        $px: append($rem, ($val+ px));
        $rem: append($rem, ($val / parseInt($rootSize) + rem));
      }
    }
  }
  #{$property}: $values;
  #{$property}: $rem;
}

@mixin last-mb0() {
  > :last-child {
    margin-bottom: 0;
  }
}

@mixin first-line-Initialization() {
  &:first-line {
    line-height: 1;
  }
  E::first-line {
    line-height: 1;
  }
}

@mixin hover-opacity($val_cursor:pointer, $val_transitions:.2s, $val_opacity:.7) {
  -webkit-transition: $val_transitions;
  transition: $val_transitions;
  &:hover {
    cursor: $val_cursor;
    opacity: $val_opacity;
  }
}

@mixin text-link-action() {
  color: $color-onamae-text-blue;
  &:visited {
    color: #5703AE;
  }
  &:hover {
    color: #4485F4;
    text-decoration: underline;
  }
  &:active {
    color: #0065BD;
    text-decoration: underline;
  }
  &:disabled {
    color: #b3b3b3;
    ;
  }
}

@mixin transition($property, $values:.2s) {
  -webkit-transition: $property $values;
  transition: $property $values;
}

@mixin transition-hover-button() {
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

@mixin displayHide {
  &.is-show {
    will-change: animation;
    display: block;
    -webkit-animation: show .2s linear 0s;
            animation: show .2s linear 0s;
  }
  &.is-hide {
    display: none;
  }
}

@-webkit-keyframes show {
  from {
    opacity: .4;
  }
  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: .4;
  }
  to {
    opacity: 1;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #EEEEEE;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-radius: 10px;
  }
}

@mixin utility-space {
  @for $i from $local-margin-min through $local-margin-max {
    .u-mt#{$i * 5} {
      margin-top: #{$i * 5}px !important;
    }
    .u-mr#{$i * 5} {
      margin-right: #{$i * 5}px !important;
    }
    .u-mb#{$i * 5} {
      margin-bottom: #{$i * 5}px !important;
    }
    .u-ml#{$i * 5} {
      margin-left: #{$i * 5}px !important;
    }
  }
  @for $i from $local-padding-min through $local-padding-max {
    .u-pt#{$i * 5} {
      padding-top: #{$i * 5}px !important;
    }
    .u-pr#{$i * 5} {
      padding-right: #{$i * 5}px !important;
    }
    .u-pb#{$i * 5} {
      padding-bottom: #{$i * 5}px !important;
    }
    .u-pl#{$i * 5} {
      padding-left: #{$i * 5}px !important;
    }
  }
}


@mixin vertical() {
  &.is-valignTop {
    vertical-align: top;
  }
  &.is-valignMiddle {
    vertical-align: middle;
  }
  &.is-valignBottom {
    vertical-align: bottom;
  }
}

@mixin buttonReset() {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
