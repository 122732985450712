@charset 'utf-8';

.p-home {
    .c-section {
        &__inner {
            margin-top: 30px;
        }
    }
}

.p-home-grid {
    &Item {
        width: 434px;

        &:nth-child(odd) {
            float: left;
        }

        &:nth-child(even) {
            float: right;
        }

        &:nth-child(n + 3) {
            margin-top: 24px;
        }

        &__title {
            position: relative;
            margin: 0;
            padding: 0;
            border-bottom: 2px solid #ccc;
            @include font-size(24);
            font-weight: 400;

            .c-icon-count-num {
                position: absolute;
                top: 6px;
                margin-left: 5px;
            }

            .c-badge {
                position: absolute;
                top: 50%;
                margin-top: -12px;
                margin-left: 5px;

                &--circle {
                    padding: 4px 6.29px;
                }
            }

            &::after {
                display: block;
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $color-onamae-red;
                content: "";
            }
        }

        &:nth-of-type(1) {
            .p-home-gridItem__title {
                &::after {
                    width: 8em;
                }
            }
        }

        &:nth-of-type(2) {
            .p-home-gridItem__title {
                &::after {
                    width: 17em;
                }
            }
        }

        &:nth-of-type(3) {
            .p-home-gridItem__title {
                &::after {
                    width: 4em;
                }
            }
        }

        &:nth-of-type(4) {
            .p-home-gridItem__title {
                &::after {
                    width: 4em;
                }
            }
        }

        &.p-home-gridItem--wide {
            width: 900px;
        }

        .p-home-gridItem__row {
            .p-home-gridItem__col {
                width: 434px;
            }
        }

        &--recommendedMenu {
            .c-panel {
                -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1),
                    0 1px 4px 0 rgba(12, 12, 13, 0.1);
                box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1),
                    0 1px 4px 0 rgba(12, 12, 13, 0.1);
                -webkit-box-shadow: 0 2px 4px 0 #DADADA;
                box-shadow: 0 2px 4px 0 #DADADA;
                padding: 0;

                &:hover,
                &:focus,
                &:active {
                    color: #CC0000;

                    .c-panel__icon,
                    .c-panel__text {
                        color: #CC0000;
                    }
                }

                &-list {
                    margin-top: 20px;
                    margin-bottom: 0;

                    &__item:nth-child(n + 4) {
                        margin-top: 10px;
                    }
                }

                &-link {
                    &.is-disabled {
                        .c-panel {
                            box-shadow: none;
                        }

                        .c-panel__icon {
                            color: #DADADA;
                            opacity: 0.6;
                        }

                        .c-panel__text {
                            color: #B3B3B3;
                            opacity: 0.6;
                        }

                        img {
                            -webkit-filter: none;
                            filter: none;
                            opacity: 0.6;
                        }
                    }
                }

                &__icon {
                    margin-top: 36px;
                    line-height: 0;
                    font-size: 40px;
                    color: #6B6B6B;
                }

                &__text-link {
                    margin-top: 12px;
                }

                &__text {
                    margin-top: 12px;
                    line-height: 1;
                    color: #333;
                }
            }
        }

        .c-text-link {
            @include first-line-Initialization();
            @include font-size(13);
        }
    }
}

.p-home-NoticeCard {
    min-height: 196px;
    background-color: #fff;
    border-right: 1px solid $color-onamae-gray-1;
    border-bottom: 1px solid $color-onamae-gray-1;
    border-left: 1px solid $color-onamae-gray-1;

    &__inner {
        padding: 20px 20px 15px;
    }

    &__list {
        margin: 0;
        padding-right: 6px;
        height: 135px;
        overflow: auto;
        @include scrollbar();

        +p {
            margin-top: 16px;
            line-height: 1;
        }
    }

    &__item {
        display: table;
        border-bottom: 1px dotted $color-onamae-gray-1;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        padding: 8px 0;

        &.is-active {
            .p-home-NoticeCard__heading {
                font-weight: bold;
            }
        }
    }

    &__label {
        display: table-cell;
        width: 64px;
        border-radius: 4px;
        @include font-size(12);
        text-align: center;
        vertical-align: top;
        color: #fff;

        .c-badge {
            width: 64px;
        }
    }

    &__date {
        display: table-cell;
        width: 64px;
        @include font-size(11);
        color: #666;
        vertical-align: middle;

        &Text {
            display: table-cell;
            width: 64px;
            padding-right: 8px;
            @include font-size(11);
        }
    }

    &__heading {
        display: table-cell;
        vertical-align: middle;
        color: $color-onamae-text-blue;

        &Text {
            display: table-cell;
            @include font-size(14);
            color: $color-onamae-text-blue;
        }

        .c-text-link {
            @include font-size(14);

            & span,
            & a {
                color: #0065bd;
                line-height: 1.5;
            }
        }
    }

    &.is-empty {
        position: relative;

        .p-home-NoticeCard__inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            span {
                display: block;
                padding: 70px 0 60px;
                @include font-size(18);
                text-align: center;
                color: #666;
            }
        }
    }
}

.p-home-operationHistory {
    border-right: 1px solid $color-onamae-gray-1;
    border-bottom: 1px solid $color-onamae-gray-1;
    border-left: 1px solid $color-onamae-gray-1;
    background-color: #fff;

    &__inner {
        padding: 16px 25px;
    }
}

.p-home-tableCard {
    &__body {
        height: 120px;
        margin-top: 12px;
        padding-right: 6px;
        overflow: auto;
        @include scrollbar();
    }

    .c-table {
        thead {
            border-bottom: 1px solid #dee2e6;

            th {
                padding: 8px 0;
                border: none;
                @include font-size(13);
            }
        }

        tbody {
            td {
                padding: 8px 16px 8px 0;
                vertical-align: top;

                &:nth-of-type(1) {
                    @include font-size(12);
                }

                &:nth-of-type(2) {
                    @include font-size(11);
                    color: #666;
                }

                &:nth-of-type(3),
                &:nth-of-type(4) {
                    @include font-size(14);
                }
            }
        }
    }

    +.c-text-link {
        margin-top: 16px;
    }
}

.p-home-checkList {
    background-color: #fff;
    border-right: 1px solid $color-onamae-gray-1;
    border-bottom: 1px solid $color-onamae-gray-1;
    border-left: 1px solid $color-onamae-gray-1;

    &-section {
        @include displayHide;
    }
    
    &__image {
        height: 180px;
    }
    &__block {
        height: 355px;
        padding: 24px 24px 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        &.is-collapse {
            height: 88px;
        }

        .c-progress-block {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin-top: 16px;

            &__item {
                &:first-child {
                    width: 320px;
                }
            }

            &__itemNnm {
                display: inline-block;
                @include font-size(20);
                font-weight: 700;
                vertical-align: middle;
                color: #0065BD;
            }

            &__itemText {
                display: inline-block;
                @include font-size(12);
                vertical-align: middle;
            }

            .c-progressBar {
                .c-progressBar__meter {
                    background-color: #4485F4;
                    color: #4485F4;
                }
            }
        }

        .c-block-completion {
            margin: 12px 0 0;
            padding: 24px 0 28px;
            background-color: #FBFBFB;

            &__icon {
                @include font-size(50);
            }

            &__text {
                margin-top: 12px;
                line-height: 1;
                font-weight: 700;
            }

            &__description {
                margin-top: 12px;
                line-height: 1;
            }

            .c-button {
                margin-top: 20px;
                padding: 8.5px 27px;
            }
        }
    }

    &__content {
        @include displayHide;
        margin-top: 10px;
    }

    &__list {
        height: 204px;
        margin: 0;
        padding-right: 0;
        overflow: auto;
        @include scrollbar();

        +.c-text-link {
            margin-top: 18px;
        }
    }

    &__item {
        display: table;
        width: 100%;
        padding: 10px 0 8px;
        border-bottom: 1px dotted $color-onamae-gray-1;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        line-height: 1;

        &.is-active {
            .p-home-NoticeCard__heading {
                font-weight: bold;
            }
        }

        &.is-completion {
            .p-home-checkList__icon {
                visibility: visible;
            }

            .p-home-checkList__tools {
                &.is-vishidden {
                    visibility: hidden;
                }
            }
        }
    }

    &__text {
        @include font-size(15);

        .c-text-link {
            @include font-size(15);
        }

        &.is-disable {
            text-decoration: line-through;

            .c-text-link {
                color: $color-onamae-gray-3;

                &:after {
                    color: $color-onamae-gray-3;
                    border-color: $color-onamae-gray-3;
                }

                &:hover {
                    text-decoration: none;
                    cursor: auto;
                }
            }
        }
    }

    &__textLink {
        position: relative;
        margin-top: 20px;
        @include font-size(13);

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: -10px;
            width: 4px;
            height: 4px;
            margin-top: -4px;
            border-bottom: 1px solid #0065bd;
            border-left: 1px solid #0065bd;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            speak: none;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }
    }

    &__note {
        @include font-size(13);
        color: #666;
        line-height: 1;
        text-align: right;
    }

    &-heading {
        @include font-size(16);
        line-height: 1;
        color: #151515;

        &__Emphasis {
            @include font-size(18);
            font-weight: bold;
            color: #666;
        }
    }

    &__icon {
        visibility: hidden;
        display: table-cell;
        width: 30px;
        border-radius: 4px;
        @include font-size(18);
        color: #4485F4;
        line-height: 0;

        &.is-vishidden {
            visibility: hidden;
        }
    }

    &-text {
        margin-top: 12px;
        @include font-size(13);
        line-height: 1;
        color: #4A4A4A;

        .c-text-link {
            @include font-size(15);

            &:hover {
                cursor: pointer;
            }
        }

        .c-icon {
            @include font-size(10);
        }

        &.is-disable {
            text-decoration: line-through;

            .c-text-link {
                color: $color-onamae-gray-3;

                &:after {
                    color: $color-onamae-gray-3;
                    border-color: $color-onamae-gray-3;
                }

                &:hover {
                    text-decoration: none;
                    cursor: auto;
                }
            }
        }

        &-link {
            position: relative;
            margin-top: 20px;
            @include font-size(13);

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: -10px;
                width: 4px;
                height: 4px;
                margin-top: -4px;
                border-bottom: 1px solid #0065bd;
                border-left: 1px solid #0065bd;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                speak: none;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
        }
    }

    &__tools {
        display: table-cell;
        width: 60px;
        vertical-align: top;
        text-align: center;
        color: #B3B3B3;

        &Icon {
            img {
                vertical-align: middle;
            }
        }

        &:hover {
            cursor: pointer;
        }

        &Button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            margin-right: 4px;
            margin-left: 8px;
            padding: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            color: #B3B3B3;

            &:before {
                content: "\F021";
                display: inline-block;
                @include font-size(14);
                font-family: "iconfont";
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                vertical-align: middle;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                speak: none;
            }

            &.is-active {
                &:before {
                    content: "\F023";
                    @include font-size(14);
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__description {
        @include displayHide;
        margin-top: 24px;
    }

    &-readmore {
        border-top: 1px solid $color-onamae-gray-1;
        @include font-size(13);
        text-align: center;
        line-height: 1;

        &__button {
            @include buttonReset();
            display: block;
            width: 100%;
            padding: 12px 0;
            color: $color-onamae-gray-3;

            &:hover {
                background: #F8F8F8;
            }

            &:active {
                background: #E9E9E9;
            }

            .c-icon {
                display: inline-block;
                margin-right: 10px;
                @include font-size(12);
                vertical-align: middle;
                line-height: 0;
            }
        }
    }

    .c-text-moderate {
        line-height: 1;
    }

    .c-label {
        display: table-cell;
        height: 20px;
        border-radius: 4px;
        @include font-size(12);
        text-align: center;
        vertical-align: middle;
    }

    .c-date {
        padding: 0 10px;
        display: table-cell;
        @include font-size(11);
    }

    .c-heading {
        display: table-cell;
        @include font-size(14);
        color: $color-onamae-text-blue;
    }

    .c-panel {
        width: 122px;
        height: 145px;
        padding-top: 12px;

        &-list {
            @include displayHide;
            margin-top: 24px;

            &__item:last-child {
                .c-panel__text {
                    padding-top: 11px;
                }
            }
        }

        &__text {
            margin-top: 10px;
        }
    }
}

.c-table.c-table--test {

    thead,
    tbody {
        display: block;
        width: 848px;
    }

    tbody {
        overflow-y: scroll;
        height: 120px;
        margin-top: 12px;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: #D8D8D8;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #EEEEEE;
            border-radius: 10px;
        }
    }

    td,
    th {
        table-layout: fixed;
        box-sizing: border-box;
    }

    th {
        padding: 8px 0;
        font-size: 13px;
    }

    td {
        padding: 8px 16px 8px 0;
        vertical-align: top;
    }

    th:nth-child(1) {
        width: 136px;
    }

    th:nth-child(2) {
        width: 136px;
    }

    th:nth-child(3) {
        width: 236px;
    }

    th:nth-child(4) {
        width: 340px;
    }

    td:nth-child(1) {
        width: 136px;
        font-size: 12px;
    }

    td:nth-child(2) {
        width: 136px;
        font-size: 11px;
    }

    td:nth-child(3) {
        width: 236px;
        font-size: 14px;
    }

    td:nth-child(4) {
        width: 340px;
        font-size: 14px;
    }
}



// backUp_20181120

.home-grid {
    &Item {
        // @include rem-change(width, 434px);
        width: 434px;

        &:nth-child(odd) {
            float: left;
        }

        &:nth-child(even) {
            float: right;
        }

        &:nth-child(n + 3) {
            margin-top: 24px;
        }

        &__title {
            position: relative;
            margin: 0;
            padding: 0;
            border-bottom: 2px solid #ccc;
            @include font-size(24);
            font-weight: 400;

            .c-icon-count-num {
                position: absolute;
                top: 6px;
                // @include rem-change(margin-left, 5px);
                margin-left: 5px;
            }

            .c-badge {
                position: absolute;
                top: 50%;
                margin-top: -12px;
                margin-left: 5px;

                &--circle {
                    padding: 4px 6.29px;
                }
            }

            &::after {
                display: block;
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $color-onamae-red;
                content: "";
            }
        }

        &:nth-of-type(1) {
            .home-gridItem__title {
                &::after {
                    width: $text-length-8;
                }
            }
        }

        &:nth-of-type(2) {
            .home-gridItem__title {
                &::after {
                    width: $text-length-11;
                }
            }
        }

        &:nth-of-type(3) {
            .home-gridItem__title {
                &::after {
                    width: $text-length-4;
                }
            }
        }

        &:nth-of-type(4) {
            .home-gridItem__title {
                &::after {
                    width: $text-length-4;
                }
            }
        }

        &.home-gridItem--wide {
            width: 900px;
        }

        .home-gridItem__row {
            .home-gridItem__col {
                width: 434px;
            }
        }

        &--recommendedMenu {
            .c-panel {
                &-list {
                    margin-top: 20px;
                    margin-bottom: 0;
                }

                &-list__item:nth-child(n + 4) {
                    margin-top: 10px;
                }

                -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1),
                0 1px 4px 0 rgba(12, 12, 13, 0.1);
                box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1),
                0 1px 4px 0 rgba(12, 12, 13, 0.1);
                -webkit-box-shadow: 0 2px 4px 0 #DADADA;
                box-shadow: 0 2px 4px 0 #DADADA;

                &__icon {
                    margin-top: 16px;
                }

                &__text-link {
                    margin-top: 12px;
                }
            }
        }

        // &--checklist {}
        .c-text-link {
            @include first-line-Initialization();
            @include font-size(13);
        }
    }
}

.home-NoticeCard {
    min-height: 196px;
    background-color: #fff;
    border-right: 1px solid $color-onamae-gray-1;
    border-bottom: 1px solid $color-onamae-gray-1;
    border-left: 1px solid $color-onamae-gray-1;

    &__inner {
        padding: 15px 20px;
    }

    &__list {
        margin: 0;
        padding-right: 6px;
        height: 135px;
        overflow: auto;
        @include scrollbar();

        +p {
            margin-top: 12px;
            line-height: 1;
        }
    }

    &__item {
        display: table;
        border-bottom: 1px dotted $color-onamae-gray-1;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        padding: 8px 0;

        &.is-active {
            .home-NoticeCard__heading {
                font-weight: bold;
            }
        }
    }

    &__label {
        display: table-cell;
        width: 64px;
        border-radius: 4px;
        @include font-size(12);
        text-align: center;
        vertical-align: top;
        color: #fff;

        .c-badge {
            width: 64px;
        }
    }

    &__date {
        display: table-cell;
        width: 64px;
        @include font-size(11);
        color: #666;
        vertical-align: middle;

        &Text {
            display: table-cell;
            width: 64px;
            padding-right: 8px;
            @include font-size(11);
        }
    }

    &__heading {
        display: table-cell;
        vertical-align: middle;
        color: $color-onamae-text-blue;

        &Text {
            display: table-cell;
            @include font-size(14);
            color: $color-onamae-text-blue;
        }

        .c-text-link {
            @include font-size(14);

            & span,
            & a {
                color: #0065bd;
                line-height: 1.5;
            }
        }
    }

    &.is-empty {
        position: relative;

        .home-NoticeCard__inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            span {
                display: block;
                padding: 70px 0 60px;
                @include font-size(18);
                text-align: center;
                color: #666;
            }
        }
    }
}

.home-operationHistory {
    border-right: 1px solid $color-onamae-gray-1;
    border-bottom: 1px solid $color-onamae-gray-1;
    border-left: 1px solid $color-onamae-gray-1;
    background-color: #fff;

    &__inner {
        padding: 16px 25px;
    }
}

.home-tableCard {
    &__body {
        height: 120px;
        margin-top: 12px;
        padding-right: 6px;
        overflow: auto;
        @include scrollbar();
    }

    .c-table {
        thead {
            border-bottom: 1px solid #dee2e6;

            th {
                padding: 8px 0;
                border: none;
                @include font-size(13);
            }
        }

        tbody {
            td {
                padding: 8px 16px 8px 0;
                vertical-align: top;

                &:nth-of-type(1) {
                    @include font-size(12);
                }

                &:nth-of-type(2) {
                    @include font-size(11);
                    color: #666;
                }

                &:nth-of-type(3),
                &:nth-of-type(4) {
                    @include font-size(14);
                }
            }
        }
    }

    +.c-text-link {
        margin-top: 16px;
    }
}

.home-checkList {
    background-color: #fff;
    border-right: 1px solid $color-onamae-gray-1;
    border-bottom: 1px solid $color-onamae-gray-1;
    border-left: 1px solid $color-onamae-gray-1;

    &-section {
        @include displayHide;
    }

    &__block {
        height: 353px;
        padding: 18px 24px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        &.is-collapse {
            height: 88px;
        }

        .c-progress-block {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            // @include rem-change(margin-top, 10px);
            margin-top: 10px;

            &__item {
                .c-progressBar {
                    margin-bottom: 0;
                }

                &:first-child {
                    // @include rem-change(width, 320px);
                    width: 320px;
                }
            }

            &__itemNnm {
                display: inline-block;
                @include font-size(20);
                font-weight: 700;
                vertical-align: middle;
                color: #4485F4;
            }

            &__itemText {
                display: inline-block;
                @include font-size(12);
                vertical-align: middle;
            }
        }

        .c-block-completion {
            margin: 8px 0 10px;
            padding: 28px 0;
            background-color: #FBFBFB;

            &__icon {
                @include font-size(50);
            }

            &__text {
                margin-top: 8px;
                // @include rem-change(margin-top, 8px);
                font-weight: 700;
            }

            &__description {
                margin-top: 2.5px;
                // @include rem-change(margin-top, 2.5px);
            }

            .c-button {
                margin-top: 17.5px;
                // @include rem-change(margin-top, 17.5px);
            }
        }
    }

    &__content {
        @include displayHide;
        margin-top: 10px;
        // @include rem-change( margin-top, 10px);
    }

    &__list {
        height: 194px;
        // @include rem-change( height, 194px);
        margin: 0;
        padding-right: 16px;
        // @include rem-change( padding-right, 16px);
        overflow: auto;
        @include scrollbar();

        +.c-text-link {
            margin-top: 18px;
            // @include rem-change(margin-top, 18px);
        }
    }

    &__item {
        display: table;
        width: 100%;
        padding: 10px 0 8px;
        // @include rem-change( padding, 10px 0 8px);
        border-bottom: 1px dotted $color-onamae-gray-1;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        line-height: 1;

        &.is-active {
            .home-NoticeCard__heading {
                font-weight: bold;
            }
        }

        &.is-completion {
            .home-checkList__icon {
                visibility: visible;
            }

            .home-checkList__tools {
                &.is-vishidden {
                    visibility: hidden;
                }
            }
        }
    }

    &__icon {
        visibility: hidden;
        display: table-cell;
        width: 10%;
        border-radius: 4px;
        @include font-size(18);
        color: #0065bd;

        &.is-vishidden {
            visibility: hidden;
        }
    }

    &__heading {
        @include font-size(16);
        color: #666;
    }

    &__text {
        @include font-size(13);
        color: #4A4A4A;

        .c-text-link {
            @include font-size(15);

            &:hover {
                cursor: pointer;
            }
        }

        .c-icon {
            @include font-size(10);
        }

        &.is-disable {
            text-decoration: line-through;

            .c-text-link {
                color: $color-onamae-gray-3;

                &:after {
                    color: $color-onamae-gray-3;
                    border-color: $color-onamae-gray-3;
                }

                &:hover {
                    text-decoration: none;
                    cursor: auto;
                }
            }
        }

        &-link {
            position: relative;
            // @include rem-change( margin-top, 8px);
            margin-top: 8px;
            @include font-size(13);

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: -10px;
                width: 4px;
                height: 4px;
                margin-top: -4px;
                // @include rem-change( width, 4px);
                // @include rem-change( height, 4px);
                // @include rem-change( margin-top, -4px);
                border-bottom: 1px solid #0065bd;
                border-left: 1px solid #0065bd;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                speak: none;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
        }
    }

    &__tools {
        display: table-cell;
        width: 16%;
        vertical-align: top;
        text-align: center;
        color: #B3B3B3;

        &Icon {

            // @include rem-change( margin-right, 8px);
            img {
                vertical-align: middle;
            }
        }

        &:hover {
            cursor: pointer;
        }

        &Button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            margin-right: 4px;
            margin-left: 8px;
            // @include rem-change( margin-right, 4px);
            // @include rem-change( margin-left, 8px);
            padding: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            color: #B3B3B3;

            &:before {
                content: "\F021";
                display: inline-block;
                @include font-size(14);
                font-family: "iconfont";
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                vertical-align: middle;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                speak: none;
            }

            &.is-active {
                &:before {
                    content: "\F023";
                    @include font-size(14);
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    // &__note {
    //     @include font-size(13);
    //     color: #4A4A4A;
    // }

    &__description {
        @include displayHide;
        margin-top: 24px;
        // @include rem-change(margin-top, 24px);
    }

    .c-label {
        display: table-cell;
        height: 20px;
        // @include rem-change( height, 20px);
        border-radius: 4px;
        @include font-size(12);
        text-align: center;
        vertical-align: middle;
    }

    .c-date {
        padding: 0 10px;
        // @include rem-change( padding, 0 10px);
        display: table-cell;
        @include font-size(11);
    }

    .c-heading {
        display: table-cell;
        @include font-size(14);
        color: $color-onamae-text-blue;
    }

    .home-readmore {
        border-top: 1px solid $color-onamae-gray-1;
        @include font-size(13);
        text-align: center;

        &__button {
            @include buttonReset();
            display: block;
            width: 100%;
            padding: 10px 0 11px;
            // @include rem-change(padding, 10px 0 11px);
            color: $color-onamae-gray-3;

            &:hover {
                background: #F8F8F8;
            }

            &:active {
                background: #E9E9E9;
            }

            .c-icon {
                display: inline-block;
                margin-right: 10px;
                // @include rem-change(margin-right, 10px);
                @include font-size(12);
                vertical-align: middle;
                line-height: 0;
            }
        }
    }

    .c-panel {
        &-list {
            @include displayHide;
            margin-top: 20px;
            // @include rem-change(margin-top, 20px);
        }

        &__text {
            margin-top: 10px;
            // @include rem-change(margin-top, 10px);
        }

        width: 122px;
        // @include rem-change(width, 122px);
        height: auto;
    }
}

// backUp_end //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////