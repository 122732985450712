@-webkit-keyframes slideDown {
    0% {
		-webkit-transform: translateY(-28px);
				transform: translateY(-28px);
	}
	100% {
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
	}
}
@keyframes slideDown {
    0% {
		-webkit-transform: translateY(-28px);
				transform: translateY(-28px);
	}
	100% {
		-webkit-transform: translateY(0px);
				transform: translateY(0px);
	}
}

.c-alert {
	@include rem-change( padding, 16px);
	overflow: hidden;
	border-bottom: solid thin #4485F4;
	@include font-size(15);
	font-weight: bold;
	text-align: center;
    // -webkit-animation: slideDown 2s forwards;
    //         animation: slideDown 2s forwards;
	&--info {
		color: #4485F4 ;
		background: #DDEBFF;
	}
	&--note {
		color: #D8261E;
		background: #FFE7E7;
		border-bottom: solid thin #D8261E;
	}
}

.c-alert-validation {
    position: relative;
    padding: 16px 24px;
    background: #FFE7E7;
    border: 1px solid #D8261E;
    border-radius: 4px;
    color: #D8261E;
    .c-section__inner > & {
      // margin-top: -14px;
    }
    & + .c-table.c-table--bordered {
      margin-top: 16px !important;
    }
}

.c-alert-complete {
    position: relative;
    padding: 11.5px 24px 11.5px 40px;
    background: #EDF4FF;
	border: 1px solid #4485F4;
	border-radius: 4px;
	box-sizing: border-box;
	color: #4485F4 ;
	&__icon {
		position: absolute;
		top: 50%;
		margin-top: -11px;
		font-size: 20px;
		color:#4485F4;
		line-height: 1;
	}
	&__text {
		padding-left: 32px;
	}
	&__resetButton {
		position: absolute;
		top: 50%;
		right: 14px;
		margin-top: -7px;
		@include buttonReset;
		font-size: 12px;
		color: #B3B3B3;
		line-height: 1;
	}
    .c-section__inner > & {
      margin-top: -14px;
    }
    & + .c-table.c-table--bordered {
      margin-top: 16px !important;
    }
  }