.c-box {
    &__title {
        position: relative;
        @include font-size(18);
        &::after {
            background: #D8D8D8;
            content: "";
            display: block;
            height: 0.8em;
            left: 0;
            margin-top: -0.4em;
            position: absolute;
            top: 50%;
            width: 1px;
        }
    }
    &__body {
        padding-left: 24px;
        @include font-size(15);
    }
    &--flex {
        display: flex;
        display: flex;
        align-items: center;
    }
}