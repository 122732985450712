.c-block-annotation {
  position: relative;
  padding: 15px 15px 15px 52px;
  background: #FDF9E4;
  border: 1px solid #F7E273;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1;

  .c-icon {
    position: absolute;
    top: 50%;
    left: 21px;
    margin-top: -9.5px;
    color: #F1990A;
    @include font-size(19);
    line-height: 0;
  }
}

.c-block-borderBox {
  padding: 8px 12px 8px 16px;
  border: 1px solid #d8d8d8;
  background-color: #fff;

  .c-text-list-disc {
    margin-left: 16px;

    &__item {
      @include font-size(13);
      line-height: 1.6;
      text-indent: -3px;

      &:before {
        content: "";
        position: relative;
        left: -10px;
        top: -3px;
        display: inline-block;
        width: 3px;
        height: 3px;
        border-radius: 100%;
        background: #333;
      }
    }
  }

  p {
    font-size: 13px;
    line-height: 20px;
  }

  &-wrap {
    padding: 10px;
    background-color: #F8F8F8;
  }
}

.c-block-categoryTips {
  margin-top: 32px;

  &__list {
    margin-top: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    max-width: 50%;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  &__title {
    margin-top: 60px;
  }

  &__title:first-child {
    margin-top: 0;
  }

  .c-media {
    &__inner {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;

      &:hover {
        text-decoration: none;

        .c-media__title {
          color: #4485F4;
          text-decoration: underline;
        }

        .c-media__description {}
      }

      &:active {
        .c-media__title {
          color: #0065BD;
          text-decoration: underline;
        }
      }

      &:visited {
        color: #5703AE;
      }

      a:link {
        text-decoration: none;
      }
    }

    &__media {
      margin-right: 24px;
    }

    &__contents {
      width: 310px;
    }

    &__title {
      font-size: 18px;
      font-weight: bold;
      color: #0065bd;

      .c-icon.c-icon-externallink {
        font-size: 16px;
        color: #B3B3B3;
        &:before {
          vertical-align: -1px;
        }
      }
    }

    &__description {
      margin-top: 7.5px;
      font-size: 13px;
    }
  }

  .c-media-icon {
    position: relative;
    display: block;
    background: #fff;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;

    .c-media-icon-wrap & {}

    .c-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      font-size: 40px;
      line-height: 0;
      color: #6B6B6B;

      &.c-icon-wordpress {
        color: #21759B;
      }

      &.c-icon-weebly {
        color: #1495D5;
      }
    }

    .c-media-icon__image {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
    }
  }

}

.c-block-changingDomain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;

  &__scheme {
    margin-right: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58px;
    -ms-flex: 0 0 58px;
    flex: 0 0 58px;
  }

  &__authority {
    @include font-size(18);
  }
}

.c-block-completion {
  padding: 32px 0 30px;
  text-align: center;

  &__icon,
  &__description {
    color: #4485f4;
  }

  &__icon {
    @include font-size(78);
    line-height: 0;
  }

  &__text {
    margin: 13px 0 0;
    @include font-size(24);
    color: #4485F4;
  }

  &__description {
    @include font-size(14);
  }
}
.c-block-searchResults {
  padding: 0 0 48px;
  text-align: center;
  &__icon {
    margin-top: 28px;
    @include font-size(50);
    line-height: 0;
    color: #D8D8D8;
  }
  &__text {
    margin-top: 24px;
  }
  p {
    line-height: 1;
  }
}


.c-block-dropdownArea {
  display: none;
  margin-top: 14px;
  position: relative;
  padding: 40px;
  background: #fff;
  border: 1px solid #D8D8D8;
  -webkit-box-shadow: 0 2px 4px 0 #DADADA;
  box-shadow: 0 2px 4px 0 #DADADA;
  border-radius: 4px;

  &:before {
    position: absolute;
    top: -10px;
    right: 9%;
    display: block;
    width: 0;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    border-top-width: 0;
    content: "";
    z-index: 1;
  }

  &:after {
    position: absolute;
    top: -11px;
    right: 9%;
    display: block;
    width: 0;
    border: 10px solid transparent;
    border-bottom-color: #D8D8D8;
    border-top-width: 0;
    content: "";
  }

  &.is-show {
    display: block;
  }
}


.c-block-displayingNum {
  div {
    display: inline-block;
    @include rem-change(margin-left, 8px);
    vertical-align: middle;
  }

  span {
    display: inline-block;
  }

  .c-form-select {
    position: relative;

    select {
      color: #333;
      cursor: pointer;
      position: relative;
      z-index: $zindex-1;
      background-color: transparent;
      border: 1px solid #d6d6d6;
      width: 100%;
      height: 31px;
      line-height: 31px;
      padding: 0 36px 0 15px;
      -ms-appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 3px;
      outline: none;
    }

    select::-ms-expand {
      display: none;
    }

    .c-icon.c-icon-arrow-down {
      position: absolute;
      top: 7px;
      right: 12px;
      @include font-size(12);
      color: #6b6b6b;
    }
  }

  .c-button {
    padding: 7px 11px;
  }
}

.c-block-domainSelection {
  .c-card & {
    padding: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    .c-block-domainSelection__action {
      margin-left: auto;
    }
  }
}

.c-block-header {
  overflow: hidden;

  &__left {
    float: left;
  }

  &__right {
    float: right;
  }
}


.c-block-pagination {
  padding: 32px 0 30px;
  text-align: center;
  color: #4485f4;

  &__icon {
    @include font-size(70);
  }

  &__text {
    margin: 13px 0 0;
    @include font-size(24);
  }
}



.c-block-paginationRange {
  display: inline-block;

  &__inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &__range {
    margin-right: 4px;
  }

  &__total {
    margin-right: 4px;
    margin-left: 4px;
  }

  &__button {
    @include buttonReset();
    margin-top: -0.2em;

    @-moz-document url-prefix() {
      margin-top: 0;
    }

    &[disabled] {
      visibility: hidden;
      pointer-events: none;
    }
  }

  .c-icon {
    color: #b3b3b3;
    @include font-size(16);
    line-height: 0;
  }
}


.c-block-paginationSelect {
  div {
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
  }

  span {
    display: inline-block;
  }

  .c-form-select {
    position: relative;

    select {
      color: #333;
      cursor: pointer;
      position: relative;
      z-index: $zindex-1;
      background-color: transparent;
      border: 1px solid #d6d6d6;
      width: 100%;
      height: 31px;
      line-height: 31px;
      padding: 0 36px 0 15px;
      -ms-appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 3px;
      outline: none;
    }

    select::-ms-expand {
      display: none;
    }

    .c-icon.c-icon-arrow-down {
      position: absolute;
      top: 50%;
      right: 12px;
      @include font-size(12);
      color: #6b6b6b;
    }
  }

  .c-button {
    padding: 7px 11px;
  }
}


.c-block-navPages {
  margin-bottom: -28px;
  text-align: right;

  &-displayingNum {
    div {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
    }

    span {
      display: inline-block;
    }

    .c-form-select {
      position: relative;

      select {
        color: #333;
        cursor: pointer;
        position: relative;
        z-index: $zindex-1;
        background-color: transparent;
        border: 1px solid #d6d6d6;
        width: 100%;
        height: 31px;
        line-height: 31px;
        padding: 0 36px 0 15px;
        -ms-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 3px;
        outline: none;
      }

      select::-ms-expand {
        display: none;
      }

      // .c-icon.c-icon-arrow-down {
      //   // position: absolute;
      //   // top: 7px;
      //   // right: 12px;
      //   // @include font-size(12);
      //   // color: #6b6b6b;
      //   position: absolute;
      //   top: 50%;
      //   right: 12px;
      //   margin-top: -6px;
      //   font-size: 12px;
      //   font-size: 1.2rem;
      //   color: #6b6b6b;
      //   line-height: 1;
      // }
    }

    .c-button {
      padding: 7px 11px;
    }
  }

  &-paginationLinks {
    margin-top: 15px;

    &__inner {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    &__range {
      margin-right: 4px;
    }

    &__total {
      margin-right: 4px;
      margin-left: 4px;
    }

    &__button {
      @include buttonReset();
      margin-top: -0.2em;

      @-moz-document url-prefix() {
        margin-top: 0;
      }

      &[disabled] {
        visibility: hidden;
        pointer-events: none;
      }
    }

    .c-icon {
      color: #b3b3b3;
      @include font-size(16);
      line-height: 0;
    }
  }

  &--history {
    overflow: hidden;
    margin-bottom: 24px;

    .c-block-navPages-displayingNum {
      float: left;

      div {
        margin-left: 0;
      }
    }

    .c-block-navPages-paginationLinks {
      float: right;
      margin-top: 15px;
      line-height: 1;
    }
  }

  &__left {
    float: left;
  }
}


.c-block-noticeList {
  margin-top: -8px;

  &__item {
    display: table;
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px dotted #d8d8d8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

}


.c-block-spamList {
  overflow: auto;
  max-height: 214px;
  @include scrollbar();

  ul {
    li {
      &.is-unset {
        color: #B3B3B3;
      }
    }
  }
}

.c-block-domainSelection {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;

  &__title {
    position: relative;
    margin-left: 12px;
    padding-right: 24px;
    line-height: 1;
    color: #333;

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      content: "";
      display: block;
      width: 1px;
      height: 32px;
      margin-top: -17px;
      background: #D8D8D8;
    }
  }

  &__text {
    width: 480px;
    margin-left: 24px;
    @include font-size(18);
    line-height: 1;
    color: #0065BD;
  }

  .c-icon {

    &-domain,
    &-mail,
    &-calender {
      @include font-size(20);
      line-height: 0;
      color: #6B6B6B;
    }
  }

  &__action {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 60px;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    text-align: right;
    color: #0065BD;

    .c-icon {
      line-height: 1;

      &:before {
        margin-right: 8px;
        margin-right: 0.8rem;
        font-size: 16px;
        font-size: 1.6rem;
        vertical-align: 0;
      }
    }
  }
}


.c-block-serverChane-dataMigrationCopying {
  -webkit-justify-content: center;
  /* Safari */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 28px;
  text-align: center;

  .c-icon.c-icon-server-fill {
    line-height: 1;
  }

  .c-progressBar {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .c-animation-arrow-pulse {
    margin: 0 32px;
  }
}

.c-block-table {
  display: table;

  &__row {
    display: table-row;

    >span {
      display: table-cell;
    }

    span:nth-last-child(1n) {
      width: 20px;
    }

    span:nth-last-child(2n) {
      width: 120px;
    }

    span:nth-last-child(3n) {
      width: 120px;
    }

    span:nth-last-child(4n) {
      width: 120px;
    }
  }
}


.c-block-navPanel {
  &-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: -8px;
    margin-bottom: 24px;

    &__item {
      margin-right: 8px;
      word-wrap: break-word;
      overflow-wrap: break-word;

      &:nth-child(6n) {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
    &--num6 {
      .c-block-navPanel {
        width: 143px;
        &.is-active::after {
          position: absolute;
          display: block;
          right: 62.5px !important;
          bottom: -9px;
          width: 0;
          border: 9px solid transparent;
          border-top-color: #4485f4;
          border-bottom-width: 0;
          content: "";
          z-index: 1;
        }
      }
    }
  }

  &-link {
    display: block;
    height: 100%;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: #333;
      text-decoration: none;
    }

    &.is-disabled {
      .c-panel__text-link {
        color: #b3b3b3;

        &:after {
          border-top: 1px solid #b3b3b3;
          border-right: 1px solid #b3b3b3;
        }
      }

      img {
        -webkit-filter: grayscale(80%);
        -moz-filter: grayscale(80%);
        -o-filter: grayscale(80%);
        -ms-filter: grayscale(80%);
        filter: grayscale(80%);
        opacity: 0.3;
      }
    }
  }

  position: relative;
  // width: 143px;
  width: 173px;
  height: 100%;
  padding: 12px 8px 8px;
  background-color: #fff;
  border: solid 1px #d8d8d8;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1),
  0 1px 4px 0 rgba(12, 12, 13, 0.1);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1),
  0 1px 4px 0 rgba(12, 12, 13, 0.1);
  -webkit-box-shadow: 0 2px 4px 0 #dadada;
  box-shadow: 0 2px 4px 0 #dadada;

  &__icon,
  &__text {
    display: block;
  }

  &__icon {
    @include font-size(32);
    line-height: 0;
    color: #6b6b6b;

    .c-icon.c-icon-wordpress:before {
      color: #21759B;
    }

    .c-icon.c-icon-weebly:before {
      color: #1495D5;
    }

    .c-icon.c-icon-exchangeonline:before {
      color: #0078D7;
    }
  }

  &__text {
    margin-top: 8px;
    @include font-size(13);
    // line-height: 1.1;
    line-height: 1.4;

    .c-icon.c-icon-externallink {
      margin-left: 4px;
      line-height: 0;
      color: #b3b3b3;

      &:before {
        vertical-align: -.125em;
      }
    }
  }

  &:hover,
  &:focus {
    background-color: #f8f8f8;
    cursor: pointer;

    .c-block-navPanel__icon {
      color: #6b6b6b;
    }

    .c-block-navPanel__text {
      color: #333;
    }
  }

  &:active {
    background: #e9e9e9;
  }

  &.is-active {
    background: #4485f4;
    border: 1px solid #4485f4;
    font-weight: 700;

    &::after {
      position: absolute;
      display: block;
      right: 76px;
      bottom: -9px;
      width: 0;
      border: 9px solid transparent;
      border-top-color: #4485f4;
      border-bottom-width: 0;
      content: "";
      z-index: $zindex-1;
    }

    &:hover,
    &:focus,
    &:active {
      background: #4485f4;
      border: 1px solid #4485f4;
      font-weight: 700;
      cursor: default;

      .c-block-navPanel__icon,
      .c-block-navPanel__text {
        color: #fff;
      }
    }

    .c-block-navPanel__icon,
    .c-block-navPanel__text {
      color: #fff;
    }
    .c-block-navPanel__icon {
      img {
        -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
      }
    }

    .c-icon.c-icon-wordpress:before,
    .c-icon.c-icon-exchangeonline:before {
      color: #fff !important;
    }
  }

  &.is-disabled {
    border: 1px solid #d8d8d8;

    .c-panel__text-link {
      color: #b3b3b3;

      &:after {
        border-top: 1px solid #b3b3b3;
        border-right: 1px solid #b3b3b3;
      }
    }

    .c-panel-link & {
      cursor: auto;
      pointer-events: none;
    }

    img {
      -webkit-filter: grayscale(80%);
      -moz-filter: grayscale(80%);
      -o-filter: grayscale(80%);
      -ms-filter: grayscale(80%);
      filter: grayscale(80%);
      opacity: 0.3;
    }

    .c-block-navPanel__icon {
      color: #6b6b6b;
    }

    .c-block-navPanel__text {
      color: #333;
    }
  }

  .c-tooltip-icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

/*Safariだけに適用される新型CSSハック*/
_::-webkit-full-page-media,
_:future,
:root .c-block-navPanel {
  padding: 16px 7.5px 12px;
}


.c-block-systemError {
  padding: 30px 0 0;
  text-align: center;

  &__icon,
  &__description {
    color: #D8261E;
  }

  &__icon {
    @include font-size(63);
    line-height: 0;
  }

  &__text {
    margin: 24px 0 0;
    @include font-size(24);
    color: #D8261E;
  }

  &__description {
    @include font-size(14);
  }
}


.c-block-validation {
  position: relative;
  padding: 16px 24px;
  background: #FFE7E7;
  border: 1px solid #D8261E;
  border-radius: 4px;
  color: #D8261E;

  .c-section__inner>& {
    margin-top: -14px;
  }

  &+.c-table.c-table--bordered {
    margin-top: 16px !important;
  }
}