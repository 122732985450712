.c-grid {
    &__row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    &__col {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        &--1,
        &--auto {
            -webkit-box-flex: 0
        }
        &--auto {
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: none
        }
        &--1 {
            -webkit-flex: 0 0 8.3333333333%;
            -ms-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
            max-width: 8.3333333333%
        }
        &--2,
        &--3 {
            -webkit-box-flex: 0
        }
        &--2 {
            -webkit-flex: 0 0 16.6666666667%;
            -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
            max-width: 16.6666666667%
        }
        &--3 {
            -webkit-flex: 0 0 25%;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%
        }
        &--4,
        &--5 {
            -webkit-box-flex: 0
        }
        &--4 {
            -webkit-flex: 0 0 33.3333333333%;
            -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
            max-width: 33.3333333333%
        }
        &--5 {
            -webkit-flex: 0 0 41.6666666667%;
            -ms-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
            max-width: 41.6666666667%
        }
        &--6,
        &--7 {
            -webkit-box-flex: 0
        }
        &--6 {
            -webkit-flex: 0 0 50%;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%
        }
        &--7 {
            -webkit-flex: 0 0 58.3333333333%;
            -ms-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
            max-width: 58.3333333333%
        }
        &--8,
        &--9 {
            -webkit-box-flex: 0
        }
        &--8 {
            -webkit-flex: 0 0 66.6666666667%;
            -ms-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
            max-width: 66.6666666667%
        }
        &--9 {
            -webkit-flex: 0 0 75%;
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            max-width: 75%
        }
        &--10,
        &--11 {
            -webkit-box-flex: 0
        }
        &--10 {
            -webkit-flex: 0 0 83.3333333333%;
            -ms-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
            max-width: 83.3333333333%
        }
        &--11 {
            -webkit-flex: 0 0 91.6666666667%;
            -ms-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
            max-width: 91.6666666667%
        }
        &--12 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%
        }
        &--top {
            align-self: flex-start;
        }
        &--bottom {
            align-self: flex-end;
        }
    }
    &__cell {
        margin: 2%;
    }
}