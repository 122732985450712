// local
$local-margin-min: 0;
$local-margin-max: 14;
$local-padding-min: 0;
$local-padding-max: 14;
$local-color-white: #fff;
// utility-space
@include utility-space();
.u-mt {
    &-xxxs {
        margin-top: 4px !important;
    }
    &-xxs {
        margin-top: 8px !important;
    }
    &-xs {
        margin-top: 16px !important;
    }
    &-s {
        margin-top: 24px !important;
    }
    &-m {
        margin-top: 32px !important;
    }
    &-l {
        margin-top: 40px !important;
    }
    &-xl {
        margin-top: 48px !important;
    }
    &-xxl {
        margin-top: 56px !important;
    }
    &12 {
        margin-top: 12px !important;
    }
    &44 {
        margin-top: 44px !important;
    }
    &132 {
        margin-top: 132px !important;
    }
}

.u-mr {
    &-xxs {
        margin-right: 8px !important;
    }
    &-xs {
        margin-right: 16px !important;
    }
    &-s {
        margin-right: 24px !important;
    }
    &-m {
        margin-right: 32px !important;
    }
    &-l {
        margin-right: 40px !important;
    }
    &-xl {
        margin-right: 48px !important;
    }
    &-xxl {
        margin-right: 56px !important;
    }
}

.u-mb {
    &-xxs {
        margin-bottom: 8px !important;
    }
    &-xs {
        margin-bottom: 16px !important;
    }
    &-s {
        margin-bottom: 24px !important;
    }
    &-m {
        margin-bottom: 32px !important;
    }
    &-l {
        margin-bottom: 40px !important;
    }
    &-xl {
        margin-bottom: 48px !important;
    }
    &-xxl {
        margin-bottom: 56px !important;
    }
    &-xxs-minus {
        margin-bottom: -8px !important;
    }
    &-xs-minus {
        margin-bottom: -16px !important;
    }
    &-s-minus {
        margin-bottom: -24px !important;
    }
    &-m-minus {
        margin-bottom: -32px !important;
    }
    &-l-minus {
        margin-bottom: -40px !important;
    }
    &-xl-minus {
        margin-bottom: -48px !important;
    }
    &-xxl-minus {
        margin-bottom: -56px !important;
    }
    &12 {
        margin-bottom: 12px !important;
    }
}

.u-ml {
    &-xxs {
        margin-left: 8px !important;
    }
    &-xs {
        margin-left: 16px !important;
    }
    &-s {
        margin-left: 24px !important;
    }
    &-m {
        margin-left: 32px !important;
    }
    &-l {
        margin-left: 40px !important;
    }
    &-xl {
        margin-left: 48px !important;
    }
    &-xxl {
        margin-left: 56px !important;
    }
    &12 {
        margin-left: 12px !important;
    }
}
.u-pt {
    &-xxs {
        padding-top: 8px !important;
    }
    &-xs {
        padding-top: 16px !important;
    }
    &-s {
        padding-top: 24px !important;
    }
    &-m {
        padding-top: 32px !important;
    }
    &-l {
        padding-top: 40px !important;
    }
    &-xl {
        padding-top: 48px !important;
    }
    &-xxl {
        padding-top: 56px !important;
    }
}
.u-pb {
    &-xxs {
        padding-bottom: 8px !important;
    }
    &-xs {
        padding-bottom: 16px !important;
    }
    &-s {
        padding-bottom: 24px !important;
    }
    &-m {
        padding-bottom: 32px !important;
    }
    &-l {
        padding-bottom: 40px !important;
    }
    &-xl {
        padding-bottom: 48px !important;
    }
    &-xxl {
        padding-bottom: 56px !important;
    }
}

.u-p24{
    padding: 24px !important;
}

.u-bg {
    &-white {
        background-color: $local-color-white !important;
    }
    &-red {
        background-color: $color-onamae-red !important;
    }
    &-gray {
        background-color: $color-onamae-icon-gray !important;
    }
    &-gray-dark {
        background-color: $color-onamae-gray-3 !important;
    }
    &-gray-1 {
        background-color: $color-onamae-gray-1 !important;
    }
    &-gray-2 {
        background-color: $color-onamae-gray-2 !important;
    }
    &-gray-3 {
        background-color: $color-onamae-gray-3 !important;
    }
    &-transparent {
        background-color: transparent !important;
    }
    &-default-primary {
        background-color: #4485F4 !important;
    }
    &-default-secondary {
        background-color: #0065BD !important;
    }
    &-warning-primary {
        background-color: #FCB71B !important;
    }
    &-warning-secondary {
        background-color: #F1990A !important;
    }
    &-danger-primary {
        background-color: #F48080 !important;
    }
    &-danger-secondary {
        background-color: #D8261E !important;
    }
}

.u-border {
    border: 1px solid #D8D8D8;
    &-top-none {
        border-top: none !important;
    }
    &-bottom-none {
        border-bottom: none !important;
    }
}
.u-color {
    &-white {
        color: $local-color-white !important;
    }
    &-red {
        color: $color-onamae-red !important;
    }
    &-gray {
        color: $color-onamae-icon-gray !important;
    }
    &-gray-dark {
        color: $color-onamae-gray-dark !important;
    }
    &-gray-1 {
        color: $color-onamae-gray-1 !important;
    }
    &-gray-2 {
        color: $color-onamae-gray-2 !important;
    }
    &-gray-3 {
        color: $color-onamae-gray-3 !important;
    }
    &-blue {
        color: #4485F4 !important;
    }
    &-blue-1 {
        color: #0065BD !important;
    }
    &-blue-light {
        color: #94B5EF !important;
    }
    &-transparent {
        color: transparent !important;
    }
    &-annotation {
        color: #F1990A !important;
    }
    &-note {
        color: #D8261E !important;
    }
    &-warning-primary {
        color: #FCB71B !important;
    }
    &-warning-secondary {
        color: #F1990A !important;
    }
    &-danger-primary {
        color: #F48080 !important;
    }
    &-danger-secondary {
        color: #D8261E !important;
    }
}
.u-filter-color {
    &-gray {
        -webkit-filter: grayscale(80%);
        -moz-filter: grayscale(80%);
        -o-filter: grayscale(80%);
        -ms-filter: grayscale(80%);
        filter: grayscale(100%);
        opacity: 1;
    }
}

.u-display {
    &-none {
        display: none;
    }
    &-block {
        display: block;
    }
    &-inline {
        display: inline;
    }
    &-inlineBlock {
        display: inline-block;
    }
    &-table {
        display: table;
        &-cell {
            display: table-cell;
            &--verticalAlign-middle {
                vertical-align: middle;
            }
            &--verticalAlign-top {
                vertical-align: top;
            }
            &--verticalAlign-bottom {
                vertical-align: bottom;
            }
        }
        &--equality {
            table-layout: fixed;
        }
    }
    &-flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        &--align-center {
            -webkit-box-align: center;
                -ms-flex-align: center;
                    -webkit-align-items: center;
                    align-items: center;
        }
        &--justify-center {
            -webkit-box-pack: center;
            -webkit-justify-content: center;
                -ms-flex-pack: center;
                    justify-content: center;
        }
        &--justify-spaceBetween {
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                    justify-content: space-between;
        }
        &__childElement {

        }
    }
}

.u-float {
    &-right {
        float: right;
    }
    &-left {
        float: left;
    }
}

.u-text {
    &-align {
        &-right {
            text-align: right !important;
        }
        &-center {
            text-align: center !important;
        }
        &-left {
            text-align: left !important;
        }
    }
    &-icon {
        font-size: 20px !important;
        vertical-align: middle;
    }
}

.u-verticalAlign{
    &-middle {
        vertical-align: middle !important;
    }
    &-top {
        vertical-align: top !important;
    }
    &-bottom {
        vertical-align: bottom !important;
    }
}

.u-lineHeight-reset {
    line-height: 1;
}

.u-visibility-hidden {
    visibility:hidden
}

.u-position {
    &-relative {
        position: relative;
    }
    &-absolute {
        position: absolute;
    }
    &-top {
        top: 0;
    }
    &-right {
        right: 0;
    }
}

.u-zindex {
    z-index: 1;
}