$color-onamae-gray-3:#666;
$color-onamae-gray-dark: #333;
.c-dropdown {
    display: none;
    position: absolute;
    left: auto;
    top: auto;
    z-index: $zindex-dropdown;
    min-height: 1rem;
    margin: 0;
    background-color: #fff;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    -moz-border-radius: 4px;
    box-shadow: 0 2px 4px 0 #DADADA;
    @include font-size(15);
    color: #333;
    @include displayHide;
    &.is-large {
        max-width: 486px;
    }
    &.is-auto {
        max-width: initial;
        max-width: auto;
    }
    &.is-show {
        display: block !important;
    }
    &--userInformation {
        width: 280px;
        .c-dropdown {
            &__heading {
                @include font-size(20);
                color: #333;
                padding: 2rem 2rem 0 2rem;
                @include first-line-Initialization();
            }
            &__media {
                display: flex;
                align-items: center;
                margin-top: 10px;
                padding: 0 2rem;
            }
            &__title {
                margin-left: 1em;
                @include font-size(18);
            }
            &__userName {
                color: $color-onamae-gray-dark;
                word-break: break-all;
            }
            &__userRole {
                @include font-size(14);
                color: $color-onamae-gray-3;
            }
            &__body {
                margin-top: 3px;
                padding: 0 3rem;
                text-align: right;
            }
            &__textLink {
                position: relative;
                display: block;
                padding: 6px 0;
                @include font-size(13);
                @include first-line-Initialization();
                color: #0065bd;
                outline: none;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 4px;
                    height: 4px;
                    border-top: 1px solid #0065bd;
                    border-right: 1px solid #0065bd;
                    transform: rotate(45deg);
                    top: 50%;
                    right: -10px;
                    margin-top: -4px;
                    speak: none;
                    transition: all 0.2s ease;
                    &:visited {
                        color: #193C81;
                    }
                    &:hover {
                        color: #4485F4;
                        text-decoration: underline;
                    }
                    &:active {
                        color: #0065BD;
                        text-decoration: underline;
                    }
                }
            }
            &__button {
                margin-top: 24px;
                text-align: right;
                border-top: solid thin #D8D8D8;
                box-sizing: border-box;
                .c-icon.c-icon-logout {
                    margin-left: .5rem;
                    vertical-align: middle;
                }
            }
            &__buttonLink {
                @include buttonReset();
                display: block;
                width: 100%;
                padding: 1rem 2rem;
                @include font-size(15);
                color: $color-onamae-gray-3;
                text-align: right;
                outline: none;
                width: 100%;
                &:hover {
                    background: #F8F8F8;
                }
                &:active {
                    background: #E9E9E9;
                }
                &.is-disabled {
                    .c-icon {
                        color: #B3B3B3;
                    }
                    &:hover {
                        background-color: #fff;
                        font-weight: normal;
                    }
                }
            }
        }
    }
    &:before {
        position: absolute;
        top: -13px;
        right: 45%;
        display: block;
        width: 0;
        border: 14px solid transparent;
        border-bottom-color: #fff;
        border-top-width: 0;
        content: "";
        z-index: $zindex-1;
    }
    &:after {
        position: absolute;
        top: -14px;
        right: 45%;
        display: block;
        width: 0;
        border: 14px solid transparent;
        border-bottom-color: #D8D8D8;
        border-top-width: 0;
        content: "";
    }
    &.left{
        &:before {
            right: 70%;
        }
        &:after {
            right: 70%;
        }
    }
    &.right{
        &:before {
            right: 20%;
        }
        &:after {
            right: 20%;
        }
    }
    &--history {
        width: 128px;
        margin-top: -16px;
        margin-left: 2px;
        padding: 8px 0;
        .dropdown__block-list {
            li {
                margin: 6px 0;
                text-align: center;
                &:hover {
                    background-color: #4485F4;
                    color: #fff;
                }
            }
        }
        &:before {
            top: -20px;
            left: 56px;
            border-width: 10px;
        }
        &:after {
            top: -21px;
            left: 56px;
            border-width: 10px;
        }
    }
}

.c-dropdown-button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    appearance: none;
}