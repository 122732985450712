@charset 'utf-8';
// =====================
// login
// =====================
.p-login {
    .l-main {
        width: auto;
        height: auto;
        margin: auto;
    }
    .l-footer {
        width: 100%;
        min-width: 680px;
        margin: 0;
    }
    &-container {
        width: 550px;
        min-height: auto;
        margin: 0 auto;
        background: #FFF;
        border: 1px solid #dee2e6;
        border-top: solid 2px #CC0000;
        box-sizing: border-box;
        &__inner {
            @include rem-change(padding, 0 60px 40px);
        }
        .c-heading-2nd {
            font-weight: 700;
            color: #666;
        }
        .c-table {
            th,
            td {
                border: none;
            }
            th {
                padding-right: 24px;
                padding-left: 0;
                font-weight: 400;
                text-align: right;
            }
            td {
                padding-right: 0;
            }
        }
        .c-button-list.c-button-list--vertical {
            margin-top: 28px;
        }
    }
    .c-modal {
        &--vertical{
            .c-modal__contents {
                width: auto;
            }
        }
    }
    .g-recaptcha {
        width: 304px;
        margin: 20px auto 0;
    }
}