.c-loading-progress {
	width: 100%;
	height: 4px;
	background: #E9E9E9;

	&__inner {
		height: 4px;
		background: #D8261E;
		-webkit-transition: .5s;
		-moz-transition: .5s;
		-ms-transition: .5s;
		-o-transition: .5s;
		transition: .5s;
	}

	.moving {
		animation: progress 2s infinite;
	}
}

@keyframes progress {
	0% {
		width: 0%;
	}

	100% {
		width: 100%;
	}
}

[class^="c-loading-spinner"],
[class*=" c-loading-spinner"] {
	display: block;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	border-top: 4px solid #E9E9E9;
	border-right: 4px solid #E9E9E9;
	border-bottom: 4px solid #E9E9E9;
	border-left: 4px solid #D8261E;
	-webkit-animation: spinner 0.8s infinite linear;
	animation: spinner 0.8s infinite linear;
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $zindex-loading;
}

.c {
	&-loading {
		&-spinner {
			&-red {
				width: 40px;
				height: 40px;
				-webkit-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
			}

			&-white {
				border-top: 3px solid rgba(255, 255, 255, 0.5);
				border-right: 3px solid rgba(255, 255, 255, 0.5);
				border-bottom: 3px solid rgba(255, 255, 255, 0.5);
				border-left: 3px solid #fff;
			}

			&-grey {
				border-top: 3px solid #D8D8D8;
				border-right: 3px solid #D8D8D8;
				border-bottom: 3px solid #D8D8D8;
				border-left: 3px solid #666666;
			}
		}

		&-relative {
			position: relative;

			tr & {
				height: 28px;

				&:hover {
					background-color: initial !important;
				}
			}
			&--h100 {
				min-height: 100px;
			}
			&--h200 {
				min-height: 200px;
			}
		}

		&-mask {
			background-color: rgba(255, 255, 255, 0.8);
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			z-index: 100;
			align-items: center;
			display: -webkit-flex;
			display: -moz-flex;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			&--hasText {
				.c-loading-spinner-red {
					top: -70px;
				}
				.c-loading-mask__text {
					margin-top: 110px;
				}
			}
			&--history {
				bottom: 85px;
    			top: 55px;
			}
		}
	}
}

body.c-loading-relative {
	.c-loading-mask {
		z-index: $zindex-loading-overall;
	}
}

.c-container__body {
	>.c-loading-mask {
		.c-loading-spinner-red {
			top: -50px;
		}

		.c-loading-mask__text {
			margin-top: 134px;
		}
	}
}

[class^="c-loading-button"],
[class*=" c-loading-button"] {
	padding: 1.3rem 2.7rem;
	font-size: 13px;
	font-size: 1.3rem;
	border-radius: 4px;
	background-color: #4485F4;
	width: 160px;
	height: 40px;
	border: 1px solid transparent;
}

.c-loading-button-gray {
	background-color: #F6F6F6;
	border: 1px solid #D6D6D6;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.c-loading-icon-spinner {
	display: block;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	border-top: 4px solid #E9E9E9;
	border-right: 4px solid #E9E9E9;
	border-bottom: 4px solid #E9E9E9;
	border-left: 4px solid #D8261E;
	-webkit-animation: spinner 0.8s infinite linear;
	animation: spinner 0.8s infinite linear;
	margin: 0 6px 0 0px;
	&--grey {
		border-top: 3px solid #D8D8D8;
		border-right: 3px solid #D8D8D8;
		border-bottom: 3px solid #D8D8D8;
		border-left: 3px solid #666666;
	}
}